import React, {
  forwardRef,
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
  useCallback,
  useMemo,
  useTransition
} from "react";
import PropTypes from "prop-types";
import {
  Icon,
  Button,
  theme,
  styled,
  keyframes
} from "@washingtonpost/wpds-ui-kit";
import { Play, Loading, Mute } from "@washingtonpost/wpds-assets";
import { getClasses } from "@washingtonpost/front-end-utils";
import "../../../../common/styles/animations.css";
import "./promo.css";
import { AutoPlayStatus, PromoState } from "../../../globals";
import { formatHoursMinutesSeconds, imageResizer } from "../../../lib/utils";
import { getBackgroundImage, getBackgroundVideo } from "./utils";
import {
  checkIfVideoEnded,
  checkIfVideoTypeLive,
  checkIfVideoUpcoming,
  hasBurnedHeadline,
  isLiveEventUpcoming
} from "../../utils";
import { autoplayStateAllowsAds, videoHasAds } from "../../ads/utils/helpers";
import { useVideoDataContext } from "../../contexts/VideoDataContext";
import { useAutoplayContext } from "../../contexts/AutoplayContext";
import { PlayerSize } from "../../hooks/usePlayerSize";

const infiniteRotation = keyframes({
  "0%": {
    transform: "rotate(0deg)"
  },
  "100%": {
    transform: "rotate(359deg)"
  }
});

// add a `toString` method
Icon.toString = () => ".wpds-icon";

const PromoButton = styled(Button, {
  "&[data-state='loading']": {
    [`& ${Icon}`]: {
      animation: `${infiniteRotation} 2s linear infinite`,

      "@reducedMotion": {
        animation: "none"
      }
    }
  },

  "&[data-state='buffer']": {
    borderWidth: "1px !important",

    [`& ${Icon}`]: {
      color: "white",
      transition: "none",

      animation: `${infiniteRotation} 2s linear infinite`,

      "@reducedMotion": {
        animation: "none"
      }
    }
  },
  "&[data-state-live='true']": {
    borderColor: "red !important",

    [`& ${Icon}`]: {
      color: "white"
    }
  },
  "&[data-state-live-preview='true']": {
    [`& ${Icon}`]: {
      color: "#111 !important"
    }
  }
});

export const Promo = forwardRef(
  (
    {
      onClick,
      promoState,
      setPromoState,
      config,
      promoEnabled: isPromoEnabled,
      shouldShowAds,
      aspectRatio,
      playerSize,
      aspectMatch
    },
    ref
  ) => {
    const {
      showBtnText = true,
      customBtnText,
      showDurationOrLive = true,
      alternateArt = null,
      isLivePreviewEnabled,
      placeholder
    } = config;

    const [, startTransition] = useTransition();

    const {
      videoData,
      videoApiDataFailed: isVideoApiDataFailed,
      isForceLoadVideo
    } = useVideoDataContext();
    const { autoplayState } = useAutoplayContext();

    const isVideoTypeLive = checkIfVideoTypeLive(videoData);
    const isUpcoming = checkIfVideoUpcoming(videoData);
    const isEnded = checkIfVideoEnded(videoData);
    const [backgroundImage, setBackgroundImage] = useState();
    const [title, setTitle] = useState();

    const [backgroundVideo, setBackgroundVideo] = useState();
    const [backgroundVideoFailed, setBackgroundVideoFailed] = useState(false);
    const [duration, setDuration] = useState();

    const promoRef = useRef();

    const hasVideo = backgroundVideo && !backgroundVideoFailed;

    const shouldRenderPromo = useMemo(() => {
      if (!autoplayState.isInitialized) return false;

      const autoplayStateAllowsPromo =
        !autoplayState.isAutoplay ||
        autoplayState.status === AutoPlayStatus.COMPLETE ||
        autoplayState.isLivePreview;

      const hasAds =
        shouldShowAds &&
        autoplayStateAllowsAds(autoplayState) &&
        videoHasAds(videoData, config);

      return (
        isPromoEnabled &&
        !isVideoApiDataFailed &&
        (autoplayStateAllowsPromo || hasAds || isLiveEventUpcoming(videoData))
      );
    }, [
      shouldShowAds,
      videoData,
      config,
      autoplayState,
      isPromoEnabled,
      isVideoApiDataFailed
    ]);

    // Update display when video changes
    useEffect(() => {
      // Set title.
      setTitle(videoData?.bandito?.title || videoData?.headlines?.basic);

      const isVertical = videoData?.additional_properties?.vertical;
      const width = isVertical ? 480 : 960;

      if (alternateArt && !autoplayState.isPlaythrough) {
        setBackgroundImage(
          imageResizer(alternateArt, width, width * aspectRatio)
        );
      } else {
        // Set background image.
        setBackgroundImage(getBackgroundImage(videoData, placeholder));
      }

      // Set background video.
      setBackgroundVideo(getBackgroundVideo(videoData));

      setDuration(formatHoursMinutesSeconds(videoData?.duration));
    }, [
      alternateArt,
      autoplayState.isPlaythrough,
      videoData,
      aspectRatio,
      placeholder
    ]);

    useImperativeHandle(ref, () => ({
      playVideo() {
        setPromoState(PromoState.HIDDEN);
        onClick();
      }
    }));

    const handleVideoPlay = useCallback(
      event => {
        event.preventDefault();
        event.stopPropagation();
        if (isVideoTypeLive && isUpcoming) return;
        onClick();
      },
      [isVideoTypeLive, isUpcoming, onClick]
    );

    const promoText = getPromoText(
      isForceLoadVideo,
      title,
      customBtnText,
      isLivePreviewEnabled,
      isUpcoming
    );

    const burnedHeadline = hasBurnedHeadline(videoData);

    const PromoTitle = styled("div", {
      color: theme.colors["gray700-static"]
    });

    const PromoDuration = styled("div", {
      color: theme.colors["gray700-static"]
    });

    const handleOnClick = event => {
      startTransition(() =>
        promoState === PromoState.READY ? handleVideoPlay(event) : null
      );
    };

    return (
      <>
        {/* Dont show promo if disabled or if autoplay tags exist. */}
        {shouldRenderPromo && (
          <div
            ref={promoRef}
            id={`orca-promo-wrapper`}
            data-testid="orca-promo"
            className="z-1 w-100 h-100 absolute top-0 left-0 left"
            style={{
              ...(!hasVideo &&
                !isLivePreviewEnabled && {
                  // Use image instead!
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: backgroundImage
                    ? `url(${backgroundImage})`
                    : "none",
                  backgroundSize: aspectMatch ? "cover" : "contain",
                  ["--aspect-height"]: aspectRatio,
                  ["--aspect-width"]: 1
                }),
              overflow: "hidden",
              display: promoState === PromoState.HIDDEN ? "none" : "block"
            }}
            onClick={handleOnClick}
          >
            {hasVideo && (
              <video
                className="w-100 h-100"
                src={backgroundVideo}
                onError={() => setBackgroundVideoFailed(true)}
                onAbort={() => setBackgroundVideoFailed(true)}
                playsInline
                muted
                autoPlay
                loop
                crossOrigin="anonymous"
                controlsList="nodownload"
              />
            )}
            {playerSize && (
              <div
                id={`orca-promo-container`}
                className={getClasses(
                  "flex items-center absolute bottom-0 left-0 pa-sm pt-xl border-box w-100 pointer",
                  { [`${playerSize}`]: playerSize }
                )}
              >
                {isUpcoming === false && isEnded === false && (
                  <PromoButton
                    aria-label={`Play ${
                      isVideoTypeLive ? "Live " : ""
                    }video: ${title}, ${duration.srOnly}`}
                    id={`customButton`}
                    icon="center"
                    data-state={promoState.toLowerCase()}
                    data-state-live={isVideoTypeLive}
                    data-state-live-preview={isLivePreviewEnabled}
                    className="focus-highlight"
                  >
                    <Icon className="wpds-icon">
                      {promoState === PromoState.READY ? (
                        isLivePreviewEnabled && isVideoTypeLive ? (
                          <Mute />
                        ) : (
                          <Play />
                        )
                      ) : (
                        <Loading />
                      )}
                    </Icon>
                  </PromoButton>
                )}
                <div
                  className={`flex flex-column w-100 ${
                    playerSize === PlayerSize.SMALL ? "ml-xs" : "ml-sm"
                  }`}
                  style={{ minWidth: 0 }}
                >
                  {showBtnText &&
                    !isEnded &&
                    // Don't show headline if it's burned in, unless customBtnText is provided
                    (!burnedHeadline || customBtnText) && (
                      <PromoTitle
                        id={`orca-promo-title`}
                        aria-hidden={isUpcoming ? false : true}
                        aria-label={`Upcoming ${
                          isVideoTypeLive ? "Live " : ""
                        }video: ${title}`}
                        data-testid="promo-title"
                        className="font-bold overrideStyles nowrap truncate mr-xs"
                        role="heading"
                      >
                        {promoText}
                      </PromoTitle>
                    )}
                  {showDurationOrLive && (
                    <PromoDuration
                      id={`orca-promo-duration`}
                      aria-hidden="true"
                      className="overrideStyles"
                      data-testid="promo-duration"
                      style={{
                        visibility:
                          isVideoTypeLive && isEnded ? "hidden" : "visible"
                      }}
                    >
                      {isVideoTypeLive && !isEnded ? (
                        <div className="flex items-center">
                          <div className={getClasses("liveDot blink")} />
                          Live
                        </div>
                      ) : (
                        duration.visible
                      )}
                    </PromoDuration>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  }
);

Promo.propTypes = {
  /** Click handler (used by Orca) */
  onClick: PropTypes.func,
  promoState: PropTypes.string,
  setPromoState: PropTypes.func,
  config: PropTypes.object,
  autoplayState: PropTypes.object,
  promoEnabled: PropTypes.bool,
  shouldShowAds: PropTypes.bool,
  aspectRatio: PropTypes.number,
  playerSize: PropTypes.oneOf(Object.values(PlayerSize)),
  aspectMatch: PropTypes.bool
};

Promo.displayName = "Promo";

const DEFAULT_LIVE_PREVIEW_TEXT = "Tap to unmute";

const getPromoText = (
  isForceLoadVideo,
  title,
  customBtnText,
  isLivePreviewEnabled,
  isUpcoming
) => {
  if (isForceLoadVideo) return title;
  if (customBtnText) return customBtnText;
  if (isLivePreviewEnabled && !isUpcoming) return DEFAULT_LIVE_PREVIEW_TEXT;
  return title;
};

import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import noop from "lodash/noop";
import Hls from "hls.js/dist/hls.min.js";

import { OrcaPropTypes } from ".";
import useVideoControls from "./hooks/useVideoControls";
import { StickyPlayerEventLabel, VideoEvent } from "../lib/analytics/utils";
import Controls from "../controls";
import { Promo } from "./components/Promo";
import { Wrapper } from "./components/Wrapper";
import { useHLS } from "./hooks/useHLS";
import { useSplunkEvents } from "./hooks/useSplunkEvents";
import { useChartbeat } from "./hooks/useChartbeat";
import {
  AdState,
  AutoPlayStatus,
  AutoPlayType,
  BASE_URL,
  OrcaWindowEvents,
  PerformanceEvents,
  PromoState,
  VideoEvents
} from "../globals";
import { useBlockHomepageRefresh } from "./hooks";
import { useRecommendedPlaylist } from "./hooks/useRecommendedPlaylist";
import { useAdState } from "./ads/hooks/useAdState";
import { Subtitles } from "../controls/Subtitles";
import AdWrapper from "./ads/AdWrapper";
import AdContainer from "./ads/AdContainer";
import {
  addOrcaEvent,
  addTagsToVideo,
  checkIfVideoTypeLive,
  getIsStickyEnabled,
  hasLiveEventEnded,
  removeSubtitles
} from "./utils";
import { EventEndedCurtain } from "./components/EventEndedCurtain";
import { SEOVideoObject } from "./components/SEOVideoObject";
import { Video } from "./OrcaPlayer.stitches";
import { FullScreenWrapper } from "./components/FullScreenWrapper";
import { useVideoHandlers } from "./hooks/useVideoHandlers";
import useFullscreen from "./hooks/useFullscreen";
import useUpdateDisplay from "./hooks/useUpdateDisplay";
import {
  autoplayStateAllowsAds,
  setNoAdsCookie,
  videoHasAds
} from "./ads/utils/helpers";
import { sendVideoSplunkEvent } from "./utils/sendVideoSplunkEvent";
import {
  SPLUNK_EVENT_NAMES,
  SPLUNK_EVENT_TYPES
} from "./splunk-events/globals";
import { useVideoDataContext } from "./contexts/VideoDataContext";
import { useVideoElementRefContext } from "./contexts/VideoElementRefContext";
import { useVideoAnalyticsContext } from "./contexts/VideoAnalyticsContext";
import { useAutoplayContext } from "./contexts/AutoplayContext";
import { PlayerSize, usePlayerSize } from "./hooks/usePlayerSize";
import { useStickyVideoContext } from "./contexts/StickyVideoContext";
import { useLiveConfig } from "./hooks/useLiveConfig";
import { DataLayer } from "./components/DataLayer";
import { usePerformanceContext } from "./contexts/PerformanceContext";
import { AdControlsProvider } from "./ads/contexts/AdControlsContext";
import { useAdsElementsRefContext } from "./contexts/AdsElementsRefContext";
import { useSubtitles } from "./hooks/useSubtitles";

export const OrcaPlayer = forwardRef(
  (
    {
      onVideoEnded = noop,
      config = {},
      promoEnabled = true,
      isSandbox = false
    },
    ref
  ) => {
    // Destructure props
    const {
      aspectRatio: _aspectRatio,
      aspectWidth = 16,
      aspectHeight = 9,
      placeholder,
      className,
      controlWrapperClassName,
      caption,
      alternateArt = null,
      playCallback = noop,
      carouselState = null,
      handleClosePlayer = noop,
      isCarousel = false,
      debug,
      debugHLS,
      allowShare = true,
      isSinglePrerollVideo = false
    } = config;

    const { originalVideo, videoData, videoId, uniqueId } =
      useVideoDataContext();
    const { autoplayState, updateAutoplayState, isMotionReduced } =
      useAutoplayContext();
    const videoElementRef = useVideoElementRefContext();
    const { sendAnalyticsEvent, sendVideoStartEvent, isAnalyticsInitialized } =
      useVideoAnalyticsContext();
    const { sendPerformanceEvent, sendPerformanceMeasure } =
      usePerformanceContext();
    const {
      isStickyEnabled,
      isOpenSticky,
      updateStickyContext,
      setOverrideStickyContext,
      setIsStickyEnabled
    } = useStickyVideoContext();
    const { adControlsRef } = useAdsElementsRefContext();

    // Setup refs
    const playerWrapperRef = useRef(null);
    const promoElement = useRef(null);
    const fullScreenWrapper = useRef();

    const playerSize = usePlayerSize(playerWrapperRef);

    const initDuration = videoData.duration / 1000 || 0;

    const isVertical =
      videoData.additional_properties?.vertical ||
      videoData.additional_properties?.videoCategory === "vertical" ||
      false;

    const isLive = checkIfVideoTypeLive(videoData);

    const [promoState, setPromoState] = useState(PromoState.LOADING);
    const [renderControls, setRenderControls] = useState(false);
    const [isNativeIOSPlayer, setIsNativeIOSPlayer] = useState(false);

    const transitionToVideo = useCallback(
      analyticsData => {
        if (!autoplayState.isLivePreview) {
          setPromoState(PromoState.HIDDEN);
        }

        setRenderControls(
          !(
            autoplayState.type === AutoPlayType.LOOP_NO_CONTROLS ||
            autoplayState.isLivePreview
          )
        );

        // carousel videos send specialized start events in the carousel component
        if (!isCarousel)
          sendVideoStartEvent(
            autoplayState,
            analyticsData ?? { ...analyticsData }
          );
        if (Hls.isSupported()) {
          play();
        } else {
          loadAndPlayVideo();
        }

        playCallback();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },
      [
        autoplayState.isLivePreview,
        autoplayState.type,
        playCallback,
        sendAnalyticsEvent,
        isCarousel
      ]
    );

    useEffect(() => {
      const newVideoEvent = new CustomEvent(VideoEvents.NEW_VIDEO_DATA, {
        detail: videoData
      });
      videoElementRef.current?.dispatchEvent(newVideoEvent);

      // For each new video, setup a window object to track events
      if (typeof window !== "undefined" && videoData) {
        window.orcaPlayers = window.orcaPlayers || {};

        window.orcaPlayers[videoId] = window.orcaPlayers[videoId] || {
          data: videoData,
          events: []
        };

        if (window.orcaPlayers[videoId].data !== videoData) {
          window.orcaPlayers[videoId].data = videoData;
        }
      }

      // This is a hack to test different autoplay tags on videos. Used only in MC storybook
      if (config?.isDebug) {
        addTagsToVideo(config?.debugTags, videoData);
      }

      // Remove the modifed tags from the video data.
      return () => {
        if (videoData?.taxonomy?.tags && originalVideo?.taxonomy?.tags) {
          videoData.taxonomy.tags = originalVideo.taxonomy.tags;
        }
      };
    }, [videoData?._id]);

    // Chartbeat analytics
    useChartbeat();

    const adOptions = {
      config,
      setPromoState,
      promoState,
      isVertical,
      isLive,
      transitionToVideo
    };

    const {
      adState,
      setAdState,
      adSettings,
      shouldShowAds,
      transitionToAd,
      isAdsInitialized,
      isIrisReady
    } = useAdState(adOptions);

    const isOrcaInitialized =
      autoplayState.isInitialized && isAnalyticsInitialized && isAdsInitialized;

    const aspectRatio = _aspectRatio || aspectHeight / aspectWidth;
    const canonicalUrl = () => {
      if (!videoData?.canonical_url) return null;

      return videoData.canonical_url.includes(BASE_URL)
        ? videoData.canonical_url
        : `${BASE_URL}${videoData.canonical_url}`;
    };

    // Video loading
    const { hls, removeHLS, setUpVideoSource } = useHLS(
      isCarousel,
      debug,
      debugHLS
    );

    const { hasSubtitles, updateCues, currentCue, setupSubtitlesListener } =
      useSubtitles(isNativeIOSPlayer);

    // Controls
    const {
      playerState,
      onPlayButton,
      play,
      pause,
      reset,
      toggleMute,
      handleVolumeChange,
      setShowSubtitles,
      handlePlayEvent,
      resetControlsState,
      handleLivePreview,
      setInitialMuteState
    } = useVideoControls({
      config,
      isCarousel,
      isNativeIOSPlayer,
      adState,
      updateAutoplayState
    });

    const { showSubtitles } = playerState;

    const {
      isFullscreen,
      setFullscreen: setIsFullscreen,
      showFullscreenBtn
    } = useFullscreen(
      debug,
      fullScreenWrapper,
      isCarousel,
      isVertical,
      setIsNativeIOSPlayer,
      showSubtitles,
      setShowSubtitles,
      setRenderControls
    );

    // Block auto-refresh on Homepage when a video is playing and unmuted and not past its end date, if it's a video event with an end date
    useBlockHomepageRefresh(playerState.isMuted, playerState.isPlaying);

    // Setup Splunk events
    const splunkParams = { isLive };
    useSplunkEvents(splunkParams, config);

    const loadAndPlayVideo = () => {
      const videoElement = videoElementRef.current;
      const loadOnce = () => {
        videoElement.removeEventListener("loadstart", loadOnce);
        play();
      };
      videoElement.addEventListener("loadstart", loadOnce);
      videoElement.load();
    };

    const closeStickyPlayer = useCallback(() => {
      sendAnalyticsEvent(VideoEvent.STICKY_PLAYER_CLOSE, {
        label: StickyPlayerEventLabel.CLICK_CLOSE
      });
      adState === AdState.PLAYING ? adControlsRef.current?.pauseAd() : pause();
      updateStickyContext({ playingId: null, openId: null });
    }, [adState, pause, sendAnalyticsEvent, updateStickyContext]);

    // Reset player
    const resetVideoState = useCallback(
      hasNextVideo => {
        // TODO - remove this error check once iris has fixed the issue
        if (adState !== AdState.ERROR) setAdState(AdState.INIT);

        setRenderControls(false);
        setPromoState(PromoState.READY);

        resetControlsState();

        updateAutoplayState({
          isPlaythrough: hasNextVideo,
          status: autoplayState.status ? AutoPlayStatus.COMPLETE : null
        });

        removeHLS();
        removeSubtitles(videoElementRef?.current);

        if (!hasNextVideo && isOpenSticky) closeStickyPlayer();
      },
      [
        autoplayState.status,
        resetControlsState,
        setAdState,
        updateAutoplayState,
        adState,
        removeHLS,
        videoElementRef,
        closeStickyPlayer,
        isOpenSticky
      ]
    );

    const processAdPlaybackBasedOnAutoplayStatus = useCallback(
      ({ hasAds, isAutoplay, forcePlayAd }) => {
        if (isAutoplay && !forcePlayAd) {
          videoElementRef.current.autoplay = true;
        }

        if (hasAds) {
          transitionToAd();
        } else {
          transitionToVideo();
          if (forcePlayAd) {
            setPromoState(PromoState.HIDDEN);
            setRenderControls(
              autoplayState.type !== AutoPlayType.LOOP_NO_CONTROLS
            );
          }
        }
      },
      [transitionToAd, transitionToVideo, videoElementRef, autoplayState.type]
    );

    const handleStart = useCallback(
      forcePlayAd => {
        const { isAutoplay } = autoplayState;

        const hasAds =
          shouldShowAds &&
          videoHasAds(videoData, config) &&
          (autoplayStateAllowsAds(autoplayState) || forcePlayAd);

        setUpVideoSource();

        setInitialMuteState(forcePlayAd);

        if (isStickyEnabled) {
          updateStickyContext({ playingId: uniqueId });
          setOverrideStickyContext(false);
        }

        if (isSinglePrerollVideo) {
          setNoAdsCookie(videoId);
        }

        setupSubtitlesListener();

        processAdPlaybackBasedOnAutoplayStatus({
          hasAds,
          isAutoplay,
          forcePlayAd
        });
      },
      [
        shouldShowAds,
        videoData,
        config,
        autoplayState,
        setUpVideoSource,
        processAdPlaybackBasedOnAutoplayStatus,
        updateStickyContext,
        isStickyEnabled,
        uniqueId,
        setOverrideStickyContext,
        isSinglePrerollVideo,
        videoId,
        setupSubtitlesListener,
        setInitialMuteState
      ]
    );

    // setup player for a new video
    const initializeNewVideo = useCallback(
      videoWillAutoplay => {
        // Kickoff autoplay flow
        if (videoWillAutoplay) {
          updateAutoplayState({ status: AutoPlayStatus.INIT });
          if (!autoplayState.isLivePreview) {
            setPromoState(
              shouldShowAds ? PromoState.BUFFER : PromoState.HIDDEN
            );
          }
        } else {
          setPromoState(PromoState.READY);
        }
        // on ios, we close fullscreen if we're going to show an ad
        // TO DO - move this into Kshitij's onStart function it is called after hls is setup
        if (isFullscreen && isNativeIOSPlayer && shouldShowAds) {
          videoElementRef.current.addEventListener(VideoEvents.CANPLAY, () => {
            setIsFullscreen(false);
          });
        }
      },
      [
        setPromoState,
        updateAutoplayState,
        setIsFullscreen,
        shouldShowAds,
        videoElementRef,
        isFullscreen,
        isNativeIOSPlayer,
        autoplayState.isLivePreview
      ]
    );

    const { switchToNextVideo, willPlaythrough, hasNextRecVideo } =
      useRecommendedPlaylist(
        config,
        isSandbox,
        isLive,
        initDuration,
        isMotionReduced,
        initializeNewVideo,
        resetVideoState
      );

    const handleVideoEnd = useCallback(() => {
      if (!autoplayState.isLooping) sendAnalyticsEvent(VideoEvent.COMPLETE);

      resetVideoState(hasNextRecVideo);

      if (willPlaythrough) {
        switchToNextVideo();
      }

      onVideoEnded();
    }, [
      autoplayState.isLooping,
      sendAnalyticsEvent,
      resetVideoState,
      willPlaythrough,
      switchToNextVideo,
      onVideoEnded,
      hasNextRecVideo
    ]);

    // Functions surfaced on the orcaplayer ref
    useVideoHandlers(
      ref,
      reset,
      hls,
      play,
      setRenderControls,
      promoEnabled,
      promoElement,
      pause,
      playerState,
      toggleMute,
      adState,
      initializeNewVideo,
      resetVideoState,
      setUpVideoSource,
      transitionToVideo
    );

    const togglePlay = () => {
      if (adState !== AdState.PLAYING) onPlayButton();
    };

    useEffect(() => {
      if (!isOrcaInitialized) return;
      initializeNewVideo(autoplayState.isAutoplay);
      setIsStickyEnabled(getIsStickyEnabled(autoplayState, config));

      sendPerformanceEvent(PerformanceEvents.ORCA_READY);

      sendPerformanceMeasure(
        PerformanceEvents.ORCA_READY,
        PerformanceEvents.ORCA_RENDER,
        { irisLoaded: isIrisReady }
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOrcaInitialized]);

    // Kickoff autoplay flow
    useEffect(() => {
      if (autoplayState.status !== AutoPlayStatus.INIT) return;
      handleStart();
      updateAutoplayState({
        status: AutoPlayStatus.READY
      });
      // We only want this to run directly after initializeNewVideo sets autoplayState.status
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoplayState.status]);

    const handlePromoClick = useCallback(() => {
      sendAnalyticsEvent(VideoEvent.PROMO_CLICK);
      sendPerformanceEvent(PerformanceEvents.PROMO_CLICK);
      if (autoplayState.isLivePreview) {
        handleLivePreview();
      }
      handleStart(autoplayState.isLivePreview);
    }, [
      sendAnalyticsEvent,
      handleStart,
      autoplayState.isLivePreview,
      handleLivePreview,
      sendPerformanceEvent
    ]);

    // Render EventEndedCurtain and stop playing the video stream if Orca is playing a live event that has ended
    const liveEventEnded = useMemo(() => {
      return hasLiveEventEnded(videoData);
    }, [videoData]);

    useEffect(() => {
      if (liveEventEnded) reset();
    }, [liveEventEnded, playerState.isPlaying]);

    const handlePlay = () => {
      addOrcaEvent(OrcaWindowEvents.PLAY, true, videoId, debug);

      handlePlayEvent(true);
      if (isStickyEnabled) {
        updateStickyContext({ playingId: uniqueId });
        updateDisplay({ state: "media-start" });
      }
    };

    const handlePause = () => {
      addOrcaEvent(OrcaWindowEvents.PAUSE, true, videoId, debug);
      handlePlayEvent(false);
    };

    /**
     * Display framework hook.
     * Pause the video if a wall is displayed or pause the video if another
     * video is playing.
     */
    const updateDisplay = useUpdateDisplay(
      playerState,
      adState,
      pause,
      adControlsRef
    );

    useEffect(() => {
      window.dispatchEvent(
        new CustomEvent(VideoEvents.ORCA_RENDER, {
          detail: { videoId, videoElementRef }
        })
      );
      addOrcaEvent(VideoEvents.ORCA_RENDER, true, videoId, debug);
      sendVideoSplunkEvent(
        videoElementRef,
        SPLUNK_EVENT_TYPES.RENDER,
        SPLUNK_EVENT_NAMES.RENDER,
        splunkParams,
        config
      );
      sendPerformanceEvent(PerformanceEvents.ORCA_RENDER);
    }, []);

    const isMiniPlayer = playerSize === PlayerSize.MINI && !isFullscreen;

    const streamAspectRatio =
      videoData?.streams?.[0].height / videoData?.streams?.[0].width;

    const aspectMatch = aspectRatio === streamAspectRatio;

    useLiveConfig(isCarousel, config, ref);

    return (
      <div ref={playerWrapperRef}>
        <Wrapper
          {...{
            aspectRatio,
            aspectMatch,
            placeholder,
            className,
            caption,
            promoState,
            playerState
          }}
        >
          {liveEventEnded && <EventEndedCurtain playerSize={playerSize} />}
          <FullScreenWrapper
            {...{
              fullScreenWrapper,
              controlWrapperClassName,
              isFullscreen,
              liveEventEnded,
              aspectRatio
            }}
          >
            <Promo
              {...{
                alternateArt,
                promoState,
                setPromoState,
                config,
                promoEnabled,
                shouldShowAds,
                liveEventEnded,
                isCarousel,
                setAdState,
                aspectRatio,
                playerSize,
                aspectMatch
              }}
              onClick={handlePromoClick}
              ref={promoElement}
              videoControls={{ toggleMute, pause }}
            />
            {/*In some cases, we will have an empty video object (while it's being fetched), in which case we use uuid to attach iris to the correct dom elements*/}
            <AdWrapper adSettings={adSettings} config={config}>
              <AdControlsProvider
                isPreroll={false}
                toggleMute={toggleMute}
                setAdState={setAdState}
                transitionToVideo={transitionToVideo}
                adState={adState}
              >
                <Video
                  id={`orca-video-${videoId}`}
                  data-testid="orca-video"
                  className="w-100 orca-video"
                  aria-hidden="true"
                  crossOrigin="anonymous"
                  ref={videoElementRef}
                  onTimeUpdate={updateCues}
                  onEnded={handleVideoEnd}
                  onPlay={handlePlay}
                  onPause={handlePause}
                  onVolumeChange={() => {
                    addOrcaEvent(
                      OrcaWindowEvents.VOLUME_CHANGE,
                      videoElementRef.current.volume,
                      videoId,
                      debug
                    );
                  }}
                  preload="metadata" // should we add logic to preload="none"?
                  controlsList="nodownload"
                  playsInline
                />
                <AdContainer
                  {...{
                    playerState,
                    setPromoState,
                    adSettings,
                    transitionToVideo,
                    setAdState,
                    debug,
                    setRenderControls,
                    adState,
                    closeStickyPlayer,
                    updateDisplay,
                    isFullscreen,
                    isAdsInitialized,
                    pause
                  }}
                  ref={adControlsRef}
                />
                {renderControls && (
                  <>
                    {hasSubtitles && currentCue && (
                      <Subtitles
                        isFullscreen={isFullscreen}
                        currentCue={currentCue}
                        showSubtitles={playerState.showSubtitles}
                      />
                    )}
                    <Controls
                      config={config}
                      initDuration={initDuration}
                      playerState={playerState}
                      isVertical={isVertical}
                      toggleMute={toggleMute}
                      togglePlay={togglePlay}
                      handleVolumeChange={handleVolumeChange}
                      setShowSubtitles={setShowSubtitles}
                      isFullscreen={isFullscreen}
                      setIsFullscreen={setIsFullscreen}
                      canonicalUrl={canonicalUrl()}
                      hasSubtitles={hasSubtitles}
                      isCarousel={isCarousel}
                      carouselEnd={carouselState?.isEnd}
                      closeCarousel={handleClosePlayer}
                      isLive={isLive}
                      showFullscreenBtn={showFullscreenBtn}
                      debug={debug}
                      closeStickyPlayer={closeStickyPlayer}
                      allowShare={allowShare}
                      isMiniPlayer={isMiniPlayer}
                    />
                  </>
                )}
              </AdControlsProvider>
            </AdWrapper>
          </FullScreenWrapper>
        </Wrapper>
        <SEOVideoObject />
        <DataLayer isLive={isLive} playerState={playerState} />
      </div>
    );
  }
);

OrcaPlayer.displayName = "OrcaPlayer";

OrcaPlayer.propTypes = OrcaPropTypes;

import React from "react";
import { styled } from "@washingtonpost/wpds-ui-kit";
import PropTypes from "prop-types";

const SubtitlesWrapper = styled("div", {
  display: "flex",
  justifyContent: "center",
  width: "100%",
  opacity: 1,
  zIndex: 2,
  position: "absolute",
  pointerEvents: "none",
  userSelect: "none",
  transition: "all 0.2s ease-out",
  lineHeight: "normal"
});

const SubtitlesElement = styled("div", {
  background: "rgb(0,0,0,0.5)",
  color: "rgb(240,248,255)",
  fontFamily: "sans-serif",
  textShadow: "none",
  borderRadius: "3px",
  padding: "3px",
  margin: "5px",
  textAlign: "center",
  fontSize: "$100",
  variants: {
    variant: {
      fullscreen: {
        fontSize: "$150",
        "@minMd": {
          fontSize: "$200"
        },
        "@minLg": {
          fontSize: "$250"
        }
      }
    }
  }
});

export const Subtitles = ({ currentCue, showSubtitles, isFullscreen }) => {
  return (
    <SubtitlesWrapper css={{ bottom: isFullscreen ? "100px" : "$125" }}>
      {showSubtitles && (
        <SubtitlesElement
          aria-hidden="true"
          id="video-subtitles"
          {...(isFullscreen && { variant: "fullscreen" })}
        >
          {currentCue}
        </SubtitlesElement>
      )}
    </SubtitlesWrapper>
  );
};

Subtitles.propTypes = {
  /** The subtitle currently being displayed. */
  currentCue: PropTypes.string.isRequired,
  /** Boolean to toggle subtitle visibility. */
  showSubtitles: PropTypes.bool.isRequired,
  /** Boolean to check if orca player is open in fullscreen */
  isFullscreen: PropTypes.bool.isRequired
};

import { styled } from "@washingtonpost/wpds-ui-kit";

export const YoutubePlayerWrapper = styled("div", {
  position: "relative",
  paddingTop: "56.25%",
  "& iframe": {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "0",
    left: "0"
  }
});

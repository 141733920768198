import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, Success } from "@washingtonpost/wpds-assets";
import { Icon, Button, styled } from "@washingtonpost/wpds-ui-kit";
import "./index.css";

const CopyButtonLabel = styled("p", { fontSize: "$075" });

/**
 * Component that display Copy Button.
 * @returns A button containing the wpds link icon.
 */
export const CopyButton = ({ content }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  // Clear copied message after 3 seconds
  useEffect(() => {
    if (showConfirmation === false) {
      return;
    }

    const timeoutId = setTimeout(() => setShowConfirmation(false), 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [showConfirmation]);

  const copyTextToClipboard = () => {
    navigator.clipboard.writeText(content);
    setShowConfirmation(true);
  };

  return (
    <div className="flex justify-center items-center flex-column">
      <Button
        aria-label="Copy link"
        icon="center"
        className={"focus-visible-highlight"}
        css={{
          backgroundColor: showConfirmation && "$green600",
          marginBottom: "$050"
        }}
        onClick={copyTextToClipboard}
      >
        <Icon fill="black" size={125} label="Link URL">
          {showConfirmation ? <Success /> : <Link />}
        </Icon>
      </Button>
      <CopyButtonLabel>
        {showConfirmation ? "Copied!" : "Link URL"}
      </CopyButtonLabel>
    </div>
  );
};

CopyButton.displayName = "CopyButton";

CopyButton.propTypes = {
  /** The content to copy to the clipboard. */
  content: PropTypes.string.isRequired
};

import React from "react";
import PropTypes from "prop-types";

import { OrcaPlayer } from "./OrcaPlayer";
import { useVideoDataContext } from "./contexts/VideoDataContext";
import { YoutubePlayer } from "./components/YoutubePlayer";
import { hasYoutubeTag } from "./components/YoutubePlayer/utils";

export const PlayerSelection = ({
  forwardedRef,
  isCarousel,
  ...videoProps
}) => {
  const { videoData } = useVideoDataContext();
  const hasYoutubeId = videoData.additional_properties?.youtubeVideoId;
  const renderYoutubePlayer =
    !isCarousel && hasYoutubeTag(videoData) && hasYoutubeId;

  return renderYoutubePlayer ? (
    <YoutubePlayer />
  ) : (
    <OrcaPlayer ref={forwardedRef} {...videoProps} />
  );
};

PlayerSelection.propTypes = {
  forwardedRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isCarousel: PropTypes.bool
};

import React from "react";

import { InnerWrapper, LeftControls, RightControls } from "./index.stitches";
import { SkipButton, skipButtonProps } from "./SkipButton";
import {
  PlayPauseButton,
  playPauseButtonProps
} from "../../../controls/buttons/PlayPauseButton";
import {
  SoundButton,
  toggleMuteProp
} from "../../../controls/buttons/SoundButton";
import { useAdControlsContext } from "../contexts/AdControlsContext";

const BottomControls = ({ skip, toggleAdPlay, toggleMute }) => {
  const { showCountdown, skipReady, adMuted } = useAdControlsContext();

  return (
    <InnerWrapper position="bottomControls" css={{ padding: 0 }}>
      <LeftControls>
        <PlayPauseButton
          // we extend the button click area to avoid accidentally going to advertiser
          buttonStyles={{
            padding: "12px 20px 20px 20px",
            margin: "0 -8px -8px -8px"
          }}
          {...{ toggleAdPlay }}
        />
        <SoundButton
          buttonStyles={{
            // we extend the button click area to avoid accidentally going to advertiser
            padding: "12px 12px 20px 12px",
            paddingLeft: adMuted ? "12px" : "12.5px",
            margin: "0 0px -8px 0"
          }}
          {...{ toggleMute }}
        />
      </LeftControls>
      <RightControls>
        {(skipReady || showCountdown) && <SkipButton {...{ skip }} />}
      </RightControls>
    </InnerWrapper>
  );
};

BottomControls.propTypes = {
  ...skipButtonProps,
  ...playPauseButtonProps,
  ...toggleMuteProp
};

export default BottomControls;

import { useCallback, useEffect, useMemo, useState } from "react";
import { fetchRecommendedPlaylist } from "../utils/fetchRecommendedPlaylist";
import { VideoApiActionTypes } from "./useVideoApi";
import { useVideoDataContext } from "../contexts/VideoDataContext";
import { useAutoplayContext } from "../contexts/AutoplayContext";
import { useVideoElementRefContext } from "../contexts/VideoElementRefContext";
import { VideoEvents } from "../../globals";
import { useStickyVideoContext } from "../contexts/StickyVideoContext";

/**
 *  A custom hook for handling the orca recommended playlist
 * @returns {boolean} willPlayRecVideos - will this video play through to recommended videos?
 * @returns {function} switchToNextRecVideo - a function which switches videoData to the next recommended vid and sets that vid up to play
 */
export const useRecommendedPlaylist = (
  config,
  isSandbox,
  isLive,
  initDuration,
  isMotionReduced,
  initializeNewVideo
) => {
  const {
    videoData,
    dispatchVideoApiEvent,
    originalVideo: firstVideo
  } = useVideoDataContext();
  const { autoplayState } = useAutoplayContext();
  const videoElementRef = useVideoElementRefContext();
  const { isOpenSticky, setOverrideStickyContext } = useStickyVideoContext();

  const { allowPlaythrough = true } = config;

  const [recommendedPlaylist, setRecommendedPlaylist] = useState([]);
  const hasNextRecVideo = recommendedPlaylist.length > 0;

  const [fetchCompleted, setFetchCompleted] = useState(false);

  // Determine if this orca will play through to fetched recommended videos
  const willPlaythrough = useMemo(() => {
    const playthroughValue =
      videoData?.additional_properties?.embedContinuousPlay &&
      allowPlaythrough &&
      !isMotionReduced &&
      !autoplayState?.isLooping &&
      !isLive;
    return playthroughValue;
  }, [
    videoData,
    allowPlaythrough,
    isMotionReduced,
    autoplayState?.isLooping,
    isLive
  ]);

  // Switch to the next video in the recommended playlist
  const switchToNextVideo = useCallback(() => {
    // If we've reached the end of the recommended playlist, return to the first video
    const nextVideo = hasNextRecVideo ? recommendedPlaylist[0] : firstVideo;

    dispatchVideoApiEvent({
      type: VideoApiActionTypes.SET_NEXT,
      videoData: nextVideo
    });

    if (hasNextRecVideo) setRecommendedPlaylist(recommendedPlaylist.slice(1));

    // If we have the sticky player open, we want next vid to also be open sticky
    if (isOpenSticky) {
      setOverrideStickyContext(true);
    }

    initializeNewVideo(hasNextRecVideo);
  }, [
    dispatchVideoApiEvent,
    isOpenSticky,
    setOverrideStickyContext,
    hasNextRecVideo,
    initializeNewVideo,
    firstVideo,
    recommendedPlaylist
  ]);

  useEffect(() => {
    if (!willPlaythrough) return;

    const videoElement = videoElementRef?.current;
    if (!videoElement) return;

    // When the video is 80% complete, fetch the recommended playlist
    const handleTimeUpdate = () => {
      if (fetchCompleted) return;
      const time = videoElementRef?.current?.currentTime;
      if (time > (80 * initDuration) / 100) {
        setFetchCompleted(true);
        fetchRecommendedPlaylist(firstVideo, isSandbox).then(recPlaylist => {
          setRecommendedPlaylist(recPlaylist);
        });
      }
    };

    videoElement.addEventListener(VideoEvents.TIME_UPDATE, handleTimeUpdate);

    return () => {
      videoElement.removeEventListener(
        VideoEvents.TIME_UPDATE,
        handleTimeUpdate
      );
    };
  }, [
    videoElementRef,
    willPlaythrough,
    isSandbox,
    firstVideo,
    recommendedPlaylist,
    fetchCompleted,
    initDuration
  ]);

  return {
    switchToNextVideo,
    willPlaythrough,
    hasNextRecVideo
  };
};

import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { isFullVideoData } from "../../lib/utils";
import { isEEA } from "../utils";
import { AutoPlayType, VideoEvents } from "../../globals";
import { useVideoDataContext } from "../contexts/VideoDataContext";
import { useVideoElementRefContext } from "../contexts/VideoElementRefContext";
import { useAutoplayContext } from "../contexts/AutoplayContext";

const CHARTBEAT_VIDEO_SCRIPT =
  "https://static.chartbeat.com/js/chartbeat_video.js";

/**
 * Hook to integrate Chartbeat analytics
 * https://docs.chartbeat.com/cbp/feature-integrations/video-engagement/custom-player-integration-sdk
 * @param video video data for the video in the player. a state variable created by a useState call in another component.
 * @param videoElementRef a ref attached to the HTML video element we're sending Chartbeat analytics for
 */
export const useChartbeat = () => {
  const videoElementRef = useVideoElementRefContext();
  const { videoData } = useVideoDataContext();
  const { autoplayState } = useAutoplayContext();

  const [isPlayerPushed, setIsPlayerPushed] = useState(false);

  const signalRef = useRef(null);
  const scriptRef = useRef(null);

  // separate useEffect without dependencies to create a cleanup function that only runs on OrcaPlayer unmount
  useEffect(() => {
    if (process.env.NODE_ENV === "development") return;
    // Create AbortSignal to remove event listeners added in the ChartbeatStrategy class object when OrcaPlayer unmounts
    const controller = new AbortController();
    const { signal } = controller;
    signalRef.current = signal;
    // Create chartbeat_video.js script element
    const script = document.createElement("script");
    script.src = CHARTBEAT_VIDEO_SCRIPT;
    script.async = true;
    script.id = "chartbeat_video";
    scriptRef.current = script;
    return () => {
      if (document.getElementById("chartbeat_video")) {
        document.body.removeChild(script);
      }
      // abort signal to remove event listeners
      controller?.abort();
    };
  }, []);

  const pushStrategyAndPlayer = player => {
    window._cbv_strategies = window._cbv_strategies || [];
    if (!window._cbv_strategies.includes(ChartbeatStrategy)) {
      window._cbv_strategies.push(ChartbeatStrategy);
    }

    window._cbv = window._cbv || [];

    window._sf_async_config.autoDetect = false;
    window._cbv.push(player);
  };

  // useEffect that re-runs when full video data loads
  useEffect(() => {
    if (process.env.NODE_ENV === "development") return;
    // Push strategy, push player, and load chartbeat script whenever a new video player loads on the page
    if (
      !isPlayerPushed &&
      isFullVideoData(videoData) &&
      signalRef.current &&
      scriptRef.current
    ) {
      const eeaUser = isEEA();
      // Do not use chartbeat for looping videos or European Economic Area (EEA) users (copying rules from Powa)
      if (autoplayState.isLooping || eeaUser) return;

      const player = {
        videoData,
        videoElementRef,
        autoplayType: autoplayState.type,
        signal: signalRef.current
      };
      if (window._sf_async_config) {
        // Note from Chartbeat documentation: Make sure to push your video strategy constructor to _cbv_strategies synchronously, before Chartbeat's pinger (chartbeat_video.js) loads.
        pushStrategyAndPlayer(player);
        setIsPlayerPushed(true);
        // load chartbeat video script if it's not already on the page
        if (!document.getElementById("chartbeat_video")) {
          document.body.appendChild(scriptRef.current);
        }
      }
    }
  }, [videoData?._id, signalRef.current, scriptRef.current, autoplayState]);

  /**
   * Constructor for the strategy. This is what gets passed to the SDK via: window['_cbv_strategies'].push(YourStrategy);
   * @param {Object} player A pointer to the player being tracked. The player is where all the video data is derived from
   */
  class ChartbeatStrategy {
    constructor(player) {
      this.player = player;
      this.startTime = null;
      this.lastPlayTime = null;
      this.videoEnded = false;
      this.currentVideoData = player.videoData;
      this.firstVideoData = player.videoData;

      // event listeners get removed when signal is aborted
      player.videoElementRef?.current?.addEventListener(
        VideoEvents.PLAY,
        this.handlePlay,
        {
          signal: this.player.signal
        }
      );
      player.videoElementRef?.current?.addEventListener(
        VideoEvents.END,
        this.handleEnd,
        {
          signal: this.player.signal
        }
      );
      player.videoElementRef?.current?.addEventListener(
        VideoEvents.NEW_VIDEO_DATA,
        this.handleNewVideoData,
        {
          signal: this.player.signal
        }
      );
      player.videoElementRef?.current?.addEventListener(
        VideoEvents.AD_START,
        this.handleAdStart,
        {
          signal: this.player.signal
        }
      );
    }

    handlePlay = () => {
      const playTime = Date.now();
      if (!this.startTime) {
        this.startTime = playTime;
      }
      this.lastPlayTime = playTime;
      this.videoEnded = false;
      this.contentType = CONTENT_TYPE.CONTENT;
    };

    handleEnd = () => {
      this.videoEnded = true;
    };

    handleNewVideoData = e => {
      this.currentVideoData = e.detail;
    };

    handleAdStart = () => {
      this.sawPreroll = true;
      this.contentType = CONTENT_TYPE.AD;
    };

    /**
     * Indicates if the video strategy is ready for pinging.
     * Typically this is called when all path and title metadata
     * is available
     * Note: Pings should only be sent after this reads true.
     * @return {boolean} The ready state of the strategy.
     */
    isReady() {
      return this.player.videoElementRef?.current && this.currentVideoData;
    }
    /**
     * Gets the human readable video title.
     * This is returned in the i key of the ping.
     * @return {string} The video title.
     */
    getTitle() {
      return this.currentVideoData?.headlines?.basic || "";
    }
    /**
     * Gets the video path.
     * This is returned in the p key of the ping.
     * Note: this should be the playable video path if available.
     * @return {string} The video path.
     */
    getVideoPath() {
      // Note: copying what Powa returns even though it's not a playable video path
      return this.currentVideoData?._id || "";
    }
    /**
     * Gets the type of video playing. Returns value from the ContentType enum.
     * This is returned in the _vt key of the ping.
     * @return {YourStrategy.ContentType} The type of content (ad or ct).
     */
    getContentType() {
      return this.contentType || CONTENT_TYPE.CONTENT;
    }
    /**
     * Gets the ad position. Returns value from the AD_POSITION enum.
     * This is returned in the _vap key of the ping.
     * @return The ad position
     * from a1 (pre-roll), a2 (mid-roll), a3 (post-roll),
     * a4 (overlay), or a5 (special).
     */
    getAdPosition() {
      return AD_POSITION.PREROLL;
    }
    /**
     * Gets the total duration of the video.
     * This is returned in the _vd key of the ping.
     * @return {number} The total duration time in milliseconds.
     */
    getTotalDuration() {
      return this.currentVideoData?.duration || 0;
    }
    /**
     * Gets the current state of the video. Returns value from the VIDEO_STATE enum.
     * This is returned in the _vs key of the ping.
     * @return {string} The current video state.
     */
    getState() {
      if (!this.player.videoElementRef?.current?.paused) {
        return VIDEO_STATE.PLAYED;
      } else if (this.videoEnded) {
        // On playthrough, this.videoEnded is set to true by handleEnd and immediately reset to false by handlePlay, so VIDEO_STATE.COMPLETED is never returned. This shouldn't interfere with Chartbeat stats, since video completion rate is not on their video dashboard or accessible through their advanced queries page
        return VIDEO_STATE.COMPLETED;
      } else if (!this.player.videoElementRef?.current?.currentTime) {
        return VIDEO_STATE.UNPLAYED;
      } else {
        return VIDEO_STATE.STOPPED;
      }
    }
    /**
     * Gets the current play time of the video (where the playhead is).
     * This is returned in the _vpt key of the ping.
     * @return {number} The current play time in milliseconds.
     */
    getCurrentPlayTime() {
      return this.player.videoElementRef?.current?.currentTime * 1000 || 0;
    }
    /**
     * Gets the current bitrate of the video.
     * This is returned in the _vbr key of the ping.
     * @return {number} The current bitrate in kbps.
     */
    getBitrate() {
      // PoWa doesn't return a bitrate so WaPo's Chartbeat analytics don't use bitrate
      // If requested by analytics, add logic to return bitrate being played by Hls
    }
    /**
     * Gets the thumbnail of the video.
     * This is returned in the _vtn key of the ping.
     * @return {string} The [absolute] path to the thumbnail.
     */
    getThumbnailPath() {
      return this.currentVideoData?.promo_image?.url || "";
    }
    /**
     * Gets the video player type.
     * This is returned in the _vplt key of the ping.
     * @return {string} The player type (user defined).
     */
    getPlayerType() {
      return this.currentVideoData?.type;
    }
    /**
     * Gets the time since start of viewing.
     * This is returned in the _vvs key of the ping.
     * @return {number} The time since viewing started in milliseconds.
     */
    getViewStartTime() {
      if (this.startTime) return Date.now() - this.startTime;
      return 0;
    }
    /**
     * Gets the time since start of viewing for users in a play state -- regardless of current state.
     * This is returned in the _vvsp key of the ping.
     * @return {number} The time since viewing started in milliseconds.
     */
    getViewPlayTime() {
      if (!this.player.videoElementRef?.current?.paused && this.lastPlayTime) {
        return Date.now() - this.player.lastPlayTime;
      }
      return 0;
    }

    /**
     * Gets the time since play start for users who saw a preroll ad -- regardless of current state.
     * The timer continues after the ad completes as well.
     * This is returned in the _vasp key of the ping.
     * @return {number} The time since viewing started in milliseconds.
     */
    getViewAdPlayTime() {
      if (this.sawPreroll && this.lastPlayTime)
        return Date.now() - this.startTime;
      return 0;
    }

    // This represents an abbreviated name for your custom player strategy.
    // @return {string} Strategy name
    // Note: function copied from Powa but not in Chartbeat Strategy template
    getStrategyName() {
      return "WPO"; // Washington Post Orca
    }

    /**
     * Return whether the given video clip is being played from manual play, autoplay, or continuous play (in a playlist or playthrough)
     * Note: copied from Powa but not in Chartbeat Strategy template
     * @return {string} AutoplayType
     */
    getAutoplayType() {
      if (this.currentVideoData._id !== this.firstVideoData._id) {
        return AUTOPLAY_TYPE.CONTINUOUS;
      } else if (this.player.autoplayType === AutoPlayType.AUTO) {
        return AUTOPLAY_TYPE.AUTOPLAY;
      } else {
        return AUTOPLAY_TYPE.MANUAL;
      }
    }

    /** Returns section(s) or other content
     * grouping(s) for the current media asset. These sections should be
     * relevant to video production and editorial teams in your
     * organization.
     * @returns {Arary} List of sections i.e. ['Sports', 'Politics'...]
     * Note: copied from Powa but not in Chartbeat Strategy template
     * */
    getSections() {
      const sections = this.currentVideoData?.taxonomy?.sections?.map(
        section => {
          return section.name;
        }
      );
      return sections || [];
    }
  }

  /**
   * Verifies that the given player belongs to this strategy. Used for a
   * greedy search of the matching strategy for a given element or object.
   * @param {Object} player A pointer to the player being tracked.
   * @return {boolean} If the strategy can handle this type of object.
   */
  ChartbeatStrategy.verify = function (player) {
    return (
      typeof player === "object" &&
      typeof player.videoData === "object" &&
      typeof player.videoElementRef === "object"
    );
  };

  ChartbeatStrategy.propTypes = {
    player: PropTypes.shape({
      videoData: PropTypes.object,
      videoElementRef: PropTypes.object,
      autoplayType: PropTypes.string
    })
  };
};

/**
 * Enum for the content type. One of these values should be returned from
 * the "getContentType" function.
 * @enum {string}
 */
const CONTENT_TYPE = {
  AD: "ad",
  CONTENT: "ct"
};

/**
 * Enum for the ad position. One of these values should be returned from
 * the "getAdPosition" function.
 * @enum {string}
 */
const AD_POSITION = {
  PREROLL: "a1",
  MIDROLL: "a2",
  POSTROLL: "a3",
  OVERLAY: "a4",
  SPECIAL: "a5"
};

/**
 * Enum for the video state. One of these values should be returned from
 * the "getState" function.
 * @enum {string}
 */
const VIDEO_STATE = {
  UNPLAYED: "s1",
  PLAYED: "s2",
  STOPPED: "s3",
  COMPLETED: "s4"
};

const AUTOPLAY_TYPE = {
  UNKNOWN: "unkn",
  MANUAL: "man",
  AUTOPLAY: "auto",
  CONTINUOUS: "cont"
};

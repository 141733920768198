import get from "lodash/get";

import { BLANK_CREATIVE_IDS, IRIS_TIMEOUT } from "../constants";
import { AdAttribute } from "../../../globals";
import { getPageType } from "../../../lib/analytics/utils";
import { getCookie, setCookie } from "../../../../common/cookie-util";
import { getPlayerHeight, getPlayerWidth, isLoopingVideo } from "../../utils";
import { getUserAgent } from "../../../../common/utils";
import { isPremium } from "../../../preroll/utils/preroll-utils";

const isHttpUrl = str => /^https?:\/\//.test(str);

function getEmbedHostname(
  embedDomain = window.self !== window.top
    ? document.referrer
    : window.location.href
) {
  try {
    if (!isHttpUrl(embedDomain)) return "";

    embedDomain = embedDomain.split("/")[2];
    embedDomain = embedDomain.split(":")[0];
  } catch (e) {
    console.log(e);
  }

  return embedDomain;
}

function getEmbedDomain(
  embedDomain = window.self !== window.top
    ? document.referrer
    : window.location.href
) {
  try {
    embedDomain = getEmbedHostname(embedDomain);

    if (embedDomain) {
      const parts = embedDomain.split(".");
      const { length } = parts;

      // depth is one greater than the TLD
      let depth = parts[length - 2] === "co" ? 3 : 2;

      if (length >= depth) {
        const chunks = [];
        while (depth) {
          chunks.push(parts[length - depth]);
          depth--;
        }
        embedDomain = chunks.join(".");
      }
    }
  } catch (e) {
    console.log(e);
  }

  return embedDomain;
}

function isWapoDomain(
  href = window.self !== window.top ? document.referrer : window.location.href
) {
  const testDomain = domain => {
    return /washingtonpost\.com|localhost|thelily\.com|arcpublishing\.com|wpprivate\.com|wapo\.st/.test(
      domain
    );
  };
  const embedDomain = getEmbedDomain(href); // the last two chunks of the domain
  if (testDomain(embedDomain) || testDomain(href)) return true;

  const embedHostname = getEmbedHostname(href); // the full hostname
  if (
    /www-washingtonpost-com\.cdn\.ampproject\.org|wpit\.nile\.works|washpost\.arcpublishing\.com/.test(
      embedHostname
    )
  )
    return true;

  return false;
}

export const isAdAllowed = (video, config) => {
  const adSetUrlApp = get(
    video,
    "additional_properties.advertising.adSetUrls.apps",
    ""
  );
  const adZone = get(
    video,
    "additional_properties.advertising.videoAdZone",
    ""
  );
  const isValidAdZone = /wpni.video/.test(adZone) === true;
  return (
    !!config?.adOverrides?.adZoneOverride ||
    (!!adSetUrlApp && !!adZone && isValidAdZone)
  );
};

export const isAdDisabledOnPlayer = config => {
  return !config.hasAds;
};

export const isBlankAd = (adData = {}) => {
  if (adData && typeof adData.creativeId === "string") {
    return BLANK_CREATIVE_IDS.includes(adData.creativeId);
  }
  return null;
};

export const getAvAdMeta = (adData = {}, skipTime = -1) => {
  return [
    `length:${adData.duration}`,
    `skipAllow:${skipTime > 0}`,
    `skipDelay:${skipTime}`
  ];
};

export const getAdAttribute = adData => {
  return isBlankAd(adData) ? AdAttribute.BLANK : AdAttribute.STARTED;
};

export const getSkipData = (remainingTime, currentTime, skipTimeOffset) => {
  const skipReady = skipTimeOffset > 0 && currentTime > skipTimeOffset;
  const skipCountDown = skipTimeOffset - currentTime;
  return {
    skipReady: skipReady,
    skipCountDown: skipCountDown,
    remainingTime: remainingTime
  };
};

export const getAdData = irisPlayer => {
  return irisPlayer.getCurrentAd()?.data;
};

function isAllowedOnDomain(videoData, domainOverride, debug) {
  if (isWapoDomain(domainOverride)) {
    return true;
  }

  if (
    !get(videoData, "additional_properties.advertising.allowPrerollOnDomain") &&
    debug
  ) {
    console.log("Ad not allowed on domain");
  }
  return get(
    videoData,
    "additional_properties.advertising.allowPrerollOnDomain"
  );
}

/**
 * @description Check if ads should play for a particular video. Even if this returns false, ads may play for subsequent vids
 * in playthrough/playlist
 * @param {Object} video - the ANS object for the video that is about to play
 * @param {Object} config - the orca component's config prop. If ads are turned off in the config, they will never render.
 * @returns {boolean}
 */
export function videoHasAds(video, config) {
  const {
    hasAds = false,
    adOverrides: { forceAd = false, domain } = {},
    debug
  } = config;
  // forceAd overrides other config/ANS settings
  if (forceAd) return true;
  const {
    additional_properties: {
      advertising: { playVideoAds = false } = {
        playVideoAds: false
      }
    } = {
      advertising: {}
    }
  } = video;

  return hasAds && playVideoAds && isAllowedOnDomain(video, domain, debug);
}

export function autoplayStateAllowsAds(autoplayState = {}) {
  // in general autoplay videos should not play ads. However, we make exceptions for referall and playthrough
  return (
    !autoplayState.isAutoplay ||
    autoplayState.isReferral ||
    autoplayState.isPlaythrough
  );
}

export const setNoAdsCookie = videoId => {
  if (typeof document === "undefined") return;
  const coookieStr = getCookie("wp_no_preroll");
  const cookieObject = coookieStr ? JSON.parse(coookieStr) : {};
  cookieObject[videoId] = "1";
  const updatedCookieStr = JSON.stringify(cookieObject);
  setCookie("wp_no_preroll", updatedCookieStr, 1);
};

const hasNoAdCookie = videoId => {
  if (!getCookie("wp_no_preroll")) return false;
  const cookieObject = JSON.parse(getCookie("wp_no_preroll"));
  return cookieObject[videoId] === "1";
};

/**
 * @description Check if ads are turned off in the config prop. If that is the case, ads will never play in this orca element
 * @param {Object} config - the player's config prop
 * @returns {boolean}
 */
export function getShouldShowAds(config, videoId) {
  const {
    hasAds = false,
    adOverrides: { forceAd = false } = {},
    isCarousel,
    isSinglePrerollVideo
  } = config;
  // forceAd overrides other config/ANS settings
  if (forceAd) return true;
  if (isSinglePrerollVideo && hasNoAdCookie(videoId)) return false;
  return hasAds && !getNoAdsParam() && !isPremium() && !isCarousel;
}

export const getNoAdsParam = () => {
  if (typeof window === "undefined") return false;
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.has("no_ads");
};

const getAdZoneSuffix = () => {
  // we trust akamai supplied devicetype if it exists
  const wapoDeviceTypeCookie = getCookie("wp_devicetype");
  if (wapoDeviceTypeCookie) {
    return wapoDeviceTypeCookie === "2"
      ? "_tablet"
      : wapoDeviceTypeCookie === "1"
      ? "_mweb"
      : "";
  }
  // otherwise we default to trusting navigator.userAgent
  const userAgent = getUserAgent();
  const isIOS = /ipod|iphone|ipad/i.test(userAgent);
  const isTablet = /tablet|ipad/i.test(userAgent);
  // Check for iOS included here to accomodate iOS WKWebViews with custom app names, which do not have "Mobile" in their UA strings
  const isMobile = !isTablet && (isIOS || /[^-]mobi/i.test(userAgent));
  if (isTablet) return "_tablet";
  if (isMobile) return "_mweb";

  return "";
};

/**
 * @description Adds mobile/tablet suffix to our adZones when neccessary. On mobile, we add "_mweb" to all adZones, on tablet we add "_tablet"
 * @param {Object} adZone - the base adZone, provided by the video ANS
 * @returns {string} the full adZone including necessaary suffix
 */
export const getDecoratedAdZone = adZone =>
  adZone?.includes("_") || adZone?.includes("/")
    ? adZone
    : adZone + getAdZoneSuffix();

// Determine ad settings from ANS/config/video state variables/default constants
export const getAdSettings = (
  config,
  video,
  videoElementRef,
  isVertical,
  isMuted,
  isLive,
  isPreroll,
  isPlaythrough
) => {
  const {
    additional_properties: {
      advertising: { videoAdZone } = {
        playAds: false,
        playVideoAds: false,
        videoAdZone: ""
      }
    } = {
      advertising: {}
    }
  } = video || {};
  const {
    adOverrides: {
      forceAd = false,
      adZoneOverride = null,
      customParamsOverride = [],
      adTagOverride,
      adTimeoutOverride,
      preloadAd = false,
      skip,
      skipmin,
      skipafter
    } = {},
    debugZeus
  } = config;

  const decoratedAdZone = getVideoAdZone(videoAdZone, forceAd, adZoneOverride);

  const adSettings = {
    // timeout length for ad request
    adTimeout: adTimeoutOverride || IRIS_TIMEOUT,
    videoAdZone: decoratedAdZone,
    forceAd,
    customParamsOverride,
    adTagOverride,
    preloadAd,
    uuid: video._id,
    debugZeus: debugZeus,
    isVertical: isVertical,
    isMuted: isPreroll ? true : isMuted,
    playerWidth: getPlayerWidth(videoElementRef),
    playerHeight: getPlayerHeight(videoElementRef),
    sections: video.taxonomy?.sections || [],
    playerType: getPlayerType(video),
    isLive: isLive,
    isPreroll: isPreroll,
    isPlaythrough: isPlaythrough,
    pageType: getPageType(),
    videoConfig: {
      ...(skip && { skip: skip }),
      ...(skipmin && { skipmin: skipmin }),
      ...(skipafter && { skipafter: skipafter })
    }
  };

  return adSettings;
};

const getPlayerType = video => {
  if (typeof window === "undefined") return;
  let playerType = `posttv-embed`;

  //looping vid
  if (isLoopingVideo(video)) {
    playerType = "posttv-embed-LoopingVideo";
    // we're on homepage
  } else if (
    get(window, "wp_meta_data.isHomepage") === true ||
    get(window, "Fusion.metas.contentType.value") === "homepage"
  ) {
    playerType = "posttv-embed-hp";
    // we're in an iframe
  } else if (window.self !== window.top) {
    playerType = "posttv-embed-iframe";
    // particular leaf page
  } else if (window.wp_pb && /video-leaf-page/.test(window.wp_pb.pageName)) {
    playerType = "posttv-leaf";
  }
  return playerType;
};

const getVideoAdZone = (videoAdZone, forceAd, adZoneOverride) => {
  if (adZoneOverride) return adZoneOverride;
  if (forceAd) return `wpni.video.test/appsalwayson`;
  return getDecoratedAdZone(videoAdZone);
};

import { useEffect } from "react";

import { VideoEvents } from "../../globals";
import { subscribeEvent, unsubscribeEvent } from "../utils/elementEvents";
import { SPLUNK_EVENT_NAMES, SPLUNK_EVENT_TYPES } from "./globals";
import { sendVideoSplunkEvent } from "../utils/sendVideoSplunkEvent";
import { getAdData, isBlankAd } from "../ads/utils/helpers";
import { useVideoElementRefContext } from "../contexts/VideoElementRefContext";
import { useAdsElementsRefContext } from "../contexts/AdsElementsRefContext";

// Sends Splunk events for when Iris events trigger for `ad start`, `ad completed` and `ad error`.

export const useSendVideoAdEvents = (splunkParams, config) => {
  const videoElementRef = useVideoElementRefContext();
  const { adWrapperRef } = useAdsElementsRefContext();

  const handleOnAdComplete = event => {
    const irisPlayer = adWrapperRef.current?.irisPlayer;
    const adData = getAdData(irisPlayer);
    if (!isBlankAd(adData)) {
      sendVideoSplunkEvent(
        videoElementRef,
        SPLUNK_EVENT_TYPES.ADS,
        SPLUNK_EVENT_NAMES.AD_COMPLETE,
        {
          adData: event.detail,
          adTitle: event.detail?.title,
          ...splunkParams
        },
        config
      );
    }
  };

  const handleOnAdError = event => {
    sendVideoSplunkEvent(
      videoElementRef,
      SPLUNK_EVENT_TYPES.ADS,
      SPLUNK_EVENT_NAMES.AD_ERROR,
      {
        adData: event.detail,
        adTitle: event.detail?.title,
        ...splunkParams
      },
      config
    );
  };

  const handleOnAdStart = event => {
    const irisPlayer = adWrapperRef.current?.irisPlayer;
    const adData = getAdData(irisPlayer);
    if (!isBlankAd(adData)) {
      sendVideoSplunkEvent(
        videoElementRef,
        SPLUNK_EVENT_TYPES.ADS,
        SPLUNK_EVENT_NAMES.AD_STARTED,
        {
          adData: event.detail,
          adTitle: event.detail?.title,
          ...splunkParams
        },
        config
      );
    }
  };

  useEffect(() => {
    const videoElement = videoElementRef?.current;
    if (!videoElement) return;

    subscribeEvent(videoElement, VideoEvents.AD_COMPLETE, handleOnAdComplete);
    subscribeEvent(videoElement, VideoEvents.AD_ERROR, handleOnAdError);
    subscribeEvent(videoElement, VideoEvents.AD_START, handleOnAdStart);

    return () => {
      unsubscribeEvent(
        videoElement,
        VideoEvents.AD_COMPLETE,
        handleOnAdComplete
      );
      unsubscribeEvent(videoElement, VideoEvents.AD_ERROR, handleOnAdError);
      unsubscribeEvent(videoElement, VideoEvents.AD_START, handleOnAdStart);
    };
  }, [videoElementRef]);
};

import { useSendVideoTimeouts } from "../splunk-events/useSendVideoTimeouts";
import { useSendVideoErrors } from "../splunk-events/useSendVideoErrors";
import { useSendVideoFullscreenEvents } from "../splunk-events/useSendVideoFullscreenEvents";
import { useSendVideoAdEvents } from "../splunk-events/useSendVideoAdEvents";

/**
 * Helper hook to send splunk events.
 * @param splunkParams contains video data passed into all splunk events
 * @param debug Boolean flag to log splunk events
 */
export const useSplunkEvents = (splunkParams, config) => {
  useSendVideoTimeouts(splunkParams, config);
  useSendVideoErrors(splunkParams, config);
  useSendVideoFullscreenEvents(splunkParams, config);
  useSendVideoAdEvents(splunkParams, config);
};

import React from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "@washingtonpost/wpds-ui-kit";
import { ChevronRight } from "@washingtonpost/wpds-assets";

import { SkipButtonInnerWrapper, Text } from "./index.stitches";
import { useAdControlsContext } from "../contexts/AdControlsContext";

export const SkipButton = ({ skip }) => {
  const { showCountdown, skipCountdown, skipReady } = useAdControlsContext();

  return (
    <Button
      component="skip"
      data-testid="skip-button"
      disabled={!skipReady}
      onClick={e => {
        e.stopPropagation();
        skip();
      }}
      css={{
        border: "none",
        background: "unset",
        padding: "12px",
        borderRadius: 0,
        "&:hover, &:disabled, &:disabled:hover": {
          background: "unset"
        }
      }}
    >
      <SkipButtonInnerWrapper
        css={{
          // we offset the right/left padding to account for space built into the ChevronRight icon
          padding: skipReady ? "7px 13px 7px 19px" : "7px 16px"
        }}
      >
        {skipReady ? (
          <SkipReadyContents />
        ) : showCountdown ? (
          <SkipCountdownContents skipCountdown={skipCountdown} />
        ) : null}
      </SkipButtonInnerWrapper>
    </Button>
  );
};

export const skipButtonProps = {
  skip: PropTypes.func
};

SkipButton.propTypes = {
  ...skipButtonProps
};

function SkipReadyContents() {
  return (
    <>
      <Text text="duration">Skip</Text>
      <Icon fill="white" label="skip" size="100">
        <ChevronRight />
      </Icon>
    </>
  );
}

function SkipCountdownContents({ skipCountdown }) {
  return <Text text="duration">Skip in {skipCountdown}</Text>;
}

SkipCountdownContents.propTypes = {
  skipCountdown: PropTypes.number
};

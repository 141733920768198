import { Box, styled } from "@washingtonpost/wpds-ui-kit";
import { generateGradient } from "../../../../common/utils";

export const FullWidth = styled(Box, {
  width: "100%"
});

export const BasicWrapper = styled(FullWidth, {
  height: "100%",
  position: "absolute",
  variants: {
    position: {
      outerWrapper: {
        top: 0
      },
      innerWrapper: {
        zIndex: 1,
        cursor: "pointer",
        background: generateGradient({
          topPercent: 15,
          bottomPercent: 25
        })
      }
    }
  }
});

export const LeftControls = styled(FullWidth, {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start"
});

export const RightControls = styled(FullWidth, {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end"
});

export const InnerWrapper = styled(FullWidth, {
  left: "0",
  position: "absolute",
  variants: {
    position: {
      topControls: {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        top: "0"
      },
      bottomControls: {
        bottom: "0",
        alignItems: "flex-end",
        display: "flex",
        justifyContent: "space-between"
      }
    }
  }
});

export const Text = styled(Box, {
  color: "$secondary",
  fontSize: "$100",
  variants: {
    text: {
      visitAdvertiser: {
        cursor: "pointer",
        width: "100%",
        textAlign: "end",
        padding: "12px"
      },
      duration: {
        fontWeight: "$light",
        justifySelf: "start"
      }
    }
  }
});

export const SkipButtonInnerWrapper = styled("div", {
  backgroundColor: "$alpha50",
  border: "1px solid $secondary",
  borderRadius: "$round",
  display: "flex",
  width: "100px",
  justifyContent: "center",
  "&:hover, &:disabled, &:disabled:hover": {
    backgroundColor: "$alpha50",
    border: "1px solid $secondary"
  }
});

export const SkipButtonWrapper = styled("div", {
  padding: "12px"
});

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useVideoDataContext } from "../contexts/VideoDataContext";
import { useAdsElementsRefContext } from "../contexts/AdsElementsRefContext";
import { getShouldShowAds } from "./utils/helpers";

// The OrcaAdWrapper contains both the orca player (which renders the provided video), and the AdContainer, which iris hooks into to render
// video advertisements. The wp-ad tag is how Zeus identifies this ad Node.
const OrcaAdWrapper = ({ adSettings, config, style = {}, children }) => {
  const { videoId } = useVideoDataContext();
  const { videoAdZone = "wpni.video", preloadAd } = adSettings.current;

  const dataJsonSettings = `{"prefetchAd":${preloadAd}}`;

  const { adWrapperRef } = useAdsElementsRefContext();

  const shouldRenderAds = useMemo(() => {
    return getShouldShowAds(config, videoId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {shouldRenderAds ? (
        <wp-ad
          ref={adWrapperRef}
          id={`ad-wrapper-${videoId}`}
          // Ad unit path is used to filter advertisements from Google Ad Manager
          data-adunitpath={`/701/${videoAdZone}`}
          data-media-type="video"
          data-media-player="imaPlayer"
          data-org="wapo"
          data-json={dataJsonSettings}
          style={style}
          class={`orca-new`}
        >
          {children}
        </wp-ad>
      ) : (
        <div ref={adWrapperRef}>{children}</div>
      )}
    </>
  );
};

OrcaAdWrapper.propTypes = {
  children: PropTypes.node,
  adSettings: PropTypes.object,
  style: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
    position: PropTypes.string
  }),
  config: PropTypes.object
};

export default OrcaAdWrapper;

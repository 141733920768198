const sm = 767;
const md = 1023;
const lg = 1440;

export const MOBILE = "MOBILE";
export const TABLET = "TABLET";
export const DESKTOP = "DESKTOP";
export const LARGE_DESKTOP = "LARGE_DESKTOP";

export const BreakPoints = {
  MOBILE: MOBILE,
  TABLET: TABLET,
  DESKTOP: DESKTOP,
  LARGE_DESKTOP: LARGE_DESKTOP
};

export const UiKitBreakPoints = {
  ["small"]: MOBILE,
  ["medium"]: TABLET,
  ["large"]: DESKTOP,
  ["xlarge"]: LARGE_DESKTOP,
  ["xxlarge"]: LARGE_DESKTOP,
  ["unknown"]: DESKTOP
};

/**
 * Function that returns a boolean based on whether user screen size is within standard mobile device width range
 *
 * @return {boolean}
 */
export function isMobile() {
  return typeof window !== "undefined" && window.innerWidth < sm;
}

/**
 * Function that returns a boolean based on whether user screen size is within standard tablet width range
 *
 * @return {boolean}
 */
export function isTablet() {
  return (
    typeof window !== "undefined" &&
    window.innerWidth >= sm &&
    window.innerWidth < md
  );
}

/**
 * Function that returns a boolean based on whether user screen size is within standard desktop width range
 *
 * @return {boolean}
 */
export function isDesktop() {
  return typeof window !== "undefined" && window.innerWidth >= md;
}

/**
 * Function that returns a boolean based on whether user screen size is in largest breakpoint range
 *
 * @return {boolean|boolean}
 */
export function isLargeDesktop() {
  return typeof window !== "undefined" && window.innerWidth >= lg;
}

/**
 * Function that returns a string of the device corresponding to the current window breakpoint
 * @return {undefined|string}
 */
export function getDeviceSize() {
  if (typeof window === "undefined") {
    return undefined;
  }
  return (
    (isMobile() && MOBILE) ||
    (isTablet() && TABLET) ||
    (isLargeDesktop() && LARGE_DESKTOP) ||
    DESKTOP
  );
}

import { useCallback, useEffect, useMemo, useRef } from "react";
import isEqual from "lodash.isequal";
import memoize from "lodash.memoize";
import merge from "lodash.merge";

import {
  AutoPlayStatus,
  AutoplayTags,
  OrcaWindowEvents,
  getLiveConfigUrl
} from "../../globals";
import {
  addOrcaEvent,
  checkIfVideoEnded,
  checkIfVideoLive,
  checkIfVideoTypeLive
} from "../utils";
import splunk from "../../../common/splunk";
import { VideoApiActionTypes } from "./useVideoApi";
import { useVideoDataContext } from "../contexts/VideoDataContext";
import { hasYoutubeTag } from "../components/YoutubePlayer/utils";
import { PlayerType } from "../../lib/analytics/utils";
import { useAutoplayContext } from "../contexts/AutoplayContext";

export const LIVE_CONFIG_ENV = "prod";
const LIVE_CONFIG_INTERVAL = 60 * 1000;

/**
 * Custom hook to fetch and update live configuration data for a video.
 * @param {Object} config - The configuration object.
 * @param {Function} setAdState - Set the ad state.
 */
export const useLiveConfig = (isCarousel, config, orcaRef) => {
  const {
    videoId,
    videoData: videoDataInState,
    videoApiUrl,
    domain,
    fetchedByUUID,
    dispatchVideoApiEvent
  } = useVideoDataContext();
  const { updateAutoplayState } = useAutoplayContext();

  const {
    liveConfigEnv = LIVE_CONFIG_ENV,
    forceLiveConfig,
    liveConfigInterval = LIVE_CONFIG_INTERVAL,
    debugTags = [],
    isLivePreviewEnabled
  } = config;

  const liveConfigUrl = useMemo(() => {
    return getLiveConfigUrl(liveConfigEnv);
  }, [liveConfigEnv]);

  const liveConfigIntervalRef = useRef();

  const updateVideoStateWithNewData = useCallback(
    async (updatedVideoApiData, liveConfigData, copyOfVideoData) => {
      const updatedVideoData = getUpdatedVideoData(
        liveConfigData,
        updatedVideoApiData,
        copyOfVideoData
      );

      memoize((video, updatedVideoData) => {
        setNewVideoData(
          video,
          updatedVideoData,
          liveConfigIntervalRef,
          dispatchVideoApiEvent,
          orcaRef,
          updateAutoplayState,
          isLivePreviewEnabled
        );
      })(videoDataInState, updatedVideoData);

      await handleForceSwitch(
        updatedVideoData,
        dispatchVideoApiEvent,
        videoApiUrl,
        domain,
        orcaRef
      );
    },
    [
      dispatchVideoApiEvent,
      domain,
      videoApiUrl,
      videoDataInState,
      orcaRef,
      updateAutoplayState,
      isLivePreviewEnabled
    ]
  );

  /**
   * @description - Gets the live config and updates the video data if it has changed.
   */
  const getLiveConfigAndUpdateVideoData = (checkVideoApi = true) => {
    // If video type is not live, don't make API calls.
    if (!checkIfVideoTypeLive(videoDataInState)) {
      return;
    }
    const copyOfVideoData = { ...videoDataInState };

    // live bar videos on homepage already fetch api updates, so we should
    // not make duplicate api calls.
    const { playerTypeOverride } = config;
    const skipFetch =
      playerTypeOverride === PlayerType.LIVE_BAR || !checkVideoApi;

    getUpdatedVideoAPIData({ videoApiUrl, domain }, videoId, skipFetch).then(
      updatedVideoApiData => {
        getLiveConfigData(liveConfigUrl).then(liveConfigData => {
          updateVideoStateWithNewData(
            updatedVideoApiData,
            liveConfigData,
            copyOfVideoData
          );
        });
      }
    );
  };

  /**
   * @description - Clears the interval when the tab is not visible, and restarts it when the tab is visible.
   */
  const onVisibilityChange = () => {
    if (document.hidden) {
      clearInterval(liveConfigIntervalRef.current);
    } else {
      clearInterval(liveConfigIntervalRef.current);
      liveConfigIntervalRef.current = setInterval(() => {
        getLiveConfigAndUpdateVideoData();
      }, liveConfigInterval);
    }
  };

  /**
   * This useEffect runs everytime video changes. On every run we:
   * 1. Run the getLiveConfig function to fetch the live configuration data.
   * 2. If the video is live, we set an interval to fetch the live configuration data.
   * 3. We clear the interval when the document is hidden.
   * 4. We remove the event listener when the component unmounts.
   * Every time the video changes, we clear the interval and set it again.
   */
  useEffect(() => {
    const isAutoplay = debugTags.includes(AutoplayTags.AUTOPLAY);
    if (isCarousel || isAutoplay) return;
    getLiveConfigAndUpdateVideoData(!fetchedByUUID);

    if (checkIfVideoTypeLive(videoDataInState) || forceLiveConfig) {
      liveConfigIntervalRef.current = setInterval(() => {
        getLiveConfigAndUpdateVideoData();

        document.addEventListener("visibilitychange", onVisibilityChange);
      }, liveConfigInterval);
    }

    return () => {
      clearInterval(liveConfigIntervalRef.current);
      document.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, [videoDataInState]);
};

const getUpdatedVideoAPIData = async (videoApi, videoID, skipFetch) => {
  if (skipFetch) return null;

  const { videoApiUrl, domain } = videoApi;
  try {
    const url = new URL(videoApiUrl);
    url.searchParams.append("uuid", videoID);
    url.searchParams.append("domain", domain);
    const response = await fetch(url, {
      cache: "no-cache"
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error retrieving video data", error);
    return null;
  }
};

export const getLiveConfigData = async liveConfigUrl => {
  try {
    const response = await fetch(liveConfigUrl, {
      cache: "no-cache"
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error retrieving live config", error);
    return null;
  }
};

export const getUpdatedVideoData = (
  liveConfigData,
  updatedVideoApiData,
  copyOfVideoData
) => {
  const apiUpdatedVideoData = updatedVideoApiData?.[0] || {};
  const videoWithApiUpdates = memoize(
    (copyOfVideoData, apiUpdatedVideoData) => {
      return merge({}, copyOfVideoData, apiUpdatedVideoData);
    }
  )(copyOfVideoData, apiUpdatedVideoData);

  return modifyVideoDataWithLiveConfig(liveConfigData, videoWithApiUpdates);
};

/**
 * Updates the video data based on the live configuration file.
 *
 * @param {object} liveConfigData - The live configuration object.
 * @param {object} videoData - The video data object to be updated.
 * @returns {object} - The updated video data object.
 */
const modifyVideoDataWithLiveConfig = (liveConfigData, videoData) => {
  const myConfig = liveConfigData[videoData._id] || {};
  if (Object.keys(myConfig).length > 0) {
    if (myConfig.forceStatus) {
      videoData.status = myConfig.forceStatus;
      if (checkIfVideoLive(videoData)) {
        delete videoData.additional_properties.publicationEndDate;
      }
    }

    if (myConfig.forceTags) {
      videoData.taxonomy = videoData.taxonomy || {};
      const existingTags = videoData.taxonomy?.tags.map(x => x.text) || [];
      myConfig.forceTags.forEach(tag => {
        if (!existingTags.includes(tag)) {
          videoData.taxonomy.tags.push({ text: tag });
        }
      });
    }

    if (myConfig.forceAdditionalProperties) {
      videoData.additional_properties = videoData.additional_properties || {};
      Object.assign(
        videoData.additional_properties,
        myConfig.forceAdditionalProperties
      );
    }
    return videoData;
  }

  return videoData;
};

export const handleForceSwitch = async (
  videoData,
  dispatchVideoApiEvent,
  videoApiUrl,
  domain,
  orcaRef
) => {
  const hasYoutubeId = videoData.additional_properties?.youtubeVideoId;
  const forceYoutubePlayer = hasYoutubeTag(videoData) && hasYoutubeId;

  if (forceYoutubePlayer) {
    return dispatchVideoApiEvent({
      type: VideoApiActionTypes.SET_NEXT,
      videoData: videoData
    });
  }

  const forceLoadVideoID = videoData?.additional_properties?.forceLoadVideo;

  if (forceLoadVideoID) {
    return await forceLoadVideo(
      forceLoadVideoID,
      videoApiUrl,
      domain,
      orcaRef,
      dispatchVideoApiEvent
    );
  }
};

const forceLoadVideo = async (
  forceLoadVideoID,
  videoApiUrl,
  domain,
  orcaRef,
  dispatchVideoApiEvent
) => {
  const url = new URL(videoApiUrl);
  url.searchParams.append("uuid", forceLoadVideoID);
  url.searchParams.append("domain", domain);
  try {
    const forceLoadReq = await fetch(url, {
      cache: "no-cache"
    });

    const forceLoadJson = await forceLoadReq.json();

    addOrcaEvent(OrcaWindowEvents.VIDEO_API_DATA, true, forceLoadVideoID);

    orcaRef.current.resetVideoState(false);
    dispatchVideoApiEvent({
      type: VideoApiActionTypes.SET_NEXT_FROM_FORCE_LOAD,
      videoData: forceLoadJson[0]
    });
    orcaRef.current.initializeNewVideo(false);
  } catch (e) {
    console.error("Cannot fetch video data for video ID: " + forceLoadVideoID);
    splunk.send("errorGettingVideoData", {
      userAgent: window.navigator.userAgent,
      href: window.location.href,
      errorMessage: "Video data not found for video ID: " + forceLoadVideoID
    });
  }
};

const setNewVideoData = (
  video,
  updatedVideoData,
  liveConfigIntervalRef,
  dispatchVideoApiEvent,
  orcaRef,
  updateAutoplayState,
  isLivePreviewEnabled
) => {
  if (!isEqual(video, updatedVideoData)) {
    clearInterval(liveConfigIntervalRef.current);
    dispatchVideoApiEvent({
      type: VideoApiActionTypes.SET_NEXT,
      videoData: updatedVideoData
    });
    if (checkIfVideoEnded(updatedVideoData)) {
      orcaRef.current.resetVideoState(false);
    } else if (checkIfVideoLive(updatedVideoData) && isLivePreviewEnabled) {
      updateAutoplayState({ status: AutoPlayStatus.INIT });
    }
  }
};

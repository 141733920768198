const TIMEOUT_EVENT_NAMES = {
  OK: "video-ok",
  TIMED_OUT: "video-timed-out",
  WAITING: "video-waiting"
};

const ERROR_EVENT_NAMES = {
  ERROR: "video-errored-out",
  API_ERROR: "video-api-request-failed"
};

const FULLSCREEN_EVENT_NAMES = {
  ON_FULLSCREEN: "video-fullscreen-opened",
  ON_UNFULLSCREEN: "video-fullscreen-closed"
};

const AD_EVENT_NAMES = {
  AD_COMPLETE: "video-ad-completed",
  AD_ERROR: "video-ad-error",
  AD_STARTED: "video-ad-started"
};

const RENDER_EVENT_NAMES = {
  RENDER: "video-rendered"
};

export const SPLUNK_EVENT_TYPES = {
  TIMEOUT: "timeout",
  ERROR: "error",
  FULLSCREEN: "fullscreen",
  ADS: "ads",
  RENDER: "render"
};

export const SPLUNK_EVENT_NAMES = {
  ...TIMEOUT_EVENT_NAMES,
  ...ERROR_EVENT_NAMES,
  ...FULLSCREEN_EVENT_NAMES,
  ...AD_EVENT_NAMES,
  ...RENDER_EVENT_NAMES
};

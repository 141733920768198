import React, { createContext, useContext, useRef } from "react";
import PropTypes from "prop-types";
import { useMemo } from "react";

const AdsElementsRefContext = createContext({
  adWrapperRef: { current: null },
  adContainerRef: { current: null },
  adControlsRef: { current: null }
});

export const useAdsElementsRefContext = () => useContext(AdsElementsRefContext);

export const AdsElementsRefProvider = ({ children }) => {
  const adWrapperRef = useRef();
  const adContainerRef = useRef();
  const adControlsRef = useRef();

  const contextValue = useMemo(
    () => ({
      adWrapperRef,
      adContainerRef,
      adControlsRef
    }),
    [adWrapperRef, adContainerRef, adControlsRef]
  );

  return (
    <AdsElementsRefContext.Provider value={contextValue}>
      {children}
    </AdsElementsRefContext.Provider>
  );
};

AdsElementsRefProvider.propTypes = {
  children: PropTypes.node,
  initialValue: PropTypes.any
};

import React, { createContext, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { AUTOPLAY_INIT_VALUE, useAutoplay } from "../hooks/useAutoplay";
import { useReducedMotion } from "../hooks/useReducedMotion";

const AutoplayContext = createContext({
  autoplayState: AUTOPLAY_INIT_VALUE,
  updateAutoplayState: () => {},
  isMotionReduced: false
});

export const useAutoplayContext = () => useContext(AutoplayContext);

export const AutoplayProvider = ({ children, config = {} }) => {
  // Disable autoplay, looping, and playthrough if user requests reduced motion
  // https://helpcenter.washingtonpost.com/hc/en-us/articles/13955752562971-How-do-I-turn-off-animations-looping-videos-and-other-motion-on-the-screen-
  const isMotionReduced = useReducedMotion();

  const {
    isLead,
    isLivePreviewEnabled = false,
    isAutoplayOverride,
    autoplayLive = false,
    forceNoLoop,
    isCarousel = false
  } = config;

  const { autoplayState, updateAutoplayState } = useAutoplay(
    isLead,
    isMotionReduced,
    isLivePreviewEnabled,
    isAutoplayOverride,
    autoplayLive,
    forceNoLoop,
    isCarousel
  );

  const contextValue = useMemo(
    () => ({
      autoplayState,
      updateAutoplayState,
      isMotionReduced
    }),
    [autoplayState, updateAutoplayState, isMotionReduced]
  );

  return (
    <AutoplayContext.Provider value={contextValue}>
      {children}
    </AutoplayContext.Provider>
  );
};

AutoplayProvider.propTypes = {
  config: PropTypes.object,
  children: PropTypes.node
};

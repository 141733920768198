import React from "react";
import PropTypes from "prop-types";

const PlayIcon = ({ className, onClick }) => (
  <svg
    className={className}
    onPointerDown={onClick}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 104 104"
    fill="#fff"
  >
    <polygon points="23 13 89.183 50.82 23 91" />
  </svg>
);

PlayIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default PlayIcon;

import { useState, useEffect } from "react";
import { BreakPoints, UiKitBreakPoints } from "../../utils/device-helper";
import {
  isDesktop as isDesktopSize,
  isLargeDesktop as isLargeDesktopSize,
  isTablet as isTabletSize
} from "../../utils/device-helper";

// Function to get the current screen size from CSS variable
const getScreenSizeFromCss = () => {
  if (typeof window === "undefined") {
    return "unknown";
  } else {
    // Get the value of the --wpds--screenSize CSS variable
    const screenSize = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue("--wpds--screenSize");

    // Return the value
    return screenSize;
  }
};

// Function to get the current screen size from window.innerWidth
const getScreenSizeLegacy = () => {
  if (typeof window === "undefined") {
    return "unknown";
  } else {
    return isLargeDesktopSize()
      ? BreakPoints.LARGE_DESKTOP
      : isDesktopSize()
      ? BreakPoints.DESKTOP
      : isTabletSize()
      ? BreakPoints.TABLET
      : BreakPoints.MOBILE;
  }
};

const useResponsiveScreenSize = () => {
  // State to store the current screen size
  const [screenSize, setScreenSize] = useState(
    getScreenSizeFromCss() || getScreenSizeLegacy()
  );

  useEffect(() => {
    // dont run on server
    if (typeof window === "undefined") return;

    const handleResize = () => {
      setScreenSize(getScreenSizeFromCss() || getScreenSizeLegacy());
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call the handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return screenSize;
};

function useDevice() {
  const [deviceSize, setDeviceSize] = useState(BreakPoints.DESKTOP);

  const size = useResponsiveScreenSize();

  useEffect(() => {
    setDeviceSize(UiKitBreakPoints[size]);
  }, [size]);

  return deviceSize;
}

export default useDevice;

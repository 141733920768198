import { Icon, styled } from "@washingtonpost/wpds-ui-kit";
import { Error } from "@washingtonpost/wpds-assets";
import React from "react";

const ErrorWrapper = styled("div", {
  width: "100%",
  height: "100%",
  position: "absolute",
  zIndex: "1",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "$175",
  background: "$primary"
});

const IconWrapper = styled("div", {
  padding: "$125"
});

const ErrorText = styled("div", {
  fontFamily: "$meta",
  color: "$secondary",
  fontSize: "$125",
  fontWeight: "$light",
  width: "40%",
  "@sm": {
    fontSize: "$087"
  }
});

export const ErrorHandler = () => {
  return (
    <ErrorWrapper>
      <IconWrapper>
        <Icon size={200} label={"error"} fill={"white"}>
          <Error />
        </Icon>
      </IconWrapper>
      <ErrorText> An error occurred. Please try again later.</ErrorText>
    </ErrorWrapper>
  );
};

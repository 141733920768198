import { useCallback, useEffect, useState } from "react";
import { useVideoDataContext } from "../contexts/VideoDataContext";
import { useVideoElementRefContext } from "../contexts/VideoElementRefContext";
import { setTextTracksToHidden } from "../utils";
import isEqual from "lodash/isEqual";

export const useKeepSubtitlesHidden = (videoElementRef, isNativeIOSPlayer) => {
  const trackMode = videoElementRef.current?.textTracks[0]?.mode;

  useEffect(() => {
    // We should only ever display native text tracks on iOS.
    if (!isNativeIOSPlayer && videoElementRef.current?.textTracks?.[0]?.mode) {
      videoElementRef.current.textTracks[0].mode = "hidden";
    }
  }, [trackMode, videoElementRef, isNativeIOSPlayer]);
};

/**
 *  A custom hook for managing the state of the subtitles.
 */
export const useSubtitles = isNativeIOSPlayer => {
  const videoElementRef = useVideoElementRefContext();
  const { videoData } = useVideoDataContext();
  const {
    subtitles,
    additional_properties: { doNotShowTranscripts = false } = {}
  } = videoData;

  useKeepSubtitlesHidden(videoElementRef, isNativeIOSPlayer);

  const [currentCue, setCurrentCue] = useState("");
  const [hasSubtitles, setHasSubtitles] = useState(false);

  const updateCues = useCallback(() => {
    setCurrentCue(getTrackToShow(videoElementRef.current));
  }, [videoElementRef]);

  const resetSubtitleState = useCallback(() => {
    setCurrentCue("");
    setHasSubtitles(!doNotShowTranscripts);
    videoElementRef.current.textTracks[0].mode = "hidden";
  }, [doNotShowTranscripts, videoElementRef]);

  const handleNewTrack = useCallback(() => {
    checkVideoElementForCaptions(
      videoElementRef.current,
      updateCues,
      resetSubtitleState
    );
  }, [videoElementRef, updateCues, resetSubtitleState]);

  const setupSubtitlesListener = useCallback(() => {
    addSubtitlesListener(videoElementRef.current, subtitles, handleNewTrack);
  }, [handleNewTrack, subtitles, videoElementRef]);

  return {
    hasSubtitles,
    updateCues,
    currentCue,
    setupSubtitlesListener,
    resetSubtitleState
  };
};

/**
 * Function which returns a `<track/>` element containing the subtitle
 * information from the ANS file.
 * @param data The information about the track.
 * @returns {HTMLTrackElement} The track DOM objects to be appended to the player.
 */
export const trackTemplate = data => {
  const { url, src, label, srclang, kind } = data || {};
  const template = document.createElement("track");

  Object.assign(template, {
    src: url || src || "",
    label: label || "English",
    srclang: srclang || "en",
    kind: kind || "captions",
    mode: "hidden"
  });

  return template;
};

export const addSubtitlesListener = (videoElement, subtitles, callback) => {
  setTextTracksToHidden(videoElement);

  // If we have a subtitle object in the ANS, create a track element and append it to the video element.
  if (subtitles && !isEqual(subtitles, {})) {
    const subtitlesTrack = trackTemplate(
      subtitles.urls?.find(track => track.format === "WEB_VTT")
    );

    videoElement.appendChild(subtitlesTrack);
  }
  videoElement.textTracks.addEventListener("addtrack", callback);
};

export const checkVideoElementForCaptions = (
  videoElement,
  updateCues,
  resetSubtitleState
) => {
  const tracksLength = videoElement.textTracks.length;
  if (tracksLength > 0) {
    resetSubtitleState();
    videoElement.textTracks[0].cueChange = updateCues;
    // If we have more than one cue, disable all but the first one.
    for (let i = 1; i < tracksLength; i++) {
      videoElement.textTracks[i].mode = "disabled";
    }
  }
};

export const getTrackToShow = videoElement => {
  return videoElement.textTracks[0]?.activeCues?.[0]?.text || "";
};

import { useEffect } from "react";
import { isFullVideoData } from "../../lib/utils";
import {
  addOrcaEvent,
  checkForForceLoad,
  checkIfVideoTypeLive,
  getVideoData
} from "../utils";
import { OrcaWindowEvents, getLiveConfigUrl } from "../../globals";
import { sendVideoSplunkEvent } from "../utils/sendVideoSplunkEvent";
import {
  SPLUNK_EVENT_NAMES,
  SPLUNK_EVENT_TYPES
} from "../splunk-events/globals";
import { useVideoElementRefContext } from "../contexts/VideoElementRefContext";
import {
  LIVE_CONFIG_ENV,
  getLiveConfigData,
  getUpdatedVideoData,
  handleForceSwitch
} from "./useLiveConfig";

export const setInitialVideoState = videoProp => {
  return {
    videoData: videoProp,
    videoApiDataFailed: false,
    originalVideo: null,
    fetchedByUUID: false,
    isForceLoadVideo: false
  };
};

export const VideoApiActionTypes = {
  SET_ORIGINAL: "SET_ORIGINAL",
  SET_ORIGINAL_FROM_API: "SET_ORIGINAL_FROM_API",
  SET_NEXT: "SET_NEXT",
  SET_NEXT_FROM_API: "SET_NEXT_FROM_API",
  SET_API_FAILED: "SET_API_FAILED",
  SET_NEXT_FROM_FORCE_LOAD: "SET_NEXT_FROM_FORCE_LOAD"
};

/**
 * A reducer function for the Video API state.
 * @param {object} state - The current state of the Video API.
 * @param {object} action - The action to be performed on the state.
 * @returns {object} The new state of the Video API.
 */
export const videoApiReducer = (state, action) => {
  switch (action.type) {
    case VideoApiActionTypes.SET_ORIGINAL_FROM_API:
      return {
        videoData: action.videoData,
        videoApiDataFailed: false,
        originalVideo: action.videoData,
        fetchedByUUID: true
      };
    case VideoApiActionTypes.SET_API_FAILED:
      return {
        ...state,
        videoApiDataFailed: true
      };
    case VideoApiActionTypes.SET_NEXT:
      return {
        ...state,
        videoData: action.videoData
      };
    case VideoApiActionTypes.SET_NEXT_FROM_API:
      return {
        ...state,
        videoData: action.videoData,
        videoApiDataFailed: false
      };
    case VideoApiActionTypes.SET_ORIGINAL:
      return {
        ...state,
        videoData: action.videoData,
        originalVideo: action.videoData
      };
    case VideoApiActionTypes.SET_NEXT_FROM_FORCE_LOAD:
      return {
        ...state,
        videoData: action.videoData,
        originalVideo: action.videoData,
        isForceLoadVideo: true
      };
    default:
      return state;
  }
};

/**
 * A React hook that uses the Video API to get data about a video.
 * It handles three scenarios:
 * 1. Orca is provided a uuid prop but no video prop. In this case, we fetch the
 * video data, and store it in state.
 * 2. The video prop changes, and we update the video data in state to match the new value.
 * This is the behavior when a user clicks on a new video in videos-nextjs.
 * 3. The user provides no uuid prop, and the videoData is not complete. In this case we enter
 * an error state.
 *
 * @param {boolean} debug - Whether or not to enable debug mode.
 * @param {function} dispatchVideoApiEvent - A function to dispatch events related to the Video API.
 * @param {string} domain - The domain of the video.
 * @param {object} originalVideoData - The original video data. Stored in state for playthrough purposes.
 * @param {string} uuid - The UUID of the video.
 * @param {string} videoApiUrl - The URL of the Video API.
 * @param {object} currentVideoData - The video data currently saved in state. This is used by the rest of orca.
 * @param {object} videoElement - The video element.
 * @param {object} propVideoData - The video data passed in as a prop.
 */
export const useVideoApi = (
  dispatchVideoApiEvent,
  domain,
  originalVideoData,
  uuid,
  videoApiUrl,
  currentVideoData,
  propVideoData,
  config,
  orcaRef
) => {
  const videoElementRef = useVideoElementRefContext();

  const { _id: propVideoId } = propVideoData;
  const { _id: originalVideoId } = originalVideoData || {};
  const { _id: currentVideoId } = currentVideoData || {};

  const { debug, hasNextVideo, liveConfigEnv = LIVE_CONFIG_ENV } = config;

  const liveConfigUrl = getLiveConfigUrl(liveConfigEnv);

  /**
   * First video passed to the orca player is stored in state as the
   * original video and used to fetch recommended playthrough.
   */
  useEffect(() => {
    if (propVideoId && !originalVideoId) {
      const setOriginalVideo = () => {
        dispatchVideoApiEvent({
          type: VideoApiActionTypes.SET_ORIGINAL,
          videoData: propVideoData
        });
      };
      // Before updating the videoData, check if we should force load from live config
      if (checkIfVideoTypeLive(propVideoData)) {
        handleLiveConfig(
          liveConfigUrl,
          propVideoData,
          dispatchVideoApiEvent,
          videoApiUrl,
          domain,
          orcaRef,
          setOriginalVideo
        );
      } else {
        setOriginalVideo();
      }
    }

    /**
     * If there is a UUID and the video prop is missing or incomplete,
     * make a request to the Video API to get the full video data.
     */
    if (
      uuid &&
      (!propVideoData || !isFullVideoData(propVideoData)) &&
      uuid !== currentVideoId
    ) {
      getVideoData({ videoApiUrl, domain }, uuid)
        .then(videoDataFromApi => {
          if (!videoDataFromApi)
            throw Error("Video data from API returned empty.");

          addOrcaEvent(
            OrcaWindowEvents.VIDEO_API_DATA,
            "API Success",
            uuid,
            debug
          );

          /**
           * If this is the first video, store the fetched data in state as both
           * original and current video.
           */
          if (!originalVideoId) {
            const setOriginalVideo = () => {
              dispatchVideoApiEvent({
                type: VideoApiActionTypes.SET_ORIGINAL_FROM_API,
                videoData: videoDataFromApi[0]
              });
            };
            // Before updating the videoData, check if we should force load from live config
            if (checkIfVideoTypeLive(videoDataFromApi[0])) {
              handleLiveConfig(
                liveConfigUrl,
                videoDataFromApi[0],
                dispatchVideoApiEvent,
                videoApiUrl,
                domain,
                orcaRef,
                setOriginalVideo
              );
            } else {
              setOriginalVideo();
            }
            /**
             * If there is already an original video, this is a playthrough vid,
             * so we kick off the autoplay flow
             */
          } else {
            dispatchVideoApiEvent({
              type: VideoApiActionTypes.SET_NEXT_FROM_API,
              videoData: videoDataFromApi[0]
            });
            orcaRef.current.resetVideoState(hasNextVideo);
            orcaRef.current.initializeNewVideo(hasNextVideo);
          }
        })
        .catch(({ message }) => {
          addOrcaEvent(OrcaWindowEvents.VIDEO_API_ERROR, message, uuid, debug);
          dispatchVideoApiEvent({
            type: VideoApiActionTypes.SET_API_FAILED
          });
          sendVideoSplunkEvent(
            videoElementRef,
            SPLUNK_EVENT_TYPES.ERROR,
            SPLUNK_EVENT_NAMES.API_ERROR,
            { errorMessage: message, uuid },
            config
          );
        });
    } else if (
      propVideoId &&
      currentVideoId &&
      propVideoId !== currentVideoId
    ) {
      /**
       * If the video prop changes, and is no longer equal to the current video
       * data, dispatch an event to set the next video.
       */
      dispatchVideoApiEvent({
        type: VideoApiActionTypes.SET_NEXT,
        videoData: propVideoData
      });
      /**
       * If there is a current vido, this is a playthrough vid,
       * so we kick off the autoplay flow
       */
      orcaRef.current.resetVideoState(hasNextVideo);
      orcaRef.current.initializeNewVideo(hasNextVideo);
    } else if (!uuid && (!propVideoData || !isFullVideoData(propVideoData))) {
      /**
       * If there is no UUID and the video prop is not a full video data object,
       * dispatch an event to set the video API data failed.
       */
      dispatchVideoApiEvent({
        type: VideoApiActionTypes.SET_API_FAILED
      });
      sendVideoSplunkEvent(
        videoElementRef,
        SPLUNK_EVENT_TYPES.ERROR,
        SPLUNK_EVENT_NAMES.API_ERROR,
        { errorMessage: "Video missing UUID and video data is empty." },
        config
      );
    }
  }, [uuid, propVideoId, hasNextVideo]);
};

const handleLiveConfig = (
  liveConfigUrl,
  videoData,
  dispatchVideoApiEvent,
  videoApiUrl,
  domain,
  orcaRef,
  fallback
) => {
  getLiveConfigData(liveConfigUrl).then(liveConfigData => {
    const myConfig = liveConfigData[videoData._id] || {};
    if (checkForForceLoad(myConfig)) {
      const updatedVideoData = getUpdatedVideoData(
        liveConfigData,
        {},
        videoData
      );
      return handleForceSwitch(
        updatedVideoData,
        dispatchVideoApiEvent,
        videoApiUrl,
        domain,
        orcaRef
      );
    } else {
      fallback();
    }
  });
};

import React, { useImperativeHandle, forwardRef } from "react";
import PropTypes from "prop-types";

import { AdContainerOuterWrapper } from "../components/AdContainerOuterWrapper";
import { TopControls, topControlsProps } from "../components/TopControls";
import BottomControls from "../components/BottomControls";
import useIrisEventListeners from "../hooks/useIrisEventListeners";
import { useVideoAnalyticsContext } from "../../contexts/VideoAnalyticsContext";
import { useAdControlsContext } from "../contexts/AdControlsContext";
import { useAdsElementsRefContext } from "../../contexts/AdsElementsRefContext";

// The AdContainer contains a rendered Zeus Iris advertisement. It includes controls for the ad and event listeners to record ad analytics
const AdContainer = forwardRef(
  (
    {
      playerState,
      setPromoState,
      adSettings,
      transitionToVideo,
      setAdState,
      debug,
      setRenderControls,
      adState,
      closeStickyPlayer,
      updateDisplay,
      isFullscreen,
      isAdsInitialized,
      pause: pauseVideo
    },
    ref
  ) => {
    const { pause, togglePlay, toggleAdMute, skip } = useAdControlsContext();
    const { setAvAdMeta, setAdAttribute } = useVideoAnalyticsContext();
    const { adWrapperRef } = useAdsElementsRefContext();

    useImperativeHandle(ref, () => ({
      pauseAd() {
        pause();
      },

      toggleAdMute() {
        toggleAdMute();
      }
    }));

    const advertiserUrl =
      adWrapperRef.current?.irisPlayer?.getCurrentAd()?.data?.clickThroughUrl;

    useIrisEventListeners({
      adSettings,
      adState,
      debug,
      transitionToVideo,
      setAdAttribute,
      setAdState,
      setAvAdMeta,
      setPromoState,
      setRenderControls,
      playerState,
      updateDisplay,
      isAdsInitialized,
      pauseVideo
    });

    return (
      <AdContainerOuterWrapper
        advertiserUrl={advertiserUrl}
        togglePlay={togglePlay}
      >
        <TopControls
          advertiserUrl={advertiserUrl}
          closeStickyPlayer={closeStickyPlayer}
          isFullscreen={isFullscreen}
        />
        <BottomControls
          skip={skip}
          toggleAdPlay={togglePlay}
          toggleMute={toggleAdMute}
        />
      </AdContainerOuterWrapper>
    );
  }
);

AdContainer.displayName = "AdContainer";

export default AdContainer;

AdContainer.propTypes = {
  toggleMute: PropTypes.func,
  playerState: PropTypes.object,
  setPromoState: PropTypes.func,
  adSettings: PropTypes.object,
  transitionToVideo: PropTypes.func,
  setAdState: PropTypes.func,
  debug: PropTypes.bool,
  setRenderControls: PropTypes.func,
  adState: PropTypes.string,
  updateDisplay: PropTypes.func,
  ...topControlsProps
};

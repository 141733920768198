import React, { createContext, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { StickyWrapper } from "../components/StickyWrapper";
import { useStickyPlayer } from "../hooks/useStickyPlayer";

const StickyVideoContext = createContext({
  isStickyEnabled: false,
  isOpenSticky: false,
  playingId: null,
  updateStickyContext: () => {},
  setOverrideStickyContext: () => {}
});

export const useStickyVideoContext = () => useContext(StickyVideoContext);

export const StickyVideoProvider = ({
  children,
  config = {},
  stickyPlayerContext
}) => {
  const {
    isStickyEnabled,
    isOpenSticky,
    playingId,
    openId,
    updateStickyContext,
    setOverrideStickyContext,
    setIsStickyEnabled
  } = useStickyPlayer(config, stickyPlayerContext);

  const contextValue = useMemo(
    () => ({
      isStickyEnabled,
      isOpenSticky,
      playingId,
      openId,
      updateStickyContext,
      setOverrideStickyContext,
      setIsStickyEnabled
    }),
    [
      isStickyEnabled,
      isOpenSticky,
      playingId,
      updateStickyContext,
      setOverrideStickyContext,
      openId,
      setIsStickyEnabled
    ]
  );

  return (
    <StickyVideoContext.Provider value={contextValue}>
      <StickyWrapper>{children}</StickyWrapper>
    </StickyVideoContext.Provider>
  );
};

StickyVideoProvider.propTypes = {
  config: PropTypes.object,
  children: PropTypes.node,
  stickyPlayerContext: PropTypes.object
};

import { useEffect, useState } from "react";
import Hls from "hls.js/dist/hls.min.js";
import { getDeviceType, getVideoStream, hasForceHDTag } from "../../lib/utils";
import { addOrcaEvent, isLoopingVideo } from "../utils";
import { AdAttribute, OrcaWindowEvents, VideoEvents } from "../../globals";
import PropTypes from "prop-types";

import { VideoApiActionTypes } from "./useVideoApi";
import { dispatchNewEvent } from "../utils/elementEvents";
import { useVideoDataContext } from "../contexts/VideoDataContext";
import { useVideoElementRefContext } from "../contexts/VideoElementRefContext";
import { useVideoAnalyticsContext } from "../contexts/VideoAnalyticsContext";

export const useHLS = (isCarousel, debug, debugHLS) => {
  const videoElementRef = useVideoElementRefContext();
  const { videoId, videoData, dispatchVideoApiEvent } = useVideoDataContext();
  const { setAdAttribute } = useVideoAnalyticsContext();

  const [hls, setHls] = useState(null);

  const removeHLS = () => {
    if (hls) {
      hls.off(Hls.Events.MEDIA_ATTACHED),
        () => {
          addOrcaEvent(
            OrcaWindowEvents.HLS_ATTACHED,
            videoElementRef.src,
            videoId,
            debug
          );
        };
      hls.off(Hls.Events.MANIFEST_PARSED),
        data => {
          addOrcaEvent(
            OrcaWindowEvents.HLS_MANIFEST_PARSED,
            data,
            videoId,
            debug
          );
        };
      hls.detachMedia();
      hls.destroy();
      setHls(null);
      dispatchNewEvent(videoElementRef.current, VideoEvents.DESTROYED);
    }
  };

  // startLevel = -1 means auto-select bitrate.
  const hlsConfig = {
    liveDurationInfinity: true,
    capLevelToPlayerSize: !isCarousel,
    maxBufferLength: isCarousel ? 5 : 10,
    autoStartLoad: false,
    startLevel: -1,
    debug: debugHLS
  };

  if (debugHLS) {
    console.log(hlsConfig);
  }

  const initHlsVideo = videoSource => {
    // See full list of config options: https://github.com/video-dev/hls.js/blob/master/docs/API.md#fine-tuning
    const newHls = new Hls(hlsConfig);

    newHls.on(Hls.Events.MEDIA_ATTACHED, () => {
      addOrcaEvent(OrcaWindowEvents.HLS_ATTACHED, videoSource, videoId, debug);
    });

    newHls.on(Hls.Events.MANIFEST_PARSED, data => {
      addOrcaEvent(OrcaWindowEvents.HLS_MANIFEST_PARSED, data, videoId, debug);
    });

    newHls.loadSource(videoSource);
    newHls.attachMedia(videoElementRef.current);
    newHls.startLoad();
    setHls(newHls);
  };

  /**
   * Sets up the video source based on the given video data.
   * If the video is looping, sets the "NOT_SUPPORTED" attribute for the ad.
   * Determines the appropriate video source based on device type, looping status, and force HD tag.
   * If no video source is found, dispatches a video API event with a failed status.
   * Sets the source of the video element based on the video source type.
   *
   * @returns {void}
   */
  const setUpVideoSource = () => {
    const forceHD = hasForceHDTag(videoData);
    const isLooping = isLoopingVideo(videoData);
    if (isLooping) setAdAttribute(AdAttribute.NOT_SUPPORTED);

    const videoSource = getVideoStream(
      videoData.streams,
      getDeviceType(),
      isLooping,
      forceHD
    );
    if (!videoSource) {
      dispatchVideoApiEvent({
        type: VideoApiActionTypes.SET_API_FAILED
      });
      return;
    }

    if (videoSource.stream_type === "mp4") {
      videoElementRef.current.src = videoSource.url;
    } else if (Hls.isSupported() && videoSource.url !== hls?.url) {
      initHlsVideo(videoSource.url);
    } else if (
      videoElementRef.current.canPlayType("application/vnd.apple.mpegurl")
    ) {
      videoElementRef.current.src = videoSource.url;
    }
  };

  useEffect(() => {
    // if video is tagged force-hd, start hls on the highest level playlist in the stream
    /// hls may lower quality of the video for low-bandwidth users but hls should start on the highest quality playlist
    const forceHD = hasForceHDTag(videoData);
    if (hls && forceHD) {
      hls.on(Hls.Events.MANIFEST_LOADED, () => {
        hls.startLevel = hls.levels.length - 1;
      });
    }
  }, [hls]);

  return {
    hls,
    removeHLS,
    setUpVideoSource
  };
};

useHLS.propTypes = {
  // is video in carousel
  isCarousel: PropTypes.bool,
  // debug mode
  debug: PropTypes.bool,
  // debug HLS
  debugHLS: PropTypes.bool
};

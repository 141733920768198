import React from "react";
import PropTypes from "prop-types";
import { Icon, Button } from "@washingtonpost/wpds-ui-kit";
import { Pause } from "@washingtonpost/wpds-assets";
// TO DO - notify WPDS that playIcon is not working, and update once theyve addressed
import PlayIcon from "./PlayIcon";
import { useAdControlsContext } from "../../orca/ads/contexts/AdControlsContext";
import "./index.css";

export const PlayPauseButton = ({
  isPlaying = null,
  toggleAdPlay,
  isMiniPlayer = false,
  buttonStyles
}) => {
  const { playing: adPlaying } = useAdControlsContext();

  const isPlayingVideoOrAd = isPlaying ?? adPlaying;
  return (
    <Button
      aria-label={isPlayingVideoOrAd ? "Pause video" : "Play video"}
      className={"focus-visible-highlight"}
      data-testid="orca-play-button"
      variant="primary"
      css={{
        border: "none",
        background: "none",
        padding: "0px",
        justifySelf: "center",
        ...(buttonStyles && { ...buttonStyles }),
        "&:hover": {
          background: "none"
        }
      }}
      {...(toggleAdPlay && {
        onClick: e => {
          e.stopPropagation();
          toggleAdPlay();
        }
      })}
    >
      <Icon fill="white" size={isMiniPlayer ? "100" : "150"} label="play">
        {isPlayingVideoOrAd ? <Pause /> : <PlayIcon />}
      </Icon>
    </Button>
  );
};

PlayPauseButton.displayName = "PlayPauseButton";

export const playPauseButtonProps = {
  isPlaying: PropTypes.bool,
  toggleAdPlay: PropTypes.func,
  isMiniPlayer: PropTypes.bool
};

PlayPauseButton.propTypes = {
  ...playPauseButtonProps
};

import React from "react";
import PropTypes from "prop-types";

const SubtitlesIcon = ({ className }) => (
  <svg
    className={className}
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2 19.6431V4.64307H23V19.6431H2Z" fill="white" />
    <path
      d="M11.3349 15C10.8257 15.4369 10.1699 15.6648 9.49941 15.6378C9.36372 15.6428 9.22789 15.6327 9.09441 15.6078C8.30744 15.5067 7.59257 15.098 7.10623 14.4711C6.61989 13.8442 6.4017 13.0502 6.49941 12.2628C6.49941 9.95282 7.68441 8.64782 9.49941 8.64782C10.1696 8.60675 10.8287 8.83322 11.332 9.27754C11.8354 9.72186 12.142 10.3477 12.1844 11.0178L10.5944 11.2728C10.4744 10.4178 10.2644 9.92282 9.49941 9.92282C8.73441 9.92282 8.29941 10.6428 8.29941 12.1428C8.29941 13.6428 8.85441 14.3778 9.55941 14.3778C10.2644 14.3778 10.6094 13.9278 10.7444 13.0278L12.2444 13.2828C12.1691 13.9496 11.8442 14.5631 11.3349 15Z"
      fill="#111111"
    />
    <path
      d="M17.5693 15.0214C17.0471 15.4598 16.3762 15.6806 15.6957 15.6378L15.4107 15.6228C15.0196 15.5728 14.6422 15.4462 14.3 15.2503C13.9579 15.0543 13.6577 14.7928 13.4167 14.4808C13.1757 14.1687 12.9986 13.8122 12.8954 13.4316C12.7923 13.051 12.7652 12.6539 12.8157 12.2628C12.8157 9.95282 14.0007 8.64782 15.8157 8.64782C16.4859 8.60675 17.145 8.83322 17.6484 9.27754C18.1517 9.72186 18.4583 10.3477 18.5007 11.0178L16.8357 11.2278C16.7157 10.3728 16.4907 9.87782 15.7407 9.87782C14.9907 9.87782 14.5557 10.6428 14.5557 12.1428C14.5557 13.6428 15.0957 14.3778 15.8157 14.3778C16.5357 14.3778 16.8807 13.9278 17.0007 13.0278L18.5007 13.2828C18.425 13.9605 18.0915 14.583 17.5693 15.0214Z"
      fill="#111111"
    />
  </svg>
);

SubtitlesIcon.propTypes = {
  className: PropTypes.string
};

export default SubtitlesIcon;

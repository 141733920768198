import React, { forwardRef, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";

import { getClasses } from "@washingtonpost/front-end-utils";
import { MediaState } from "./MediaState.js";
import {
  bound,
  addEventListeners,
  removeEventListeners
} from "../audio/utils.js";
import { Theme } from "./globals.js";
import { styled } from "@washingtonpost/wpds-ui-kit";
import "./scrubber.css";

const Event = {
  Mouse: { MOVE: "mousemove", UP: "mouseup", LEAVE: "mouseleave" },
  Touch: { MOVE: "touchmove", END: "touchend", CANCEL: "touchcancel" }
};

const ScrubberWrapper = styled("button", {
  backgroundColor: "transparent",
  border: "none"
});

const ScrubberProgress = styled("div", {
  background: "$gray20"
});

export const Scrubber = forwardRef(
  (
    {
      theme,
      changeToState,
      duration,
      setCurrentTime = noop,
      progress,
      setProgress
    },
    mediaRef
  ) => {
    const scrubberRef = useRef();

    const handleResize = () => {
      if (mediaRef.current.duration)
        setProgress(
          (mediaRef.current?.currentTime / mediaRef.current?.duration) *
            scrubberRef.current?.offsetWidth
        );
    };

    useEffect(() => {
      const resizer = new ResizeObserver(() => handleResize);
      resizer.observe(scrubberRef.current);
      return () => resizer && resizer.disconnect();
    }, []);

    const handleTimeUpdate = e => {
      setCurrentTime(e.target?.currentTime);
      handleResize();
    };

    useEffect(() => {
      const media = mediaRef.current;
      if (!media) return;
      media.addEventListener("timeupdate", handleTimeUpdate);
      return () => media.removeEventListener("timeupdate", handleTimeUpdate);
    }, []);

    const setPosition = clientX => {
      const currentWidth =
        clientX - scrubberRef.current?.getBoundingClientRect().left;
      const totalWidth = scrubberRef.current?.offsetWidth;
      const position = (currentWidth / totalWidth) * totalWidth || 0;
      setProgress(bound(position, 0, totalWidth));
      const newTime = (currentWidth / totalWidth) * duration;
      setCurrentTime(bound(newTime, 0, duration));
    };

    const setTimeForPosition = position => {
      const currentWidth =
        position - scrubberRef.current?.getBoundingClientRect().left;
      const totalWidth = scrubberRef.current?.offsetWidth;
      const newTime = (currentWidth / totalWidth) * duration || 0;
      return (mediaRef.current.currentTime = bound(newTime, 0, duration));
    };

    const handleMouseDown = () => {
      const isPaused = mediaRef.current?.paused;
      if (!isPaused) mediaRef.current?.pause();
      const handleMouseMove = e => setPosition(e.clientX);
      const handleMouseUp = e => {
        const newTime = setTimeForPosition(e.clientX);
        if (newTime === duration) changeToState(MediaState.READY);
        // if content is paused before scrubbing begins, content stays paused
        // if content is not paused before scrubbing begins, content plays after scrubbing
        else if (!isPaused) mediaRef.current?.play();
        removeEventListeners(document, [
          [Event.Mouse.MOVE, handleMouseMove],
          [Event.Mouse.UP, handleMouseUp],
          [Event.Mouse.LEAVE, handleMouseUp]
        ]);
      };
      addEventListeners(document, [
        [Event.Mouse.MOVE, handleMouseMove],
        [Event.Mouse.UP, handleMouseUp],
        [Event.Mouse.LEAVE, handleMouseUp]
      ]);
    };

    const handleTouchStart = event => {
      event.preventDefault();
      const isPaused = mediaRef.current?.paused;
      if (!isPaused) mediaRef.current?.pause();
      const handleTouchMove = e => {
        e.preventDefault();
        setPosition(e.changedTouches[0].clientX);
      };
      const handleTouchEnd = e => {
        e.preventDefault();
        const newTime = setTimeForPosition(e.changedTouches[0].clientX);
        if (newTime === duration) changeToState(MediaState.READY);
        // if content is paused before scrubbing begins, content stays paused
        // if content is not paused before scrubbing begins, content plays after scrubbing
        else if (!isPaused) mediaRef.current.play();
        removeEventListeners(document, [
          [Event.Touch.MOVE, handleTouchMove],
          [Event.Touch.END, handleTouchEnd],
          [Event.Touch.CANCEL, handleTouchEnd]
        ]);
      };
      addEventListeners(document, [
        [Event.Touch.MOVE, handleTouchMove],
        [Event.Touch.END, handleTouchEnd],
        [Event.Touch.CANCEL, handleTouchEnd]
      ]);
    };

    return (
      <ScrubberWrapper
        tabIndex={-1} // button not accessible for keyboards
        aria-hidden="true"
        ref={scrubberRef}
        id={`scrubber`}
        data-testid="scrubber"
        className="w-100 pt-xs pb-xxs pointer touch-none"
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
        onClick={e => e.stopPropagation()}
      >
        <div
          id={`scrubber-bar`}
          className={getClasses("relative brad-3", {
            "bg-gray-lighter":
              theme === Theme.LIGHT || theme === Theme.HUMAN_READ,
            "bg-gray-darker": theme === Theme.DARK
          })}
        >
          <ScrubberProgress
            className="flex items-center absolute h-100 brad-3 brad-tr-0 brad-br-0 pointer"
            style={{
              width: progress,
              background:
                theme === Theme.HUMAN_READ
                  ? "linear-gradient(180deg, #166DFC, 25.52%, #03AFCA 100%)"
                  : theme === Theme.DARK
                  ? "white"
                  : "$gray20"
            }}
          >
            <div
              id={`scrubber-dot`}
              className="brad-50 relative border-box select-none transition-transform duration-200"
              style={{
                left: progress,
                backgroundColor: theme === Theme.HUMAN_READ && "#166DFC"
              }}
            />
          </ScrubberProgress>
        </div>
      </ScrubberWrapper>
    );
  }
);

Scrubber.displayName = "Scrubber";

Scrubber.propTypes = {
  theme: PropTypes.string.isRequired,
  changeToState: PropTypes.func.isRequired,
  duration: PropTypes.number.isRequired,
  setCurrentTime: PropTypes.func,
  progress: PropTypes.number.isRequired,
  setProgress: PropTypes.func.isRequired
};

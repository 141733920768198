import React, { useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { useStickyVideoContext } from "../contexts/StickyVideoContext";
import { useVideoDataContext } from "../contexts/VideoDataContext";
import { useVideoAnalyticsContext } from "../contexts/VideoAnalyticsContext";
import { StickyPlayerEventLabel, VideoEvent } from "../../lib/analytics/utils";

export const StickyWrapper = ({ children }) => {
  const { uniqueId } = useVideoDataContext();
  const {
    isStickyEnabled,
    isOpenSticky,
    playingId,
    openId,
    updateStickyContext
  } = useStickyVideoContext();
  const { sendAnalyticsEvent } = useVideoAnalyticsContext();

  const wrapperRef = useRef();

  const observerCallback = useCallback(
    entries => {
      if (!isStickyEnabled) return;
      const [entry] = entries;
      // When we scroll past video, check if we should open it in the sticky player
      const shouldOpenVideo = shouldStickyVideoOpen(entry, playingId, uniqueId);
      if (shouldOpenVideo) {
        if (!isOpenSticky) {
          // only send analytics event if sticky player isn't open, because this observerCallback fires again after updating videoID
          sendAnalyticsEvent(VideoEvent.STICKY_PLAYER_OPEN, {
            label: StickyPlayerEventLabel.OPEN_SCROLL
          });
        }
        updateStickyContext({ openId: uniqueId });
      } else {
        // When we scroll back to a video, check if we should close the sticky player
        const shouldCloseVideo = shouldStickyVideoClose(
          entry,
          playingId,
          openId,
          uniqueId
        );
        if (shouldCloseVideo && isOpenSticky) {
          updateStickyContext({ openId: null });
          sendAnalyticsEvent(VideoEvent.STICKY_PLAYER_CLOSE, {
            label: StickyPlayerEventLabel.SCROLL_CLOSE
          });
        }
      }
    },
    [
      isOpenSticky,
      isStickyEnabled,
      openId,
      playingId,
      sendAnalyticsEvent,
      updateStickyContext,
      uniqueId
    ]
  );

  useEffect(() => {
    const localRef = wrapperRef?.current;
    if (!localRef || !isStickyEnabled) return;

    const intersectionObserver = new IntersectionObserver(observerCallback, {});

    intersectionObserver.observe(localRef);
    return () => {
      intersectionObserver.unobserve(localRef);
    };
  }, [
    wrapperRef,
    playingId,
    openId,
    isStickyEnabled,
    isOpenSticky,
    sendAnalyticsEvent,
    observerCallback
  ]);
  return <div ref={wrapperRef}>{children}</div>;
};

StickyWrapper.propTypes = {
  children: PropTypes.node
};

const shouldStickyVideoOpen = (entry, playingId, uniqueId) => {
  return !entry.isIntersecting && playingId === uniqueId;
};

const shouldStickyVideoClose = (entry, playingId, openId, uniqueId) => {
  return entry.isIntersecting && playingId === uniqueId && openId === uniqueId;
};

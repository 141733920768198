import { useCallback, useEffect, useState } from "react";
import { isFullVideoData } from "../../lib/utils";
import {
  checkIfVideoTypeLive,
  getAutoplayType,
  hasLiveEventEnded,
  isLiveEventUpcoming
} from "../utils";
import { AutoPlayType } from "../../globals";
import { isSubscriber } from "@washingtonpost/subs-sdk";
import { useVideoDataContext } from "../contexts/VideoDataContext";

export const AUTOPLAY_INIT_VALUE = {
  /** {@link AutoPlayType} */
  type: null,
  /** {@link AutoPlayStatus} */
  status: null,
  /** Is looping video? Includes types LOOP and LOOP_NO_CONTROLS  */
  isLooping: false,
  /** Is autoplaying video? Includes types AUTO, LOOP and LOOP_NO_CONTROLS */
  isAutoplay: false,
  /** Is autoplay due to referral? */
  isReferral: false,
  /** Is autoplay due to live preview? */
  isLivePreview: false,
  /** Is playthrough mode? */
  isPlaythrough: false,
  /** Has autoplay state been initialized? */
  isInitialized: false,
  /** Should override autoplay mute behavior? */
  overrideAutoplayMuteBehavior: false
};

/**
 * Determines autoplay settings for initial and subsequent videos
 * Provides function to update settings, esp. autoplay status and playthrough mode
 */
export const useAutoplay = (
  isLead,
  isMotionReduced,
  isLivePreviewEnabled,
  isAutoplayOverride,
  autoplayLive,
  forceNoLoop,
  isCarousel
) => {
  const { videoData } = useVideoDataContext();

  const [autoplayState, setAutoplayState] = useState(AUTOPLAY_INIT_VALUE);

  const updateAutoplayState = useCallback(
    updatedState => {
      if (isMotionReduced) return;
      setAutoplayState(previousState => ({
        ...previousState,
        ...updatedState
      }));
    },
    [isMotionReduced]
  );

  useEffect(() => {
    if (
      !videoData ||
      !isFullVideoData(videoData) ||
      // Wait until reduced motion preference is set and ready to use!
      isMotionReduced === undefined
    ) {
      return;
    }

    if (isMotionReduced || isLiveEventUpcoming(videoData) || isCarousel) {
      setAutoplayState({
        ...AUTOPLAY_INIT_VALUE,
        isInitialized: true
      });
      return;
    }

    let type = getAutoplayType(videoData, forceNoLoop);

    const isLooping = [
      AutoPlayType.LOOP,
      AutoPlayType.LOOP_NO_CONTROLS
    ].includes(type);

    let isReferral = false;

    if (!isLooping && isReferralAutoplay(isLead)) {
      isReferral = true;
      type = AutoPlayType.AUTO;
    }

    let isLivePreview = false;

    if (
      isLivePreviewEnabled &&
      !hasLiveEventEnded(videoData) &&
      !isLiveEventUpcoming(videoData) &&
      checkIfVideoTypeLive(videoData)
    ) {
      isLivePreview = true;
      type = AutoPlayType.AUTO;
    }

    let overrideAutoplayMuteBehavior = false;

    if (checkIfVideoTypeLive(videoData) && autoplayLive) {
      type = AutoPlayType.AUTO;
      overrideAutoplayMuteBehavior = true;
    }

    const isAutoplay =
      isAutoplayOverride ||
      (checkIfVideoTypeLive(videoData) && autoplayLive) ||
      !!type;

    updateAutoplayState({
      type,
      isLooping,
      isAutoplay,
      isReferral,
      isLivePreview,
      overrideAutoplayMuteBehavior,
      isInitialized: true
    });
  }, [
    isMotionReduced,
    videoData,
    isLead,
    isLivePreviewEnabled,
    updateAutoplayState,
    isAutoplayOverride,
    forceNoLoop,
    autoplayLive
  ]);

  return { autoplayState, updateAutoplayState };
};

const socialReferrers = [
  "facebook.com",
  "/t.co/",
  "reddit.com",
  "linkedin.com",
  "youtube.com",
  "digg.com"
];

const searchReferrers = [
  "www.google.*",
  "news.google.*",
  "com.google",
  "search.yahoo.com",
  "www.bing.com",
  "duckduckgo.com"
];

/**
 * Determines whether should autoplay due to referral from social or search.
 * Non-lead videos and subscribers are excluded.
 * @param {boolean} isLead
 * @returns {boolean}
 */
function isReferralAutoplay(isLead) {
  if (!isLead || isSubscriber()) return false;

  const queryParams = new URLSearchParams(window.location.search);

  let referrer;

  if (queryParams.has("wpvReferrer")) {
    referrer = queryParams.get("wpvReferrer");
  } else if (window.document.referrer) {
    referrer = new URL(window.document.referrer).hostname;
  } else {
    return false;
  }

  return [...socialReferrers, ...searchReferrers].some(x =>
    new RegExp(x).test(referrer)
  );
}

import { useState, useEffect } from "react";

export const PlayerSize = {
  MINI: "mini",
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large"
};

export const PlayerBreakpoint = {
  MINI: 300,
  SMALL: 400,
  MEDIUM: 700
};

export function usePlayerSize(playerWrapperRef) {
  const [playerSize, setPlayerSize] = useState();

  useEffect(() => {
    const playerWrapperElement = playerWrapperRef?.current;
    if (!playerWrapperElement) return;

    const handleResizePlayer = () => {
      const playerWidth = playerWrapperElement.offsetWidth;
      let playerSize;
      if (playerWidth <= PlayerBreakpoint.MINI) {
        playerSize = PlayerSize.MINI;
      } else if (playerWidth <= PlayerBreakpoint.SMALL) {
        playerSize = PlayerSize.SMALL;
      } else if (playerWidth <= PlayerBreakpoint.MEDIUM) {
        playerSize = PlayerSize.MEDIUM;
      } else {
        playerSize = PlayerSize.LARGE;
      }
      setPlayerSize(playerSize);
    };
    handleResizePlayer();

    const resizer = new ResizeObserver(handleResizePlayer);
    resizer.observe(playerWrapperElement);

    return () => resizer.disconnect();
  }, [playerWrapperRef]);

  return playerSize;
}

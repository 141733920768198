import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";

import { AdState, PromoState } from "../../../globals";
import { useAdsScripts } from "./useAdsScripts";
import {
  autoplayStateAllowsAds,
  getAdSettings,
  getNoAdsParam,
  getShouldShowAds
} from "../utils/helpers";
import { isFullVideoData } from "../../../lib/utils";
import { getInitMuteValue } from "../../utils";
import { isPremium } from "../../../preroll/utils/preroll-utils";
import { useVideoDataContext } from "../../contexts/VideoDataContext";
import { useVideoElementRefContext } from "../../contexts/VideoElementRefContext";
import { useVideoAnalyticsContext } from "../../contexts/VideoAnalyticsContext";
import { useAutoplayContext } from "../../contexts/AutoplayContext";
import { VideoEvent } from "../../../lib/analytics/utils";
import { useAdsElementsRefContext } from "../../contexts/AdsElementsRefContext";

export const useAdState = options => {
  const videoElementRef = useVideoElementRefContext();
  const { videoData = {} } = useVideoDataContext();
  const { autoplayState } = useAutoplayContext();
  const { sendAnalyticsEvent } = useVideoAnalyticsContext();

  const {
    config = {},
    setPromoState = () => {},
    spectrumCallback = () => {},
    promoState = "",
    isVertical = false,
    isLive = false,
    transitionToVideo = () => {},
    isPreroll = false
  } = options;

  const [adState, setAdState] = useState(AdState.INIT);
  const [shouldShowAds, setShouldShowAds] = useState(false);
  const [hasLoadedFirstVideo, setHasLoadedFirstVideo] = useState(false);
  const [isAdsInitialized, setAdsInitialized] = useState(false);
  const { adWrapperRef } = useAdsElementsRefContext();

  const adSettings = useRef(
    getAdSettings(
      config,
      videoData,
      videoElementRef,
      isVertical,
      getInitMuteValue(),
      isLive,
      isPreroll,
      autoplayState.isPlaythrough
    )
  );

  useEffect(() => {
    // Update adSettings when video changes so that we fetch playthrough ad based on next video
    adSettings.current = getAdSettings(
      config,
      videoData,
      videoElementRef,
      isVertical,
      getInitMuteValue(),
      isLive,
      isPreroll,
      autoplayState.isPlaythrough
    );

    if (isPreroll) {
      setShouldShowAds(true);
      initializeAds();
      return;
    }

    if (!autoplayState.isInitialized) return;

    // If we are fetching video data by uuid, we'll need to update shouldShowAds once we've fetched our video.
    if (!hasLoadedFirstVideo) {
      if (
        getShouldShowAds(config, videoData?._id) &&
        !getNoAdsParam() &&
        !isPremium()
      ) {
        setShouldShowAds(true);
        initializeAds();
      } else {
        setAdsInitialized(true);
      }

      if (!autoplayStateAllowsAds(autoplayState)) {
        setAdsInitialized(true);
      }
    }

    // We don't want to recalculate shouldShowAds for subsequent videos in playthrough (causes iris bugs)
    if (!hasLoadedFirstVideo && videoData && isFullVideoData(videoData)) {
      setHasLoadedFirstVideo(true);
    }
  }, [videoData?._id, autoplayState]);

  // Resize ad to match video element size
  const resizeAd = () => {
    const irisPlayer = adWrapperRef?.current?.irisPlayer;
    if (!irisPlayer && !videoElementRef.current) return;
    irisPlayer?.resize(
      videoElementRef.current.clientWidth,
      videoElementRef.current.clientHeight
    );
  };

  // Respond to changes in video element size due to window resizing
  useEffect(() => {
    const resizer = new ResizeObserver(resizeAd);
    resizer.observe(videoElementRef?.current);
    return () => resizer?.disconnect();
  }, [videoElementRef?.current]);

  const loadAd = useCallback(async () => {
    const irisPlayer = adWrapperRef.current?.irisPlayer;

    if (!irisPlayer || adState === AdState.ERROR) {
      // Once iris enters an error state it won't accept additional calls to play()
      setAdState(AdState.ERROR);
      sendAnalyticsEvent(VideoEvent.START);
      transitionToVideo();
    } else {
      setAdState(AdState.LOADING);
      // This play function causes iris to call our build ad tag url function, and load the ad onto the page
      await irisPlayer.play();
    }
  }, [adState, adWrapperRef, sendAnalyticsEvent, transitionToVideo]);

  const { isIrisReady, initializeAds } = useAdsScripts(
    adSettings,
    setShouldShowAds,
    setAdsInitialized,
    setPromoState,
    config.debug,
    spectrumCallback
  );

  useEffect(() => {
    // If its preroll, mute the ad container and play when iris is ready.
    async function playPrerollAd() {
      if (isIrisReady && isPreroll) {
        await adWrapperRef.current?.irisPlayer.autoPlay();
      }
    }

    playPrerollAd();
  }, [adWrapperRef, isIrisReady, isPreroll, videoElementRef]);

  // If we're showing ads, we need the user to wait for the iris ready event before we load the ad.
  useMemo(() => {
    if (shouldShowAds) {
      if (!isIrisReady && promoState === PromoState.READY) {
        setPromoState(PromoState.LOADING);
      }
      if (isIrisReady && promoState === PromoState.LOADING) {
        // Only set promo as ready once we've determined video won't autoplay
        if (
          autoplayState.isInitialized &&
          (!autoplayState.isAutoplay || autoplayState.isLivePreview)
        ) {
          setPromoState(PromoState.READY);
        }
      }
    }
  }, [isIrisReady, shouldShowAds, promoState, autoplayState, setPromoState]);

  const transitionToAd = useCallback(() => {
    if (adState !== AdState.ERROR) {
      setAdState(AdState.REQUESTED);
    }
    setPromoState(PromoState.BUFFER);
    adWrapperRef.current?.irisPlayer?.initializeAdDisplayContainer();

    // 10ms delay to ensure iris is ready to load ad
    // When switching between ads, we need to wait for this event -
    // {@link https://github.com/WashPost/zeus-lib/blob/7745160259a962c9476dd7036660840849d959b7/packages/zeus-iris/src/plugins/imaPlayer/imaPlayer.js#L244}

    setTimeout(() => {
      loadAd();
    }, [10]);
  }, [adState, adWrapperRef, loadAd, setPromoState]);

  return {
    adState,
    setAdState,
    adSettings,
    shouldShowAds,
    transitionToAd,
    isAdsInitialized,
    isIrisReady
  };
};

useAdState.propTypes = {
  video: PropTypes.object,
  config: PropTypes.object,
  setPromoState: PropTypes.func,
  transitionToVideo: PropTypes.func,
  autoplayState: PropTypes.object,
  videoID: PropTypes.string,
  isPreroll: PropTypes.bool
};

import React from "react";
import PropTypes from "prop-types";
import { Cc } from "@washingtonpost/wpds-assets";
import { Icon, Button } from "@washingtonpost/wpds-ui-kit";
import { styled } from "@washingtonpost/wpds-ui-kit";

import { VideoEvents } from "../../globals";
import SubtitlesIcon from "./SubtitlesIcon";
import { useVideoElementRefContext } from "../../orca/contexts/VideoElementRefContext";
import "./index.css";

/**
 * Component which enables/disables the subtitles for the video.
 * @returns A button containing the closed caption icon.
 */

const PlaceholderElement = styled("div", {
  width: "40px",
  height: "40px"
});

export const SubtitlesButton = ({
  setShowSubtitles,
  playerState,
  hasSubtitles
}) => {
  const videoElementRef = useVideoElementRefContext();

  if (!hasSubtitles) {
    return <PlaceholderElement />;
  }

  return (
    <Button
      aria-label={
        playerState.showSubtitles ? "Hide subtitles" : "Show subtitles"
      }
      data-testid="subtitles-button"
      icon="center"
      className={"focus-visible-highlight"}
      variant="primary"
      css={{
        border: "none",
        paddingLeft: 0,
        background: "none",
        "&:hover": {
          background: "none"
        }
      }}
      onClick={e => {
        e.stopPropagation();
        const fullscreenEvent = new Event(VideoEvents.CAPTIONS);
        videoElementRef.current.dispatchEvent(fullscreenEvent);
        setShowSubtitles(!playerState.showSubtitles);
      }}
    >
      {playerState.showSubtitles ? (
        <SubtitlesIcon />
      ) : (
        <Icon fill="white" size="150" label="subtitles">
          <Cc />
        </Icon>
      )}
    </Button>
  );
};

SubtitlesButton.displayName = "SubtitlesButton";

SubtitlesButton.propTypes = {
  /* callback function to toggle subtitles state */
  setShowSubtitles: PropTypes.func,
  /* object tracking current video info*/
  playerState: PropTypes.object,
  /** Boolean to determine if the video has subtitles */
  hasSubtitles: PropTypes.bool
};

export const subscribeEvent = (element, eventName, listener) => {
  element.addEventListener(eventName, listener);
};

export const unsubscribeEvent = (element, eventName, listener) => {
  if (!element) return;
  element.removeEventListener(eventName, listener);
};

export const dispatchNewEvent = (element, eventName, data) => {
  const event = new CustomEvent(eventName, { detail: data });
  element.dispatchEvent(event);
};

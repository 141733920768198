import { useEffect } from "react";

import { VideoEvents } from "../../globals";
import { subscribeEvent, unsubscribeEvent } from "../utils/elementEvents";
import { SPLUNK_EVENT_NAMES, SPLUNK_EVENT_TYPES } from "./globals";
import { sendVideoSplunkEvent } from "../utils/sendVideoSplunkEvent";
import { useVideoElementRefContext } from "../contexts/VideoElementRefContext";

// Sends Splunk events for when video elements trigger an `error` event.

export const useSendVideoErrors = (splunkParams, config) => {
  const videoElementRef = useVideoElementRefContext();

  const handleOnError = event => {
    const errorText = event.target.error.message;
    const errorCode = event.target.error.code;

    sendVideoSplunkEvent(
      videoElementRef,
      SPLUNK_EVENT_TYPES.ERROR,
      SPLUNK_EVENT_NAMES.ERROR,
      { errorText, errorCode, ...splunkParams },
      config
    );
  };

  useEffect(() => {
    const videoElement = videoElementRef?.current;
    if (!videoElement) return;

    subscribeEvent(videoElement, VideoEvents.ERROR, handleOnError);

    return () => {
      unsubscribeEvent(videoElement, VideoEvents.ERROR, handleOnError);
    };
  }, [videoElementRef]);
};

import { Play, Pause, Loading, Refresh } from "@washingtonpost/wpds-assets";
class State {
  constructor(name, label, icon) {
    this.name = name;
    this.label = label;
    this.icon = icon;
  }
}

export const MediaState = {
  READY: new State("READY", "Listen", Play),
  LOADING: new State("LOADING", "Listen", Loading),
  LOADED: new State("LOADED", "Listen", Loading),
  BUFFER: new State("BUFFER", "Listen", Loading),
  PLAYING: new State("PLAYING", "Pause", Pause),
  PAUSED: new State("PAUSED", "Play", Play),
  ERROR: new State("ERROR", undefined, Refresh)
};

import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { imageResizer } from "../../lib/utils";
import { ErrorHandler } from "./ErrorHandler";
import { styled } from "@washingtonpost/wpds-ui-kit";
import { PromoState } from "../../globals";
import { useVideoDataContext } from "../contexts/VideoDataContext";
import { useStickyVideoContext } from "../contexts/StickyVideoContext";

const StyledFigure = styled("figure", {
  margin: 0
});

const FigureDiv = styled("div", {});

export const useCalcCss = ({
  isOpenSticky,
  aspectRatio,
  aspectMatch,
  bgImageUrl,
  blur,
  color,
  promoState,
  playerState
}) => {
  const calcCss = useCallback(() => {
    const commonStyles = {
      position: "relative",
      backgroundPosition: "center",
      backgroundSize: aspectMatch ? "cover" : "contain",
      paddingBottom: `calc(${aspectRatio}*100%)`
    };

    const stickyStyles = {
      ...commonStyles,
      backgroundColor: "unset",
      backgroundImage: "none"
    };

    const shouldShowBackgroundImage =
      bgImageUrl &&
      (promoState !== PromoState.HIDDEN || !playerState.isStarted);

    const nonStickyStyles = {
      ...commonStyles,
      backgroundRepeat: "no-repeat",
      backgroundColor: color || "black",
      backgroundImage: shouldShowBackgroundImage
        ? `url(${bgImageUrl})`
        : "none",
      ...(blur
        ? {
            filter: "blur(5px)",
            transition: "filter .1s"
          }
        : {})
    };

    return isOpenSticky ? stickyStyles : nonStickyStyles;
  }, [
    isOpenSticky,
    aspectRatio,
    aspectMatch,
    bgImageUrl,
    blur,
    color,
    promoState,
    playerState
  ]);

  return calcCss;
};

export const useBackgroundImage = ({
  placeholderImage,
  resize,
  videoData,
  originalVideo
}) => {
  const getImageWidth = () => {
    // NOTE: May require sync up with the spectrum code
    // see https://github.com/WashPost/spectrum/blob/main/components/document-elements/shared/lede-art-preload/index.jsx#L54
    return videoData?.additional_properties?.vertical ? 480 : 960;
  };

  const isFirstVideo = (videoData, originalVideo) => {
    return !originalVideo?._id || videoData?._id === originalVideo?._id;
  };

  return useCallback(() => {
    const imageWidth = getImageWidth(videoData);

    if (placeholderImage && isFirstVideo(videoData, originalVideo)) {
      return resize
        ? imageResizer(placeholderImage, imageWidth)
        : placeholderImage;
    }

    const videoImage = videoData?.bandito?.image || videoData?.promo_image?.url;
    return videoImage ? imageResizer(videoImage, imageWidth) : null;
  }, [placeholderImage, resize, videoData, originalVideo]);
};

export const Wrapper = ({
  aspectRatio,
  aspectMatch,
  placeholder,
  className,
  caption,
  children,
  promoState,
  playerState
}) => {
  const { originalVideo, videoData, videoApiDataFailed } =
    useVideoDataContext();
  const { isOpenSticky } = useStickyVideoContext();

  const { image: placeholderImage, resize, blur, color } = placeholder || {};

  const bgImageUrl = useBackgroundImage({
    placeholderImage,
    resize,
    videoData,
    originalVideo
  })();

  const calcCss = useCalcCss({
    isOpenSticky,
    aspectRatio,
    aspectMatch,
    bgImageUrl,
    blur,
    color,
    promoState,
    playerState
  });

  return (
    <StyledFigure className={className}>
      <FigureDiv className="w-100" data-testid="orca-wrapper" css={calcCss()}>
        {videoApiDataFailed && <ErrorHandler />}
        {videoData && children}
      </FigureDiv>
      {caption && <figcaption>{caption}</figcaption>}
    </StyledFigure>
  );
};

Wrapper.propTypes = {
  /** Aspect ratio float, e.g. 0.5625 for 16:9 */
  aspectRatio: PropTypes.number,
  /** Player & stream aspect ratios match */
  aspectMatch: PropTypes.bool,
  /** Placeholder object */
  placeholder: PropTypes.object,
  /** React prop for class */
  className: PropTypes.string,
  /** Video caption */
  caption: PropTypes.node,
  children: PropTypes.node.isRequired,
  videoApiDataFailed: PropTypes.bool,
  videoID: PropTypes.string,
  isStickyEnabled: PropTypes.bool,
  isOpenSticky: PropTypes.bool,
  promoState: PropTypes.string,
  playerState: PropTypes.object
};

import { DEFAULT_VALUE_ANALYTICS, MediaType } from "./globals";
import { joinArray } from "./utils";

/**
 * sendAnalytics function is used to push to the dataLayer for video/audio analytics events.
 * @param {analytics} analytics - analytics data object.
 * @param {analytics} debug - boolean used to log analytics data.
 */
const sendAnalytics = (analytics, debug, mediaType) => {
  const {
    label,
    event,
    avName,
    category,
    progressThreshold,
    avCMS,
    avLength,
    avArcID,
    avSection,
    avSource,
    avType,
    avTags,
    avProducer,
    avProducerID,
    avHost,
    avHostID,
    avContributors,
    avContributorID,
    avLiveProgress,
    avPublishDate,
    avPromoType,
    avTestGroup,
    avPlayerType,
    adAttribute,
    avAdMeta,
    avSystem,
    avPlayer,
    hosts,
    contributors,
    producers,
    publishDate,
    keywords,
    avKeywords,
    soundStatus
  } = analytics;

  window.dataLayer.push({
    label: label,
    event: event,
    action: event,
    category: category,
    progressThreshold: progressThreshold || DEFAULT_VALUE_ANALYTICS,
    avCMS: avCMS || DEFAULT_VALUE_ANALYTICS,
    avSystem: avSystem || DEFAULT_VALUE_ANALYTICS,
    avName: avName || DEFAULT_VALUE_ANALYTICS,
    avLength: avLength || DEFAULT_VALUE_ANALYTICS,
    avArcID: avArcID || DEFAULT_VALUE_ANALYTICS,
    avSection: avSection || DEFAULT_VALUE_ANALYTICS,
    avSource: avSource || DEFAULT_VALUE_ANALYTICS,
    avType: avType || DEFAULT_VALUE_ANALYTICS,
    avTags: avTags,
    avKeywords: avKeywords || joinArray(keywords, ";", DEFAULT_VALUE_ANALYTICS),
    avHost: avHost || joinArray(hosts, ";", DEFAULT_VALUE_ANALYTICS, "name"),
    avProducer:
      avProducer || joinArray(producers, ";", DEFAULT_VALUE_ANALYTICS, "name"),
    avContributors:
      avContributors ||
      joinArray(contributors, ";", DEFAULT_VALUE_ANALYTICS, "name"),
    avPromoType: avPromoType || DEFAULT_VALUE_ANALYTICS,
    avPublishDate: avPublishDate
      ? avPublishDate
      : publishDate
      ? new Date(publishDate).toISOString().substring(0, 10)
      : DEFAULT_VALUE_ANALYTICS,
    avTestGroup: avTestGroup || DEFAULT_VALUE_ANALYTICS,
    avPlayerType: avPlayerType || avPlayer,
    avPlayer: avPlayerType || avPlayer,
    adAttribute: adAttribute || "undefined",
    avAdMeta: avAdMeta || DEFAULT_VALUE_ANALYTICS,
    avLiveProgress: avLiveProgress || DEFAULT_VALUE_ANALYTICS,
    ...(mediaType === MediaType.AUDIO && {
      avProducerID:
        avProducerID ||
        joinArray(producers, ";", DEFAULT_VALUE_ANALYTICS, "id"),
      avContributorID:
        avContributorID ||
        joinArray(contributors, ";", DEFAULT_VALUE_ANALYTICS, "id"),
      avHostID: avHostID || joinArray(hosts, ";", DEFAULT_VALUE_ANALYTICS, "id")
    }),
    ...(soundStatus && { soundStatus })
  });
  if (debug) {
    console.log(window.dataLayer);
  }
};

export default sendAnalytics;

import { DEFAULT_IMAGE, RESIZER_PROXY, BASE_VIDEO_API_URL } from "../globals";

export function imageResizer(url = DEFAULT_IMAGE, width = 960, height = null) {
  let resizerUrl = `${RESIZER_PROXY}?src=${encodeURIComponent(url)}&w=${width}`;

  if (height) {
    resizerUrl += `&h=${height}`;
  }

  return resizerUrl;
}

export function getDeviceType() {
  const userAgent =
    window.navigator && navigator.userAgent ? navigator.userAgent : "";

  if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
    return "ios";
  } else if (userAgent.match(/Android/i)) {
    return "android";
  } else if (userAgent.match(/Macintosh/i)) {
    return "mac";
  } else if (userAgent.match(/Windows/i)) {
    return "windows";
  }
}

const getStreamsByTypeAndBitrate = (streams, stream_type, maxBitrate) => {
  return streams
    .filter(
      stream =>
        stream["bitrate"] <= maxBitrate && stream["stream_type"] === stream_type
    )
    .reduce((prev, current) => {
      return prev?.bitrate > current?.bitrate ? prev : current;
    }, undefined);
};

/**
 * Returns the maximum bitrate for a given device type
 * @param {array} streams of possile streams
 * @param {string} deviceType the device type
 * @param {boolean} isLoopingVideo
 * @param {boolean} forceHD
 * @returns  the stream that matches the device type
 */
export const getVideoStream = (
  streams,
  deviceType,
  isLoopingVideo = false,
  forceHD = false
) => {
  // use mp4 stream for looping videos
  // looping videos are usually short so by the time HLS kicks in to adjust to user bandwidth, the video is over
  // hls.js does not yet flush backbuffer of lower quality segments for looped video (https://github.com/video-dev/hls.js/issues/4553)
  if (isLoopingVideo) {
    const maxMp4Bitrate = forceHD
      ? 5400
      : deviceType === "ios" || deviceType === "android"
      ? 300
      : 2000;

    const mp4Stream = getStreamsByTypeAndBitrate(streams, "mp4", maxMp4Bitrate);
    // if found, return mp4 stream that's fits the max bitrate
    if (mp4Stream) return mp4Stream;
  }

  // let hls handle level selection based on device bandwidth
  // capLevelToPlayerSize is set to true in useHls and should prevent mobile devices from receiving bitrates that are too high
  return streams
    .filter(
      stream =>
        (stream.stream_type === "ts") &
        !stream.url?.endsWith("hlsv4_master.m3u8")
    )
    .reduce((prev, current) => {
      return prev?.bitrate > current?.bitrate ? prev : current;
    }, undefined);
};

export const getVideoStreamForVerticalVideoThumbnail = streams => {
  return getStreamsByTypeAndBitrate(streams, "mp4", 600) || streams?.[0];
};

/**
 * Add leading digits to a number until it has at least 2 digits
 * @param {number} num the number to pad
 * @returns {string} padded number (with leading zeroes)
 */
function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

/**
 * Given a duration in milliseconds, convert it to hh:mm:ss format
 * @param {number} milliseconds time in milliseconds
 * @returns {object} contains 2 properties:
 * visible: the milliseconds formatted as hh:mm:ss (or mm:ss if hours is 0)
 * srOnly: the milliseconds formatted as "X hours, Y minutes and Z seconds"
 * (or Y minutes and Z seconds, if hours is 0) for screen readers
 */
export function formatHoursMinutesSeconds(milliseconds) {
  if (!milliseconds) {
    return { visible: "", srOnly: "" };
  }

  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  seconds %= 60;
  minutes %= 60;

  const visibleFormat = hours
    ? `${hours}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`
    : `${minutes}:${padTo2Digits(seconds)}`;

  const screenReaderFormat = hours
    ? `${hours} hours, ${padTo2Digits(minutes)} minutes and ${padTo2Digits(
        seconds
      )} seconds`
    : `${minutes} minutes and ${padTo2Digits(seconds)} seconds`;

  return { visible: visibleFormat, srOnly: screenReaderFormat };
}

export function isFullVideoData(video) {
  return video && video.streams && video.streams[0]?.url;
}

export function hasForceHDTag(video) {
  return video?.taxonomy?.tags?.some(x => x.text === "force-hd");
}

export function getVideoApiUrl(isSandbox) {
  return isSandbox ? BASE_VIDEO_API_URL.SANDBOX : BASE_VIDEO_API_URL.PROD;
}

export function isElementInViewport(el, ratio = 1.0, lookLeftAndRight = false) {
  if (!(el instanceof HTMLElement)) return null;

  const rect = el.getBoundingClientRect();
  return (
    rect.top + el.offsetHeight * ratio >= 0 &&
    rect.bottom - el.offsetHeight * ratio <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    (lookLeftAndRight ? rect.left >= 0 : true) &&
    (lookLeftAndRight
      ? rect.right <=
        (window.innerWidth || document.documentElement.clientWidth)
      : true)
  );
}

import { Duration } from "luxon";
import { BROSWER } from "./globals";
import { getCookie } from "./cookie-util";

/**
 * @param {Array<string>} array
 * @param {String} separtor - The string each item is separated by.
 * @param {String} defaultValue - The default null value.
 * @param {String} [property] - The optional property name its targeting within the array.
 * @returns {String} Returns either the defaultValue or a string for analytics.
 */
export const joinArray = (array, separator, defaultValue, property) => {
  if (!array || array.length === 0) {
    return defaultValue;
  }
  const stringArray = property ? array.map(x => x[property]) : array;
  return stringArray.join(separator);
};

/**
 * Wraps specified function with button keyboard logic
 * i.e. only " " (Space) or "Enter" keydowns will trigger the function.
 *
 * @param {Object} event The keyboard event
 * @param {Array<String>} keys The keys that the function is triggered
 * @param {Function} callback The function to be wrapped
 * @returns {Object} The wrapped function
 */
export const keyDownButtonWrapper = (event, keys, callback) => {
  if (keys.some(key => event.key === key)) {
    event.preventDefault();
    callback();
  }
};

export function getDuration(seconds) {
  return Duration.fromObject({ hours: 0, minutes: 0, seconds }).normalize();
}

export function miniDuration(seconds) {
  const duration = getDuration(seconds);
  return duration.toFormat(
    duration.hours ? duration.toFormat("h:mm:ss") : duration.toFormat("m:ss")
  );
}

export function simpleDuration(seconds) {
  const duration = getDuration(seconds);
  return duration.toFormat(
    duration.hours ? "h 'hr' m 'min'" : duration.minutes ? "m 'min'" : "s 'sec'"
  );
}

export function complexDuration(leftSeconds, rightSeconds) {
  const left = getDuration(leftSeconds);
  const right = getDuration(rightSeconds);
  return right.hours
    ? `${left.toFormat("h:mm:ss")} / ${right.toFormat("h:mm:ss")}`
    : `${left.toFormat("m:ss")} / ${right.toFormat("m:ss")}`;
}

export function complexDurationNoFraction(seconds) {
  const duration = getDuration(seconds);
  return `${duration.toFormat("m:ss")}`;
}
// Convert to ISO 8601 for SEO Schemas. https://en.wikipedia.org/wiki/ISO_8601#Durations
export function getDurationISO8601(ms) {
  const durationInSeconds = ms / 1000;

  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = Math.floor(durationInSeconds % 60);

  const hourDisplay = hours ? `${hours}H` : "";
  const minuteDisplay = `${minutes}M`;
  const secondDisplay = `${seconds}S`;

  return `PT${hourDisplay}${minuteDisplay}${secondDisplay}`;
}

export const generateGradient = ({ topPercent, bottomPercent }) => {
  const topGradient = topPercent
    ? `linear-gradient(to bottom, rgba(0, 0, 0, 0.75) 3%, rgba(0, 0, 0, 0) ${topPercent}%)`
    : null;

  const bottomGradient = bottomPercent
    ? `linear-gradient(to top, rgba(0, 0, 0, 0.75) 3%, rgba(0, 0, 0, 0) ${bottomPercent}%)`
    : null;

  return [topGradient, bottomGradient].filter(Boolean).join();
};

/**
 * @description Get existing script from DOM matching source URL
 * @param {string} source - Source URL
 * @returns {Object}
 */
export function getScript(source) {
  return window.document.querySelector(`script[src="${source}"]`);
}

/**
 * @description Listen to existing script in DOM
 * @param {string} script - Script element
 * @param {Function} onLoad - Callback run on script load
 * @param {Function} onError - Callback run on script error
 * @returns {void}
 */
export function listenScriptOnce(script, onLoad, onError) {
  script.addEventListener("load", onLoad, { once: true });
  script.addEventListener("error", onError, { once: true });
}

/**
 * @description Load new script into DOM with source URL
 * @param {string} source - Source URL
 * @param {Function} onLoad - Callback run on script load
 * @param {Function} onError - Callback run on script error
 * @returns {void}
 */
export function loadScript(source, onLoad, onError) {
  if (typeof document === "undefined") return;
  const script = document.createElement("script");
  script.src = source;
  script.async = true;
  script.onload = () => onLoad?.();
  script.onerror = () => onError?.(`Failed to load script: ${source}`);
  document.body.appendChild(script);
}

export const getBrowserType = () => {
  const userAgent = window?.navigator?.userAgent || "";

  const browserMap = {
    [BROSWER.OPERA]:
      userAgent.includes(BROSWER.OPERA) || userAgent.includes("OPR"),
    [BROSWER.EDGE]: userAgent.includes("Edg"),
    [BROSWER.CHROME]: userAgent.includes(BROSWER.CHROME),
    [BROSWER.SAFARI]: userAgent.includes(BROSWER.SAFARI),
    [BROSWER.FIREFOX]: userAgent.includes(BROSWER.FIREFOX)
  };
  return Object.keys(browserMap).find(key => browserMap[key]) || "";
};

export const getDeviceTypeAkamai = () => {
  // we trust akamai supplied devicetype if it exists
  const wapoDeviceTypeCookie = getCookie("wp_devicetype");
  if (wapoDeviceTypeCookie) {
    return wapoDeviceTypeCookie === "2"
      ? "tablet"
      : wapoDeviceTypeCookie === "1"
      ? "mobile"
      : "desktop";
  }
  // otherwise we default to trusting navigator.userAgent
  const userAgent = getUserAgent();
  const isIOS = /ipod|iphone|ipad/i.test(userAgent);
  const isTablet = /tablet|ipad/i.test(userAgent);
  // Check for iOS included here to accomodate iOS WKWebViews with custom app names, which do not have "Mobile" in their UA strings
  const isMobile = !isTablet && (isIOS || /[^-]mobi/i.test(userAgent));
  if (isTablet) return "tablet";
  if (isMobile) return "mobile";

  return "desktop";
};

export const getUserAgent = () => {
  if (typeof window === "undefined") return "";
  return window?.navigator?.userAgent ? navigator.userAgent : "";
};

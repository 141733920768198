import { DEFAULT_IMAGE, RESIZER_PROXY } from "../video/globals";

export function bound(value, min, max) {
  return Math.max(min, Math.min(max, value));
}

export function addEventListeners(element, listeners) {
  listeners.forEach(([event, callback]) => {
    element.addEventListener(event, callback);
  });
}

export function removeEventListeners(element, listeners) {
  listeners.forEach(([event, callback]) => {
    element.removeEventListener(event, callback);
  });
}

export function imageResizer(url = DEFAULT_IMAGE, width = 960, height = null) {
  let resizerUrl = url
    ? `${RESIZER_PROXY}?src=${encodeURIComponent(url)}&w=${width}`
    : null;

  if (resizerUrl && height) {
    resizerUrl += `&h=${height}`;
  }

  return resizerUrl;
}

/**
 * Retrieve the contextual keyvalues that exists in both window.wpAdFusion.contextualTargeting.adcall
 * @example
 * ```js
 *      // window.wpAdFusion.contextualTargeting.adcall = {
 *           key: "bs",
 *           values: ["foo", "bar"],
 *         }
 *     }
 *    getWpAdFusionKeyValues() //result { bs: ["foo", "bar"] }
 * ```
 * @returns {object} - The contextual keyvalues
 */
export const getWpAdFusionKeyValues = () => {
  const contextualKv = window?.wpAdFusion?.contextualTargeting?.adcall || [];
  return contextualKv.reduce((acc, kv) => {
    if (["bs"].includes(kv.key)) {
      acc[kv.key] = kv.values;
    }
    return acc;
  }, {});
};

/**
 * @description Append the contextual keyvalues to the given URL
 * @param {string} url - URL to append the keyvalues to
 * @returns {string} - The URL with the keyvalues appended
 * @example
 * ```js
 *  // window.wpAdFusion.contextualTargeting.adcall = {
 *           key: "bs",
 *           values: ["foo", "bar"],
 *         }
 *     }
 *   appendParamsToUrl("https://example.com") // result "https://example.com?bs=foo&ca=bar"
 * ```
 * @see getWpAdFusionKeyValues
 */
export const appendParamsToUrl = url => {
  const params = getWpAdFusionKeyValues();
  const newUrl = new URL(url);
  Object.entries(params).forEach(([key, value]) => {
    newUrl.searchParams.append(key, value);
  });
  return newUrl.toString();
};

import React from "react";
import PropTypes from "prop-types";

import { useVideoDataContext } from "../../contexts/VideoDataContext";
import { BasicWrapper } from "./index.stitches";
import { generateGradient } from "../../../../common/utils";
import { useStickyVideoContext } from "../../contexts/StickyVideoContext";
import { useAdControlsContext } from "../contexts/AdControlsContext";
import { useAdsElementsRefContext } from "../../contexts/AdsElementsRefContext";

export const AdContainerOuterWrapper = ({
  advertiserUrl,
  children,
  togglePlay
}) => {
  const { videoId } = useVideoDataContext();
  const { showControls, visitAdvertiser } = useAdControlsContext();
  const { isOpenSticky } = useStickyVideoContext();
  const { adContainerRef } = useAdsElementsRefContext();

  return (
    <BasicWrapper
      position="outerWrapper"
      id={`ad-container-${videoId}`}
      ref={adContainerRef}
    >
      {showControls && (
        <BasicWrapper
          position="innerWrapper"
          onClick={advertiserUrl ? visitAdvertiser : togglePlay}
          css={{
            overflow: "hidden",
            ...(isOpenSticky && {
              justifyContent: "space-between",
              background: generateGradient({
                topPercent: 35,
                bottomPercent: 35
              })
            })
          }}
        >
          {children}
        </BasicWrapper>
      )}
    </BasicWrapper>
  );
};

AdContainerOuterWrapper.propTypes = {
  children: PropTypes.node,
  togglePlay: PropTypes.func,
  advertiserUrl: PropTypes.string
};

AdContainerOuterWrapper.displayName = "AdContainerOuterWrapper";

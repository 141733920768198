import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { isPastPublicationEndDate } from "../utils";
import { PageTypes } from "../../globals";
import { getPageType } from "../../lib/analytics/utils";
import { useVideoDataContext } from "../contexts/VideoDataContext";

/**
 * We need a second Axios instance for client-side calls because some headers
 * need to change for CORS reasons.
 */
const clientSideAxios = axios.create();
delete clientSideAxios.defaults.headers.common.Accept;

// this function returns a custom hook for managing the state of the carousel
// component. It returns an object containing the state of the carousel and
// two functions for toggling the state of the carousel.
export const useCarouselState = () => {
  const [carouselState, setCarouselState] = useState({
    isMuted: false,
    showSubtitles: false,
    isEnd: false,
    isBeginning: true
  });

  // Create a single function to update state properties.
  const toggleCarouselState = useCallback((name, value) => {
    setCarouselState(state => ({
      ...state,
      [name]: value
    }));
  }, []);

  return {
    carouselState,
    toggleCarouselState
  };
};

export function useScript(source, id, callback, fallback) {
  useEffect(() => {
    if (!source || fallback) return;
    const script = document.createElement("script");
    script.src = source;
    script.async = true;
    script.onload = () => callback?.();
    script.id = id;
    document.body.appendChild(script);
    return () => {
      if (document.getElementById(id)) document.body.removeChild(script);
    };
  }, [source, fallback]);
}

// Block auto-refresh on Homepage when a video is playing and unmuted and not past its end date, if it's a video event with an end date
// Creates hpRefreshTests that are used by Assembler's AutoUpdate component to determine when to auto-refresh:
// https://github.com/WPMedia/assembler/blob/main/components/features/fronts/force-refresh/AutoUpdate.jsx
export const useBlockHomepageRefresh = (isMuted, isPlaying) => {
  const { originalVideo, videoData } = useVideoDataContext();
  const originalUuid = originalVideo?._id;
  const publicationEndDate =
    videoData?.additional_properties?.publicationEndDate;

  useEffect(() => {
    // only add hpRefreshTests if Orca is on the homepage and the video data has been fetched
    if (getPageType() !== PageTypes.HOME || !originalUuid) {
      return;
    }

    // AutoUpdate calls hpRefreshTest functions to determine if the reason for a pause-refresh has been concluded.
    // True = finished, False = still paused.
    const hpRefreshTest = () => {
      return (
        !isPlaying || isMuted || isPastPublicationEndDate(publicationEndDate)
      );
    };

    const setHpRefreshTest = () => {
      window.TWP = window.TWP || {};
      window.TWP.hpRefreshTests = window.TWP.hpRefreshTests || {};
      window.TWP.hpRefreshTests[`orca_${originalUuid}`] = hpRefreshTest;
    };

    setHpRefreshTest();

    // Delete the hpRefreshTest when the player is removed
    return () => {
      delete window?.TWP?.hpRefreshTests?.[`orca_${originalUuid}`];
    };
  }, [originalUuid, isMuted, isPlaying]);
};

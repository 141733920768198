import React, { memo } from "react";
import PropTypes from "prop-types";
import { styled } from "@washingtonpost/wpds-ui-kit";

/**
 * The Headline component is used to display the headline of the video.
 */
const Headline = props => {
  const { headline } = props;

  const HeadlineOverlay = styled("div", {
    position: "absolute",
    bottom: "100px",
    display: "flex",
    textAlign: "left",
    opacity: 1
  });

  const ControlsHeadline = styled("p", {
    color: "$gray700",
    fontFamily: "$headline",
    fontSize: "$125"
  });

  return (
    <HeadlineOverlay>
      <ControlsHeadline>{headline}</ControlsHeadline>
    </HeadlineOverlay>
  );
};

Headline.propTypes = {
  /** The headline currently being displayed. */
  headline: PropTypes.string.isRequired
};

Headline.displayName = "Headline";

export default memo(Headline);

import React from "react";
import PropTypes from "prop-types";
import { Icon, Button } from "@washingtonpost/wpds-ui-kit";
import { Volume, Mute } from "@washingtonpost/wpds-assets";
import { useAdControlsContext } from "../../orca/ads/contexts/AdControlsContext";
import "./index.css";

const SoundIcon = ({ isMuted, isMiniPlayer }) => {
  return (
    <Icon fill="white" size={isMiniPlayer ? "100" : "150"} label="mute">
      {isMuted ? <Mute /> : <Volume />}
    </Icon>
  );
};

SoundIcon.propTypes = {
  isMuted: PropTypes.bool,
  isMiniPlayer: PropTypes.bool
};

export const SoundButton = ({
  muted = null,
  toggleMute,
  isMiniPlayer = false,
  buttonStyles
}) => {
  const { adMuted } = useAdControlsContext();

  const isMuted = muted ?? adMuted;

  return (
    <Button
      aria-label={isMuted ? "Unmute audio" : "Mute audio"}
      className={"focus-visible-highlight"}
      data-testid="sound-button"
      variant="primary"
      css={{
        border: "none",
        background: "none",
        padding: "0px",
        cursor: "pointer",
        justifySelf: "end",
        ...(buttonStyles && { ...buttonStyles }),
        "&:hover": {
          background: "none",
          right: "0px"
        }
      }}
      onClick={e => {
        e.stopPropagation();
        toggleMute();
      }}
    >
      <SoundIcon {...{ isMuted, isMiniPlayer }} />
    </Button>
  );
};

SoundButton.displayName = "SoundButton";

export const toggleMuteProp = {
  toggleMute: PropTypes.func,
  isMiniPlayer: PropTypes.bool
};

SoundButton.propTypes = {
  ...toggleMuteProp
};

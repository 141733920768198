import { useCallback, useEffect, useRef } from "react";
import { useDisplayFramework } from "../../../common/display-framework";
import { AdState, AutoPlayType, VideoEvents } from "../../globals";
import { subscribeEvent, unsubscribeEvent } from "../utils/elementEvents";
import { useVideoElementRefContext } from "../contexts/VideoElementRefContext";
import { useAutoplayContext } from "../contexts/AutoplayContext";
import { useAdsElementsRefContext } from "../contexts/AdsElementsRefContext";

/**
 * Custom hook that returns a function to handle wall display updates.
 * @param {Function} handlePause - Function to handle pausing the video.
 * @param {Function} pause - Function to pause the video.
 * @param {Object} videoData - Object containing video data.
 * @returns {Function} - Function to handle wall display updates.
 */
const useHandleWalls = (playerState, adState, pause) => {
  const { autoplayState } = useAutoplayContext();
  // Mutable reference to updated callback function
  const handleWallsCallbackRef = useRef();
  const { adControlsRef } = useAdsElementsRefContext();
  const videoElementRef = useVideoElementRefContext();

  // Effect that updates callback function
  useEffect(() => {
    // Don't pause the video if looping promo.
    if (autoplayState.type === AutoPlayType.LOOP_NO_CONTROLS) return;

    const callback = walls => {
      const isWallDisplaying = () =>
        Object.values(walls).some(readyDisplay => readyDisplay === true);

      if (isWallDisplaying()) {
        if (playerState.isPlaying) {
          // Pause video immediately
          pause();
        } else if (adState === AdState.PLAYING) {
          // Pause ad immediately
          adControlsRef.current?.pauseAd();
        } else {
          // Wait for video or ad to start and THEN pause
          const currentVideo = videoElementRef.current;

          const handleEvent = e => {
            unsubscribeEvent(currentVideo, VideoEvents.PLAY, handleEvent);
            unsubscribeEvent(currentVideo, VideoEvents.AD_START, handleEvent);

            // (Pause only if wall is STILL displaying)
            if (isWallDisplaying()) {
              if (e.type === VideoEvents.PLAY) {
                setTimeout(() => pause(), 0);
              }
              if (e.type === VideoEvents.AD_START) {
                setTimeout(() => adControlsRef.current?.pauseAd(), 0);
              }
            }
          };

          subscribeEvent(currentVideo, VideoEvents.PLAY, handleEvent);
          subscribeEvent(currentVideo, VideoEvents.AD_START, handleEvent);
        }
      }
    };

    handleWallsCallbackRef.current = callback;
  }, [
    adControlsRef,
    adState,
    autoplayState.type,
    pause,
    playerState.isPlaying,
    videoElementRef
  ]);

  // Immutable callback that executes updated callback function
  // Must be immutable in order to avoid multiple registrations in display framework
  const handleWallsCallback = useCallback(walls => {
    handleWallsCallbackRef.current?.(walls);
  }, []);

  return handleWallsCallback;
};

/**
 * A custom hook that returns a function to update the display of a video element.
 * @param {object} playerState - The state of the video player.
 * @param {object} adState - The state of the ad player.
 * @param {Function} pause - A boolean indicating whether the video is paused or not.
 * @returns {function} - A function to update the display of the video element.
 */
const useUpdateDisplay = (playerState, adState, pause) => {
  const videoElementRef = useVideoElementRefContext();

  const handleWalls = useHandleWalls(playerState, adState, pause);

  const updateDisplay = useDisplayFramework({
    type: "video",
    ref: videoElementRef,
    /**
     * TODO: Restore `pauseSelf` after thoroughly testing (esp. interaction with sticky player)
     * Previously, we were passing the wrong property so this functionality has not been in place for some time!
     */
    // pauseSelf: pause,
    handleWalls
  });

  return updateDisplay;
};

export default useUpdateDisplay;

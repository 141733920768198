// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../orca-ui/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../orca-ui/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#scrubber #scrubber-bar {
  position: relative;
  border-radius: 3px;
  height: 4px;
}
#scrubber #scrubber-dot {
  min-width: 10px;
  min-height: 10px;
  margin-left: -4px;
  background-color: inherit;
  transform: scale(0);
  transition: transform 0.1s cubic-bezier(0.4, 0, 1, 1);
}

#scrubber:hover #dot {
  transform: scale(1);
}
`, "",{"version":3,"sources":["webpack://./../components/src/common/scrubber.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;AACb;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,yBAAyB;EACzB,mBAAmB;EACnB,qDAAqD;AACvD;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["#scrubber #scrubber-bar {\n  position: relative;\n  border-radius: 3px;\n  height: 4px;\n}\n#scrubber #scrubber-dot {\n  min-width: 10px;\n  min-height: 10px;\n  margin-left: -4px;\n  background-color: inherit;\n  transform: scale(0);\n  transition: transform 0.1s cubic-bezier(0.4, 0, 1, 1);\n}\n\n#scrubber:hover #dot {\n  transform: scale(1);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

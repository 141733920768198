import React from "react";
import PropTypes from "prop-types";
import { getDurationISO8601 } from "../../../common/utils";
import { imageResizer } from "../../lib/utils";
import { AutoPlayType, DEFAULT_VIDEO_THUMBNAIL } from "../../globals";
import { useVideoDataContext } from "../contexts/VideoDataContext";
import { getAutoplayType } from "../utils";

export const SEOVideoObject = () => {
  const { videoData } = useVideoDataContext();
  if (
    !videoData ||
    !videoData._id ||
    getAutoplayType(videoData) === AutoPlayType.LOOP_NO_CONTROLS ||
    videoData?.status === "ended"
  )
    return null;

  const seoSchemaData = videoData?._id
    ? {
        "@context": "https://schema.org",
        "@type": "VideoObject",
        name: videoData.headlines?.basic,
        description: videoData.description?.basic,
        thumbnailUrl: videoData.promo_image?.url
          ? imageResizer(videoData.promo_image.url)
          : DEFAULT_VIDEO_THUMBNAIL,
        uploadDate: videoData.publish_date,
        embedUrl: `https://www.washingtonpost.com/video/c/embed/${videoData._id}`
      }
    : {};

  if (videoData.video_type === "live") {
    seoSchemaData.publication = [
      {
        "@type": "BroadcastEvent",
        isLiveBroadcast: true,
        startDate: videoData.display_date,
        endDate: videoData.additional_properties?.publicationEndDate
      }
    ];
  } else {
    const mp4Stream = videoData.streams?.filter(
      stream => stream["stream_type"] === "mp4" && stream["bitrate"] === 600
    )[0];

    mp4Stream?.url && (seoSchemaData.contentUrl = mp4Stream.url);
    seoSchemaData.duration = getDurationISO8601(videoData.duration);
  }

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(seoSchemaData) }}
    />
  );
};

SEOVideoObject.propTypes = {
  video: PropTypes.object
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Code, Success } from "@washingtonpost/wpds-assets";
import { Icon, Button, styled } from "@washingtonpost/wpds-ui-kit";
import "./index.css";

const CopyCodeButtonLabel = styled("p", { fontSize: "$075" });

const CopyBottonWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center"
});

/**
 * Component that displays Copy Code Button.
 * @returns A button containing the wpds link icon.
 */
export const CopyCodeButton = ({ content, onBlur }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  // Clear copied message after 3 seconds
  useEffect(() => {
    if (showConfirmation === false) {
      return;
    }

    const timeoutId = setTimeout(() => setShowConfirmation(false), 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [showConfirmation]);

  const copyTextToClipboard = () => {
    navigator.clipboard.writeText(content);
    setShowConfirmation(true);
  };

  return (
    <CopyBottonWrapper>
      <Button
        aria-label="Copy embed"
        icon="center"
        className={"focus-visible-highlight"}
        css={{
          backgroundColor: showConfirmation && "$green600",
          marginBottom: "$050"
        }}
        onClick={copyTextToClipboard}
        onBlur={onBlur}
      >
        <Icon fill="black" size={125} label="Embed Code">
          {showConfirmation ? <Success /> : <Code />}
        </Icon>
      </Button>
      <CopyCodeButtonLabel>
        {showConfirmation ? "Copied!" : "Embed Code"}
      </CopyCodeButtonLabel>
    </CopyBottonWrapper>
  );
};

CopyCodeButton.displayName = "CopyCodeButton";

CopyCodeButton.propTypes = {
  /** The content to copy to the clipboard. */
  content: PropTypes.string.isRequired,
  onBlur: PropTypes.func
};

import splunk from "../../../common/splunk";
import { getBrowserType, getDeviceTypeAkamai } from "../../../common/utils";
import { getPageType, getPlayerType } from "../../lib/analytics/utils";
import { isElementInViewport } from "../../lib/utils";

export const sendVideoSplunkEvent = (
  videoElementRef,
  eventType,
  eventName,
  params = {},
  config = {}
) => {
  if (!videoElementRef?.current) return;

  const { debug = false } = config;

  const isProd = process.env.NODE_ENV === "production";

  const splunkData = {
    eventType,
    userAgent: window.navigator.userAgent,
    browserType: getBrowserType(),
    deviceType: getDeviceTypeAkamai(),
    href: window.location.href,
    hostname: window.location.host,
    pageType: getPageType(),
    playerType: getPlayerType(config),
    player: "orca",
    isElementInViewport: isElementInViewport(videoElementRef.current),
    videoId: videoElementRef.current.id,
    ...params
  };

  // We only want to record events from prod environments
  if (isProd) {
    splunk.send(eventName, splunkData);
  }

  if (debug) {
    console.log(
      `${isProd ? "" : `Mock`} Splunk event sent: ${eventName}. Video: ${
        videoElementRef.current.id
      }`,
      splunkData
    );
  }
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../orca-ui/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../orca-ui/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.infinite-rotate {
  animation: rotation 2s infinite linear;
  @media (prefers-reduced-motion) {
    animation: none;
  }
}

.infinite-rotate-icon svg {
  animation: rotation 2s infinite linear;
  @media (prefers-reduced-motion) {
    animation: none;
  }
}
`, "",{"version":3,"sources":["webpack://./../components/src/common/styles/animations.css"],"names":[],"mappings":"AAAA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,sCAAsC;EACtC;IACE,eAAe;EACjB;AACF;;AAEA;EACE,sCAAsC;EACtC;IACE,eAAe;EACjB;AACF","sourcesContent":["@keyframes rotation {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(359deg);\n  }\n}\n\n.infinite-rotate {\n  animation: rotation 2s infinite linear;\n  @media (prefers-reduced-motion) {\n    animation: none;\n  }\n}\n\n.infinite-rotate-icon svg {\n  animation: rotation 2s infinite linear;\n  @media (prefers-reduced-motion) {\n    animation: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { useEffect, useRef, useState } from "react";
import { useVideoAnalyticsContext } from "../contexts/VideoAnalyticsContext";
import { VideoEvent } from "../../lib/analytics/utils";

/**
 *  A custom hook that tracks how long a user has viewed a live video while unmuted
 *  and sends an analytics event every 5 minutes
 */
export const useLiveCounter = (isLive, playerState) => {
  const { sendAnalyticsEvent } = useVideoAnalyticsContext();

  const analyticsCounter = useRef(0);
  const [secPassed, setSecPassed] = useState(0);

  const orcaPlayingWithVolume = !playerState?.isMuted && playerState?.isPlaying;

  const setupPolling = () => {
    const interval = setInterval(() => {
      setSecPassed(prevState => prevState + 1);
    }, 1000);
    return () => clearInterval(interval);
  };

  useEffect(() => {
    setupPolling();
  }, []);

  // 5 minutes in seconds
  const DEFAULT_INTERVAL_SECONDS = 300;

  useEffect(() => {
    if (isLive && orcaPlayingWithVolume) {
      // Adds 1 every second
      const newCounter = analyticsCounter.current + 1;
      analyticsCounter.current = newCounter;

      // Fires every 5 minutes
      if (newCounter % DEFAULT_INTERVAL_SECONDS === 0) {
        // Analytics wants the avLiveProgress string to be divisible by 5
        // Ex: at the first fire it should be 5, next is 10, etc
        sendAnalyticsEvent(VideoEvent.LIVE_VIDEO_THRESHOLD, {
          avLiveProgress: `${(newCounter / DEFAULT_INTERVAL_SECONDS) * 5}`
        });
      }
    }
  }, [secPassed]);
};

import React from "react";
import PropTypes from "prop-types";

import { Box } from "@washingtonpost/wpds-ui-kit";
import AudioScrubber from "./AudioScrubber";
import { SoundButton, toggleMuteProp } from "./buttons/SoundButton";

export const Volume = ({
  setShowVolumeSlider,
  isVertical,
  isMiniPlayer,
  showVolumeSlider,
  playerState,
  handleVolumeChange,
  toggleMute
}) => {
  const { isMuted } = playerState;
  return (
    <Box
      onMouseOver={() => setShowVolumeSlider(true)}
      onMouseLeave={() => setShowVolumeSlider(false)}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <Box
        css={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: "5px"
        }}
      >
        {!isVertical && !isMiniPlayer && (
          <Box
            css={{
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
              width: showVolumeSlider ? "105px" : "0px",
              transition: "width 0.5s ease-in-out",
              "@sm": {
                display: "none"
              }
            }}
          >
            <AudioScrubber
              {...{
                playerState,
                handleVolumeChange,
                toggleMute
              }}
            />
          </Box>
        )}
        <SoundButton
          muted={isMuted}
          toggleMute={toggleMute}
          isMiniPlayer={isMiniPlayer}
        />
      </Box>
    </Box>
  );
};

Volume.propTypes = {
  setShowVolumeSlider: PropTypes.func,
  isVertical: PropTypes.bool,
  showVolumeSlider: PropTypes.bool,
  playerState: PropTypes.shape({
    isMuted: PropTypes.bool
  }),
  handleVolumeChange: PropTypes.func,
  ...toggleMuteProp
};

export default Volume;

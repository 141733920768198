import React, { useState, useEffect, useRef } from "react";
import Loading from "@washingtonpost/wpds-assets/asset/loading";
import { Button, Icon, keyframes, styled } from "@washingtonpost/wpds-ui-kit";
import { useVideoElementRefContext } from "../orca/contexts/VideoElementRefContext";

const IconWrapper = styled("div", {
  position: "absolute",
  inset: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
});

const IconStyled = styled(Icon, {
  boxSizing: "content-box",
  "@sm": {
    padding: "20px 8px 20px 8px",
    margin: "-20px -8px -20px -8px"
  }
});

const spin = keyframes({
  from: { transform: "rotate(0deg)" },
  to: { transform: "rotate(360deg)" }
});

const LoadingState = () => {
  const videoElementRef = useVideoElementRefContext();

  const [isWaiting, setIsWaiting] = useState(false);

  const isWaitingTimeout = useRef(null);
  const isPlayingTimeout = useRef(null);
  useEffect(() => {
    const videoElement = videoElementRef.current;
    if (!videoElement) return;

    const waitingHandler = () => {
      clearTimeout(isWaitingTimeout.current);

      isWaitingTimeout.current = setTimeout(() => {
        setIsWaiting(true);
      }, 200);
    };

    const playHandler = () => {
      clearTimeout(isWaitingTimeout.current);
      clearTimeout(isPlayingTimeout.current);

      isPlayingTimeout.current = setTimeout(() => {
        setIsWaiting(false);
      }, 50);
    };

    const pauseHandler = () => {
      clearTimeout(isWaitingTimeout.current);
      clearTimeout(isPlayingTimeout.current);

      isPlayingTimeout.current = setTimeout(() => {
        setIsWaiting(false);
      }, 50);
    };

    videoElement.addEventListener("waiting", waitingHandler);
    videoElement.addEventListener("play", playHandler);
    videoElement.addEventListener("playing", playHandler);
    videoElement.addEventListener("pause", pauseHandler);

    // clean up
    return () => {
      clearTimeout(isWaitingTimeout.current);
      clearTimeout(isPlayingTimeout.current);

      videoElement.removeEventListener("waiting", waitingHandler);
      videoElement.removeEventListener("play", playHandler);
      videoElement.removeEventListener("playing", playHandler);
      videoElement.removeEventListener("pause", pauseHandler);
    };
  }, [videoElementRef]);

  return isWaiting ? (
    <IconWrapper>
      <Button
        variant="primary"
        isOutline="false"
        icon="center"
        css={{
          border: "none",
          background: "$alpha50",
          "&:hover": {
            background: "$alpha50"
          }
        }}
      >
        <IconStyled
          label="loading"
          fill="white"
          css={{
            width: "25px",
            height: "25px",
            animation: `${spin} 2s linear infinite`,
            "@reducedMotion": {
              animation: "none"
            }
          }}
        >
          <Loading />
        </IconStyled>
      </Button>
    </IconWrapper>
  ) : null;
};

LoadingState.displayName = "LoadingState";

export default LoadingState;

import { styled } from "@washingtonpost/wpds-ui-kit";
import React, { useState, useRef } from "react";
import { complexDuration } from "../../common/utils";
import { Scrubber } from "../../common/Scrubber";
import { MediaState } from "../../common/MediaState";
import splunk from "../../common/splunk";
import PropTypes from "prop-types";
import { Flex, TextBox } from "../../common/styles/index.stitches";
import {
  useUpdateDuration,
  useUpdatePersistentProgress,
  useUpdateProgressRef
} from "./hooks/useVideoScrubber";
import { useVideoElementRefContext } from "../orca/contexts/VideoElementRefContext";

const ScrubberWrapper = styled(Flex, {
  gap: "$050"
});

// Smaller functions for state changes
const handleLoadedState = (
  videoElementRef,
  currState,
  setDuration,
  loaded,
  setLoaded
) => {
  setDuration(videoElementRef.current.duration);
  if (currState !== MediaState.PAUSED) videoElementRef.current.play();
  if (!loaded) setLoaded(true);
};

const handleReadyState = setProgress => {
  setProgress(0);
};

const handleErrorState = error => {
  splunk.send("video-scrubber-error", {
    player: "Orca",
    errorMessage: error.message
  });
  console.error(error);
};

const ProgressWrapper = ({
  currState,
  setSelected,
  changeToState,
  duration,
  progress,
  setProgress
}) => {
  const videoElementRef = useVideoElementRefContext();

  return (
    <ScrubberWrapper
      direction="column"
      align="end"
      justify="start"
      onMouseDown={() => setSelected(true)}
      onMouseUp={() => setSelected(false)}
    >
      <Scrubber
        ref={videoElementRef}
        theme="dark"
        currState={currState}
        changeToState={changeToState}
        duration={duration}
        progress={progress}
        setProgress={setProgress}
      />
      <TextBox size="md" align="right" color="secondary">
        {complexDuration(videoElementRef.current?.currentTime, duration)}
      </TextBox>
    </ScrubberWrapper>
  );
};

/**
 * Renders a video scrubber UI element.
 * @param {number} initDuration - The initial duration of the video in seconds.
 * @param {Function} setSelected - A function to set the selected state of the
 * video scrubber.
 * @param {number} persistentProgress - The persistent progress of the video
 * scrubber.
 * @param {Function} setPersistentProgress - A function to set the persistent
 * progress of the video scrubber.
 * @returns {JSX.Element} - The rendered component.
 */
export const VideoScrubber = ({
  initDuration,
  setSelected,
  persistentProgress,
  setPersistentProgress
}) => {
  const videoElementRef = useVideoElementRefContext();

  const [progress, setProgress] = useState(persistentProgress);
  const [currState, setCurrState] = useState(MediaState.READY);
  const [duration, setDuration] = useState(initDuration || NaN);
  const [loaded, setLoaded] = useState(false);
  const progressRef = useRef(persistentProgress);

  useUpdateDuration(duration, initDuration, setDuration);

  useUpdateProgressRef(progress, progressRef);

  useUpdatePersistentProgress(progressRef, setPersistentProgress);

  const changeToState = (nextState, error) => {
    if (nextState === MediaState.LOADED) {
      handleLoadedState(
        videoElementRef,
        currState,
        setDuration,
        loaded,
        setLoaded
      );
    } else if (nextState === MediaState.READY) {
      handleReadyState(setProgress);
    } else if (nextState === MediaState.ERROR) {
      handleErrorState(error);
    }
    setCurrState(nextState);
  };

  return (
    <ProgressWrapper
      {...{
        currState,
        setSelected,
        changeToState,
        duration,
        progress,
        setProgress
      }}
    />
  );
};

const sharedPropTypes = {
  setSelected: PropTypes.func.isRequired
};

ProgressWrapper.propTypes = {
  currState: PropTypes.object.isRequired,
  changeToState: PropTypes.func.isRequired,
  duration: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  setProgress: PropTypes.func.isRequired,
  ...sharedPropTypes
};

VideoScrubber.propTypes = {
  initDuration: PropTypes.number.isRequired,
  persistentProgress: PropTypes.number.isRequired,
  setPersistentProgress: PropTypes.func.isRequired,
  ...sharedPropTypes
};

import { useEffect, useMemo, useState } from "react";
import { useStickyPlayerContext } from "../../../../common/StickyPlayerContext";
import { useVideoAnalyticsContext } from "../../contexts/VideoAnalyticsContext";
import { useVideoDataContext } from "../../contexts/VideoDataContext";
import {
  PlayerType,
  StickyPlayerEventLabel,
  VideoEvent,
  getPlayerType
} from "../../../lib/analytics/utils";
import {
  getStickyPlayerContext,
  shouldOpenSticky,
  shouldSendStickyAnalytics
} from "./utils";

export const useStickyPlayer = (config, stickyPlayerContext) => {
  const { uniqueId } = useVideoDataContext();
  const { sendAnalyticsEvent, setPlayerType } = useVideoAnalyticsContext();

  const stickyPlayerHookContext = useStickyPlayerContext();

  const { playingId, openId, updateStickyContext } = useMemo(
    () => getStickyPlayerContext(stickyPlayerContext, stickyPlayerHookContext),
    [stickyPlayerContext, stickyPlayerHookContext]
  );

  const [isStickyEnabled, setIsStickyEnabled] = useState(false);
  const [isOpenSticky, setIsOpenSticky] = useState(false);
  // Used to override the global sticky player context, and keep the sticky video player open during playthrough
  const [overrideStickyContext, setOverrideStickyContext] = useState(false);

  useEffect(() => {
    const openSticky = shouldOpenSticky(
      overrideStickyContext,
      isStickyEnabled,
      openId,
      playingId,
      uniqueId
    );

    // check if the sticky video player should close SPECIFICALLY DUE TO OTHER MEDIA STARTING
    if (
      shouldSendStickyAnalytics(
        isOpenSticky,
        overrideStickyContext,
        playingId,
        uniqueId,
        openId
      )
    ) {
      sendAnalyticsEvent(VideoEvent.STICKY_PLAYER_CLOSE, {
        label: StickyPlayerEventLabel.OTHER_MEDIA_CLOSE
      });
    }

    setIsOpenSticky(openSticky);
  }, [
    openId,
    playingId,
    uniqueId,
    isStickyEnabled,
    overrideStickyContext,
    isOpenSticky,
    sendAnalyticsEvent
  ]);

  // Whenever the orca player is open sticky, we need to reflect that in analytics
  useEffect(() => {
    setPlayerType(isOpenSticky ? PlayerType.STICKY : getPlayerType(config));
  }, [isOpenSticky, config, setPlayerType]);

  return {
    isStickyEnabled,
    isOpenSticky,
    playingId,
    openId,
    updateStickyContext,
    setOverrideStickyContext,
    setIsStickyEnabled,
    overrideStickyContext
  };
};

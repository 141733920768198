import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  Share,
  Email,
  Forward,
  Facebook,
  Twitter,
  Reddit
} from "@washingtonpost/wpds-assets";
import { Icon, Button, styled, Popover } from "@washingtonpost/wpds-ui-kit";
import {
  canShareNatively,
  shareViaEmail,
  shareViaFacebook,
  shareViaReddit,
  shareViaTwitter,
  openNativeShareTool,
  generateEmbedCode
} from "../../lib/social/utils";
import { CopyButton } from "./CopyButton";
import { CopyCodeButton } from "./CopyCodeButton";
import "./index.css";

const PopoverHeaderWrapper = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start"
});

const PopoverHeader = styled("div", {
  display: "flex",
  alignItems: "center",
  fontFamily: "$headline",
  fontWeight: "$bold",
  fontSize: "$150",
  color: "$gray0"
});

const PopoverGrid = styled("div", {
  display: "grid",
  paddingTop: "$100",
  color: "$gray0",
  gridTemplateColumns: "1fr 1fr 1fr",
  gridGap: "15px"
});

const PopoverGridElement = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center"
});

const PopoverButtonLabel = styled("p", { fontSize: "$075" });

/**
 * Component that display share options for video.
 * @returns A button containing the wpds share icon.
 */
export const ShareButton = ({
  headline,
  canonicalUrl: url,
  isVertical,
  setSelected
}) => {
  const useNativeShare = canShareNatively({ title: headline, url });

  const [open, setOpen] = useState(false);
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    setOpen(focus);
  }, [focus]);

  const onOpenChange = change => {
    if (focus) return;
    setOpen(change);
  };

  const socialLinks = useMemo(
    () => [
      {
        component: <Facebook />,
        text: "Facebook",
        onShare: () => {
          shareViaFacebook({ url });
        }
      },
      {
        component: <Twitter />,
        text: "Twitter",
        onShare: () => {
          shareViaTwitter({ text: headline, url });
        }
      },
      {
        component: <Reddit />,
        text: "Reddit",
        onShare: () => {
          shareViaReddit({ headline, url });
        }
      },
      {
        component: <Email />,
        text: "Email",
        onShare: () => {
          shareViaEmail({
            body: url,
            subject: `[The Washington Post] ${headline}`
          });
        }
      }
    ],
    [headline, url]
  );

  return (
    <Popover.Root open={!useNativeShare && open} onOpenChange={onOpenChange}>
      <Popover.Trigger asChild>
        <Button
          icon="center"
          variant="primary"
          css={{
            border: "none",
            background: "none",
            padding: "unset",
            "&:hover": {
              background: "none"
            }
          }}
          onFocus={() => {
            setSelected(true);
            if (!open) setFocus(true);
          }}
          onClick={e => {
            e.stopPropagation();
            useNativeShare && openNativeShareTool({ title: headline, url });
          }}
        >
          <Icon fill="white" size="150" label="share">
            <Share />
          </Icon>
        </Button>
      </Popover.Trigger>
      <Popover.Content
        css={{ width: "280px", padding: "$125", zIndex: 5 }}
        side="bottom"
        align={isVertical ? "center" : "start"}
        onClick={e => {
          e.stopPropagation();
        }}
        onOpenAutoFocus={e => {
          e.preventDefault();
        }}
        onCloseAutoFocus={e => {
          e.preventDefault();
        }}
        onInteractOutside={() => {
          setOpen(false);
          setFocus(false);
        }}
      >
        <PopoverHeaderWrapper>
          <PopoverHeader>
            <Icon fill="black" label="share">
              <Forward />
            </Icon>
            <div style={{ marginLeft: "4px" }}>Share</div>
          </PopoverHeader>
        </PopoverHeaderWrapper>
        <PopoverGrid>
          {socialLinks.map(link => {
            return (
              <PopoverGridElement key={link.text}>
                <Button
                  className={"focus-visible-highlight"}
                  icon="center"
                  css={{
                    marginBottom: "$050"
                  }}
                  onClick={() => {
                    link.onShare();
                  }}
                >
                  <Icon fill="black" size={125} label={link.text}>
                    {link.component}
                  </Icon>
                </Button>
                <PopoverButtonLabel>{link.text}</PopoverButtonLabel>
              </PopoverGridElement>
            );
          })}
          <CopyButton content={url} />
          <CopyCodeButton
            content={generateEmbedCode(url, isVertical)}
            onBlur={() => {
              setFocus(false);
            }}
          />
        </PopoverGrid>
      </Popover.Content>
    </Popover.Root>
  );
};

ShareButton.displayName = "ShareButton";

ShareButton.propTypes = {
  /** The headline to pass to social media share links */
  headline: PropTypes.string.isRequired,
  /** The canonical URL to pass to social media share links */
  canonicalUrl: PropTypes.string.isRequired,
  /** Boolean if video is vertical */
  isVertical: PropTypes.bool,
  setSelected: PropTypes.func
};

import React, { forwardRef, memo, useRef } from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";

import { VideoDataProvider } from "./contexts/VideoDataContext";
import { VideoElementRefProvider } from "./contexts/VideoElementRefContext";
import { VideoAnalyticsProvider } from "./contexts/VideoAnalyticsContext";
import { AutoplayProvider } from "./contexts/AutoplayContext";
import { PlayerSelection } from "./PlayerSelection";
import { StickyVideoProvider } from "./contexts/StickyVideoContext";
import { PerformanceEvents } from "../globals";
import { sendPerformanceEvent } from "./utils";
import { VideoPerformanceProvider } from "./contexts/PerformanceContext";
import { AdsElementsRefProvider } from "./contexts/AdsElementsRefContext";

sendPerformanceEvent(PerformanceEvents.FIRST_LINE_EXECUTION);

const Orca = forwardRef(
  (
    {
      uuid,
      video,
      onVideoEnded = noop,
      config = {},
      promoEnabled = true,
      isSandbox = false,
      isCarousel = false,
      stickyPlayerContext = {}
    },
    ref
  ) => {
    const videoProps = {
      onVideoEnded,
      config,
      promoEnabled,
      isSandbox
    };

    const defaultRef = useRef();
    const orcaRef = ref ?? defaultRef;

    return (
      <VideoElementRefProvider>
        <VideoDataProvider
          {...{
            uuid,
            video,
            isSandbox,
            config,
            orcaRef
          }}
        >
          <AutoplayProvider {...{ config }}>
            <VideoAnalyticsProvider {...{ config }}>
              <AdsElementsRefProvider>
                <VideoPerformanceProvider {...{ config }}>
                  <StickyVideoProvider {...{ config, stickyPlayerContext }}>
                    <PlayerSelection
                      forwardedRef={orcaRef}
                      isCarousel={isCarousel}
                      {...videoProps}
                    />
                  </StickyVideoProvider>
                </VideoPerformanceProvider>
              </AdsElementsRefProvider>
            </VideoAnalyticsProvider>
          </AutoplayProvider>
        </VideoDataProvider>
      </VideoElementRefProvider>
    );
  }
);

Orca.displayName = "Orca";

export const OrcaPropTypes = {
  /** Video UUID */
  uuid: PropTypes.string,
  /** Video API Object */
  video: PropTypes.object,
  /** Pass callback function when video ends. */
  onVideoEnded: PropTypes.func,
  // TO DO: audit config props
  /** Pass in config to control size of player, showControls etc. */
  config: PropTypes.shape({
    /** Aspect ratio float, e.g. 0.5625 for 16:9
     * NOTE: Takes precedence over `aspectWidth` & `aspectHeight` */
    aspectRatio: PropTypes.number,
    /** Aspect ratio width, e.g. 16 for 16:9 */
    aspectWidth: PropTypes.number,
    /** Aspect ratio height, e.g. 9 for 16:9 */
    aspectHeight: PropTypes.number,
    /** Placeholder object */
    placeholder: PropTypes.object,
    /** React prop for class */
    className: PropTypes.string,
    /** Video caption */
    caption: PropTypes.node,
    /** Video a/b test group */
    testGroup: PropTypes.string,
    /** Video a/b test object */
    abTest: PropTypes.object,
    /** If video should load first video fragment on load. */
    loadFirstFragmentOnly: PropTypes.bool,
    /** Boolean value which enables/disables console log messages. */
    debug: PropTypes.bool,
    /** url that overrides promo image url */
    alternateArt: PropTypes.string,
    /** Boolean value to indicate if this player should playthrough to recommended videos (if video ANS additional_properties.embedContinuousPlay is also true); true by default */
    allowPlaythrough: PropTypes.bool,
    /** Boolean value to determine whether video will open in the sticky player when a user scrolls past it; true by default */
    isSticky: PropTypes.bool,
    /** Boolean value to indicates whether the player will allow ads (the video ANS also has fields that determine if ads are allowed for that particular video) */
    hasAds: PropTypes.bool,
    /** Boolean value determines if players surfaces logs from Zeus Iris, our ads plugin */
    debugZeus: PropTypes.bool,
    /** Boolean to set debug: true for HLSjs, useful for debugging auto-bitrate issues */
    debugHLS: PropTypes.bool,
    /** Object value to allow users to override ad settings (mainly for testing and development) */
    adOverrides: PropTypes.shape({
      /** force orca player to allways render an ad */
      forceAd: PropTypes.bool,
      /** overrides the video ANS object's provided video adZone */
      adZoneOverride: PropTypes.string,
      /** overrides the provided timeout for ad calls */
      adTimeoutOverride: PropTypes.number,
      /** overrides the provided ad skip value (0 = no skip, 1 = skip) */
      skip: PropTypes.number,
      /** overrides the provided ad skip min value */
      skipmin: PropTypes.number,
      /** overrides the provided ad skip after value */
      skipafter: PropTypes.number,
      /** set whether add will preload in orca player */
      preloadAd: PropTypes.bool,
      /** mocks what domain the video is being rendered on */
      domain: PropTypes.string
    })
  }),
  /** Boolan value to enable Promo video; true by default */
  promoEnabled: PropTypes.bool,
  /** Boolean value to indicate this player is part of the Vertical Video Carousel */
  isCarousel: PropTypes.bool,
  /** Flag for lower environments */
  isSandbox: PropTypes.bool,
  /** Check if the storybook is sending custom tags. */
  isDebug: PropTypes.bool,
  /** Debug tags from storybook. */
  debugTags: PropTypes.arrayOf(PropTypes.string),
  /** Whether share button is displayed */
  allowShare: PropTypes.bool,
  /** Override for whether video autoplays */
  isAutoplayOverride: PropTypes.bool,
  /** Override for whether video initializes muted */
  isMutedOverride: PropTypes.bool,
  /** Override for whether video initializes showing subtitles */
  showSubtitlesOverride: PropTypes.bool,
  /** Override for playerType in analytics */
  playerTypeOverride: PropTypes.string,
  stickyPlayerContext: PropTypes.object
};

Orca.propTypes = OrcaPropTypes;

const MemoOrca = memo(Orca);

export { MemoOrca as Orca };

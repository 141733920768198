import { getCookie } from "@washingtonpost/front-end-utils";

/**
 * Checks if the plugin should be initialized based on three checks:
 * 1. Is the user premium? Return false.
 * 2. Is the user in a mini crossword webview? Return false.
 * 3. If the user is on desktop OR the plugin type is games OR the forceAllowMobile
 * option is true, return true.
 * @param {string} type - The type of the plugin.
 * @param {boolean} forceAllowMobile - If the plugin should be forced to run on mobile.
 * @returns {boolean} True if the plugin should be initialized, false otherwise.
 */
export const shouldInitializePlugin = (type = "", forceAllowMobile = false) => {
  if (isPremium()) return false;
  if (isMiniCrosswordWebview()) return false;
  return !!(isDesktop() || type === "games" || forceAllowMobile);
};

/**
 * Checks if the user is premium based on the wapo_actmgmt cookie,
 * the rplampr cookie, and the wp_meta_data.isPremium flag.
 * @returns {boolean} True if the user is premium, false otherwise.
 */
export const isPremium = () => {
  if (typeof window === "undefined") return false;

  const isPremiumRplampr = getCookie("rplampr")?.includes("Premium");

  const wapoActmgmtCookie = getCookie("wapo_actmgmt");
  const isPremiumWapoActmgmt =
    wapoActmgmtCookie?.includes("NOADS:1") ||
    wapoActmgmtCookie?.includes("EU_NOADS:1");

  const isPremiumWpMetaData = window.wp_meta_data?.isPremium === true;

  return isPremiumRplampr || isPremiumWapoActmgmt || isPremiumWpMetaData;
};

/**
 * Checks if the user is in a mini crossword webview based on the pathname
 * and the wp_wv cookie.
 * @returns {boolean} True if the user is in a mini crossword webview,
 * false otherwise.
 */
const isMiniCrosswordWebview = () => {
  if (typeof window === "undefined") return false;
  const webviewCookie = getCookie("wp_wv");
  return window.location.pathname.includes("mini-meta") && webviewCookie;
};

/**
 * Checks if the user is on a desktop device based on the user agent.
 * @returns {boolean} True if not a tablet or mobile device, false otherwise.
 */
const isDesktop = () => {
  // If the user agent is not defined, assume it's a desktop.
  if (typeof navigator === "undefined") return true;
  return !/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
    navigator.userAgent
  );
};

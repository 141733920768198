import { styled } from "@washingtonpost/wpds-ui-kit";

export const Video = styled("video", {
  position: "absolute",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  objectFit: "contain"
});

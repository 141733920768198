import { styled } from "@washingtonpost/wpds-ui-kit";
import { generateGradient } from "../../common/utils";

export const ControlsContainer = styled("div", {
  top: "0",
  left: "0",
  position: "absolute",
  height: "100%",
  width: "100%",
  display: "flex",
  zIndex: "3",
  opacity: 1,
  "& .fade-out": {
    opacity: 0
  }
});

export const ControlsWrapper = styled("div", {
  width: "100%",
  height: "100%",
  background: generateGradient({ topPercent: 15, bottomPercent: 25 })
});

export const TopControlsWrapper = styled("div", {
  position: "absolute",
  top: "0",
  width: "100%",
  paddingTop: "$075",
  paddingLeft: "$150",
  paddingRight: "$150",
  "@sm": {
    paddingLeft: "$075",
    paddingRight: "$075"
  }
});

export const TopControls = styled("div", {
  justifyContent: "space-between",
  flexDirection: "row",
  width: "100%",
  display: "flex",
  "@sm": {
    alignItems: "center"
  }
});

export const BottomControlsWrapper = styled("div", {
  position: "absolute",
  bottom: "0%",
  width: "100%",
  paddingBottom: "$075",
  paddingLeft: "$150",
  paddingRight: "$150",
  "@sm": {
    paddingLeft: "$075",
    paddingRight: "$075"
  }
});

export const BottomControls = styled("div", {
  display: "grid",
  width: "100%",
  alignItems: "center",
  justifyContent: "space-between"
});

export const LeftButtonsWrapper = styled("div", {
  display: "flex"
});

export const LiveWrapper = styled("div", {
  alignItems: "center",
  display: "flex",
  gap: "$025",
  paddingRight: "$100"
});

export const LiveText = styled("div", {
  color: "$secondary",
  fontWeight: "$bold"
});

export const RedDot = styled("span", {
  height: "$050",
  width: "$050",
  backgroundColor: "$red100",
  borderRadius: "50%",
  display: "inline-block",
  animation: "sc-status-light-blink-animation 4s ease-in-out infinite alternate"
});

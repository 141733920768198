import { useImperativeHandle } from "react";
import { addOrcaEvent } from "../utils";
import { AdState, OrcaWindowEvents } from "../../globals";
import { useVideoDataContext } from "../contexts/VideoDataContext";
import { useAdsElementsRefContext } from "../contexts/AdsElementsRefContext";
import { useVideoElementRefContext } from "../contexts/VideoElementRefContext";
/**
 * Surfaces the methods that are available on orca refs.
 * Used in the vertical video carousel.
 */
export const useVideoHandlers = (
  ref,
  reset,
  hls,
  play,
  setRenderControls,
  promoEnabled,
  promoElement,
  pause,
  playerState,
  toggleMute,
  adState,
  initializeNewVideo,
  resetVideoState,
  setUpVideoSource,
  transitionToVideo
) => {
  const { videoId } = useVideoDataContext();
  const { adControlsRef } = useAdsElementsRefContext();
  const videoElementRef = useVideoElementRefContext();

  useImperativeHandle(
    ref,
    () => ({
      resetVideo() {
        reset();
        addOrcaEvent(OrcaWindowEvents.RESET_CAROUSEL_VIDEO, true, videoId);
      },

      startFirstVideo() {
        setUpVideoSource();
        transitionToVideo();
      },

      startVideoLoad() {
        setUpVideoSource();
      },

      stopVideoLoad() {
        hls?.stopLoad();
      },

      showControls() {
        setRenderControls(true);
      },

      playVideo() {
        if (promoEnabled) promoElement.current.playVideo();
        play();
      },

      pauseVideo() {
        pause();
      },

      toggleMute() {
        if (adState === AdState.PLAYING) {
          adControlsRef.current.toggleAdMute();
        } else {
          toggleMute();
        }
      },

      setTime(time) {
        if (adState === AdState.PLAYING) return;
        if (typeof time === "number")
          videoElementRef.current.currentTime = time;
      },

      restartVideo() {
        if (adState === AdState.PLAYING) return;
        videoElementRef.current.currentTime = 0;
      },

      initializeNewVideo(videoWillAutoplay) {
        initializeNewVideo(videoWillAutoplay);
      },

      resetVideoState(hasNextVideo) {
        resetVideoState(hasNextVideo);
      },

      videoPlayerState: playerState
    }),
    [
      playerState,
      reset,
      videoId,
      setUpVideoSource,
      hls,
      play,
      setRenderControls,
      promoEnabled,
      promoElement,
      pause,
      adState,
      adControlsRef,
      initializeNewVideo,
      resetVideoState,
      toggleMute,
      videoElementRef,
      transitionToVideo
    ]
  );
};

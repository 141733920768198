export const DEFAULT_VALUE_ANALYTICS = "(not set)";

export const Theme = {
  LIGHT: "light",
  DARK: "dark",
  HUMAN_READ: "human_read"
};

export const KEYBOARD_EVENTS = {
  SPACE: " ",
  ENTER: "Enter"
};

export const MediaType = {
  AUDIO: "audio",
  VIDEO: "video",
  PHOTO: "photo"
};

export const BROSWER = {
  OPERA: "Opera",
  EDGE: "Edge",
  CHROME: "Chrome",
  SAFARI: "Safari",
  FIREFOX: "Firefox",
  IE: "IE"
};

import { getCookie } from "../../../common/cookie-util";
import { AutoPlayType, AutoplayTags } from "../../globals";
import { getVideoApiUrl } from "../../lib/utils";
import crypto from "crypto"; // should have webcrypto.getRandomValues defined
import { v4 as uuidV4 } from "uuid";

export function goFullscreen(elem) {
  if (elem.requestFullscreen) {
    return elem.requestFullscreen();
  } else if (elem.webkitEnterFullscreen) {
    /* iphone Safari */
    return elem.webkitEnterFullscreen();
  } else if (elem.webkitRequestFullscreen) {
    /* Safari */
    return elem.webkitRequestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    return elem.mozRequestFullScreen();
  } else if (elem.msRequestFullscreen) {
    /* IE11 */
    return elem.msRequestFullscreen();
  }
}

export function closeFullscreen(videoElementRef) {
  // webkitExitFullscreen must be called on the video element if the native iOS player is open
  if (videoElementRef?.current?.webkitDisplayingFullscreen) {
    videoElementRef.current.webkitExitFullscreen();
  } else if (getFullScreen()) {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
  }
}

export function closeFullscreenPromise() {
  if (document.exitFullscreen) {
    return document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    /* Safari */
    return document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    /* IE11 */
    return document.msExitFullscreen();
  } else {
    return Promise.reject();
  }
}

function getFullScreenBrowserProp() {
  if (typeof document === "undefined") {
    return false;
  }

  const prefixes = [
    "fullscreen",
    "mozFullScreen",
    "msFullscreen",
    "webkitFullscreen"
  ];
  const prop = prefixes.find(
    prefix => typeof document[`${prefix}Element`] !== "undefined"
  );

  return prop ? `${prop}Element` : false;
}

export const isPastPublicationEndDate = publicationEndDate => {
  if (!publicationEndDate) return false;
  try {
    const now = new Date();

    const pubEndDate = new Date(publicationEndDate);
    // 1 hour adjustment in case of weird end-user time settings, copied from Powa: https://github.com/WPMedia/wp-powa-player/blob/main/src/js/plugins/Wapo/WapoTools.js#L505
    pubEndDate.setHours(pubEndDate.getHours() + 1);

    if (now > pubEndDate) {
      return true;
    }
  } catch (e) {
    console.error("Unable to parse publicationEndDate", e);
  }
  return false;
};

export const isPastDisplayDate = displayDate => {
  if (!displayDate) return false;
  try {
    const now = new Date();

    const displayDateDate = new Date(displayDate);
    displayDateDate.setMinutes(displayDateDate.getMinutes());

    if (now > displayDateDate) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    console.debug(e);
  }
};

export function getFullScreen(elm) {
  if (typeof document != "undefined") {
    let browserProp = getFullScreenBrowserProp();

    return elm
      ? elm.webkitDisplayingFullscreen
      : document[browserProp] != null && document[browserProp] != false;
  }
}

export function canFullScreen() {
  return !!getFullScreenBrowserProp();
}

export function elementCanFullScreen(elem) {
  return (
    elem.requestFullscreen ||
    elem.webkitEnterFullscreen ||
    elem.webkitRequestFullscreen ||
    elem.mozRequestFullScreen ||
    elem.msRequestFullscreen
  );
}

export function getAutoplayType(video, forceNoLoop = false) {
  // videos should only be given one tag related to autoplaytype
  // if a video is accidentally given multiple tags, the order of precedence should be 1) looping-promo/looping-no-controls, 2) looping-video, 3) autoplay
  const tags = video?.taxonomy?.tags?.map(x => x.text);

  // check video tags for 'looping-promo', or 'looping-no-controls'
  const isTypeLoopNoControls =
    !forceNoLoop &&
    [AutoplayTags.LOOPING_PROMO, AutoplayTags.LOOPING_NO_CONTROLS].some(x =>
      tags?.includes(x)
    );
  if (isTypeLoopNoControls) return AutoPlayType.LOOP_NO_CONTROLS;

  // check video tags for 'looping-video'
  const isTypeLoop = !forceNoLoop && tags?.includes(AutoplayTags.LOOPING_VIDEO);
  if (isTypeLoop) return AutoPlayType.LOOP;

  // Check video tags for 'autoplay'
  const isTypeAutoplay = tags?.includes(AutoplayTags.AUTOPLAY);
  if (isTypeAutoplay) return AutoPlayType.AUTO;

  return null;
}

export function isLoopingVideo(video) {
  const autoplayType = getAutoplayType(video);
  return (
    autoplayType === AutoPlayType.LOOP ||
    autoplayType === AutoPlayType.LOOP_NO_CONTROLS
  );
}

export function checkSubtitles(subtitles) {
  if (!subtitles?.urls) return false;

  return subtitles.urls.length !== 0 &&
    subtitles.urls.some(track => track.format === "WEB_VTT")
    ? true
    : false;
}

export const showFullscreenButton = ({
  isCarousel,
  isMobileSize,
  isVertical,
  isOpenSticky
}) => {
  // No fullscreen btn in the carousel vids
  if (isCarousel) return false;
  // Show fullscreen btn for horizontal vids
  if (!isVertical) return true;
  // For vertical vids, we want the fullscreen btn on mobile and in the sticky player
  return isMobileSize || isOpenSticky;
};

export function hasLiveEventEnded(video) {
  return (
    checkIfVideoEnded(video) ||
    isPastPublicationEndDate(video?.additional_properties?.publicationEndDate)
  );
}

export function isLiveEventUpcoming(video) {
  return checkIfVideoUpcoming(video) || !isPastDisplayDate(video?.display_date);
}

// Checks if a user is in the European Economic Area (EEA), meaning they are covered by the GDPR
// Copied from Powa
export function isEEA() {
  if (typeof document === "undefined") {
    return false;
  }
  const wpGeo = getCookie("wp_geo") || "";
  return wpGeo.toUpperCase().endsWith("|EEA");
}

export const isCcpaOptOut = uspString => {
  return uspString.charAt(2) === "Y";
};

/**
 * Overrides the tags on the video ANS if debug tags are passed from storybook.
 *
 * @param {string[]} debugTags - An array of debug tags to be added to the video.
 * @param {object} video - The video object to which the debug tags are to be added.
 * @returns {void}
 */
export function addTagsToVideo(debugTags = [], video) {
  if (video?.taxonomy) {
    // If debugTags are empty, use the video tags as source of truth.
    if (debugTags.length === 0) {
      debugTags = video.taxonomy?.tags?.map(x => x.text) || [];
    } else {
      const existingTags = new Set(
        video.taxonomy?.tags?.map(x => x.text) || []
      );
      const tagsToAdd = debugTags.filter(x => !existingTags.has(x));

      video.taxonomy = video.taxonomy || {};
      video.taxonomy.tags = [
        ...(video.taxonomy.tags || []),
        ...tagsToAdd.map(x => ({ text: x }))
      ];
    }
  }
}

export const getDomain = domainOverride => {
  return domainOverride
    ? domainOverride
    : typeof window !== "undefined" && window?.location?.hostname
    ? window.location.hostname
    : "washingtonpost.com";
};

/**
 * Adds an Orca event to the player's events array.
 *
 * @param {string} eventName - The name of the event to add.
 * @param {object} eventData - The data to add to the event.
 * @param {string} videoID - The ID of the video player.
 * @returns {void}
 */
export function addOrcaEvent(eventName, eventData, videoID, debug = false) {
  if (debug) {
    console.log(eventName, eventData);
  }
  if (window?.orcaPlayers) {
    const player = window.orcaPlayers[videoID];
    if (player) {
      player.events.push({ [eventName]: eventData });
    }
  }
}

/**
 * Checks if a video is live based on its status.
 *
 * @param {object} videoData - The video data object.
 * @returns {boolean} - True if the video is live, false otherwise.
 */
export const checkIfVideoLive = videoData => {
  return videoData.status === "live";
};

/**
 * Returns the video API URL and domain based on the given configuration and sandbox flag.
 *
 * @param {string} domainOverride - Optional domain override from Orca config prop
 * @param {boolean} isSandbox - Flag indicating whether the environment is a sandbox.
 * @returns {object} - An object containing the video API URL and domain.
 * @property {string} videoApiUrl - The URL of the video API.
 * @property {string} domain - The domain of the configuration.
 */
export const getVideoAPIAndDomain = (domainOverride, isSandbox) => {
  const videoApiUrl = getVideoApiUrl(isSandbox);

  const domain = getDomain(domainOverride);

  return { videoApiUrl, domain };
};

/**
 * Checks if a video is of type "live" based on its video_type property.
 *
 * @param {object} videoData - The video data object.
 * @returns {boolean} - True if the video is of type "live", false otherwise.
 */
export const checkIfVideoTypeLive = videoData => {
  return videoData.video_type === "live";
};

/**
 * Checks if a video is upcoming based on its type and status.
 *
 * @param {object} videoData - The video data object.
 * @returns {boolean} - True if the video is upcoming, false otherwise.
 */
export const checkIfVideoUpcoming = videoData => {
  return checkIfVideoTypeLive(videoData) && videoData.status === "upcoming";
};

/**
 * Checks if a video has ended based on its type and status.
 *
 * @param {object} videoData - The video data object.
 * @returns {boolean} - True if the video has ended, false otherwise.
 */
export const checkIfVideoEnded = videoData => {
  return checkIfVideoTypeLive(videoData) && videoData.status === "ended";
};

/**
 * Sets all text tracks of a video element to "hidden".
 *
 * @param {HTMLVideoElement} videoElement - The video element to set text tracks on.
 * @returns {void}
 */
export const setTextTracksToHidden = videoElement => {
  if (!videoElement.textTracks[0]) return;
  for (const track of videoElement.textTracks) {
    track.mode = "hidden";
  }
};

/**
 * Displays iOS captions on a video element.
 *
 * @param {boolean} showSubtitles - Flag indicating whether to show subtitles.
 * @param {HTMLVideoElement} videoElement - The video element to display captions on.
 * @returns {void}
 */
export const displayIOSCaptions = (showSubtitles, videoElement) => {
  setTextTracksToHidden(videoElement);
  if (showSubtitles) {
    videoElement.textTracks[0].mode = "showing";
  }
};

/**
 * Returns a boolean indicating whether subtitles are enabled on an iOS device and hides any showing subtitles.
 *
 * @param {HTMLVideoElement} videoElement - The video element to check for subtitles.
 * @returns {boolean} - True if subtitles are enabled, false otherwise.
 */
export const getSubtitlesDisplayFromIOSCaptions = videoElement => {
  let subsAreEnabled = false;
  for (const track of videoElement.textTracks) {
    if (track.mode === "showing") {
      subsAreEnabled = true;
    }
    track.mode = "hidden";
  }
  return subsAreEnabled;
};

/**
 * Retrieves the video data for a given video ID from the video API.
 *
 * @async
 * @param {object} videoApi - The video API object containing the API URL and domain.
 * @param {string} videoID - The ID of the video to retrieve data for.
 * @returns {Promise<object>} - A promise that resolves with the video data object.
 */
export const getVideoData = async (videoApi, videoID) => {
  const { videoApiUrl, domain } = videoApi;
  try {
    const response = await fetch(
      videoApiUrl + `?uuid=${videoID}&domain=${domain}`
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error retrieving video data", error);
    return null;
  }
};

// get initial isMuted value for an adTag URL param
export const getInitMuteValue = () => {
  if (typeof window !== "undefined") {
    const mutePreference = localStorage.getItem("userMutePreference");
    if (mutePreference === "true") {
      return true;
    } else if (mutePreference === "false") {
      return false;
    }
  }
  return false;
};

export const getPlayerWidth = videoElementRef =>
  videoElementRef?.current?.clientWidth;

export const getPlayerHeight = videoElementRef =>
  videoElementRef?.current?.clientHeight;

export const removeSubtitles = videoElement => {
  const tracks = videoElement.getElementsByTagName("track");
  Array.from(tracks).forEach(track => {
    track.remove();
  });
};

export const getIsStickyEnabled = (autoplayState, config) => {
  const { isSticky = true, isLead = false } = config;
  const allowSticky =
    // Looping video should never go sticky
    autoplayState.isLooping ||
    // Autoplaying video should not go sticky UNLESS in lead spot
    (autoplayState.type === AutoPlayType.AUTO && !isLead)
      ? false
      : isSticky;

  return allowSticky;
};

export const generateUniqueID = () => {
  if (typeof global.crypto !== "object") {
    global.crypto = crypto;
  }

  if (typeof global.crypto.getRandomValues !== "function") {
    global.crypto.getRandomValues = getRandomValues;
  }
  return uuidV4();
};

function getRandomValues(array) {
  return crypto.webcrypto.getRandomValues(array);
}

export const checkForForceLoad = liveConfigData => {
  return (
    !!liveConfigData?.forceAdditionalProperties?.forceLoadVideo ||
    !!liveConfigData?.forceAdditionalProperties?.youtubeVideoId
  );
};

/**
 * Shorthand for the window.performance.measure()
 *
 * @param {string} fromEvent The start event fofor the measurement
 * @param {string} toEvent The end event of the measurement
 * @returns {number|null} the duration of the event, or null
 */
export function sendPerformanceMeasure(
  eventName,
  prevEvent = null,
  debug = false,
  detail = {}
) {
  try {
    const measure = window.performance.measure(`time-to-${eventName}`, {
      start: prevEvent,
      end: eventName,
      detail: { ...detail }
    });

    if (debug) {
      console.log(measure);
    }

    // Zeus metric only take integer
    return Math.round(measure.duration);
  } catch (err) {
    return null;
  }
}

/**
 * Shorthand for the window.performance.mark()
 *
 * @param {string} eventName event name
 * @param {debug} boolean debug flag
 * @param {object} detail additional detail to be added to the performance mark
 * @returns The performance mark object
 */
export function sendPerformanceEvent(eventName, debug = false, detail = {}) {
  if (typeof window === "undefined" || window.performance.mark === undefined) {
    return;
  }
  const mark = window.performance?.mark(eventName, {
    detail: detail
  });
  if (debug) {
    console.log(mark);
  }
  return mark;
}

export const hasBurnedHeadline = video => {
  const tagsArray = video?.taxonomy?.tags;
  return tagsArray?.filter(tag => tag.text === "headline-burned").length > 0;
};

export const isExcludeVideoElement = videoData => {
  const tagsArray = videoData?.taxonomy?.tags;
  return tagsArray?.some(tag => tag.text === "excludevideoelement");
};

const IMAGE_PLACEHOLDER_FOR_VIDEO =
  "https://www.washingtonpost.com/player/prod/img/video-thumbnail-bg.png";
const PLACEHOLDER_COLOR = "rgb(245, 245, 245)";

export const getWrapperProps = props => {
  const {
    config: {
      aspectRatio = 0.5625,
      placeholder: {
        blur = false,
        image = IMAGE_PLACEHOLDER_FOR_VIDEO,
        color = PLACEHOLDER_COLOR,
        resize = true
      } = {
        blur: false,
        image: IMAGE_PLACEHOLDER_FOR_VIDEO,
        color: PLACEHOLDER_COLOR,
        resize: true
      },
      className,
      caption
    } = {
      aspectRatio: 0.5625,
      placeholder: {
        blur: false,
        image: IMAGE_PLACEHOLDER_FOR_VIDEO,
        color: PLACEHOLDER_COLOR,
        resize: true
      },
      className: "",
      caption: null
    }
  } = props;

  return {
    aspectRatio,
    placeholder: {
      image,
      color,
      resize,
      blur
    },
    className,
    caption
  };
};

import { getVideoApiUrl } from "../../lib/utils";
import { getAutoplayType } from ".";

/**
 *
 * @param {string} originalVideo - The first video played in this orca player
 * @param {boolean} isSandbox - Whether the video player is in a sandbox environment.
 * @returns {array} an array of video ANS objects
 */
export const fetchRecommendedPlaylist = async (originalVideo, isSandbox) => {
  const videoApiUrl = getVideoApiUrl(isSandbox);

  const originalVideoIsVertical = videoIsVertical(originalVideo);

  try {
    const url = new URL(videoApiUrl);
    url.search = new URLSearchParams({
      uuid: originalVideo?._id,
      recommendedPlaylist: 1
    });
    const response = await fetch(url);
    const data = await response.json();
    const filteredVideos = data
      .filter(
        video =>
          filterByMatchingOrientation(video, originalVideoIsVertical) &&
          filterOutAutoPlayingVideos(video)
      )
      // remove the first item in the api results, since it's the original video
      .slice(1);
    return filteredVideos;
  } catch (error) {
    console.error(
      `Video API request for recommended playlist for UUID = ${originalVideo?._id} failed: ${error}`
    );
  }
};

const videoIsVertical = video => {
  const { vertical = false, videoCategory = "" } = video.additional_properties;
  return vertical || videoCategory === "vertical";
};

/**
 * Filter for fetched videos to ensure that the horizontal/vertical
 * orientation of video recs matches the orientation of the original video
 * @param {Object} video - The video object to filter.
 * @param {boolean} originalVideoIsVertical - A boolean indicating whether the
 * original video is vertical.
 * @returns {boolean} - A boolean indicating whether the video matches the
 * orientation of the original video.
 */
const filterByMatchingOrientation = (video, originalVideoIsVertical) => {
  return videoIsVertical(video) === originalVideoIsVertical;
};

/**
 * Filter out auto playing videos, including looping videos
 * @param {Object} video - The video object to filter.
 * @returns {boolean} - Returns true if the video is not an autoplay video, false otherwise.
 */
const filterOutAutoPlayingVideos = video => {
  const isAutoPlayVideo = getAutoplayType(video);
  return !isAutoPlayVideo;
};

import React from "react";
import { Icon, styled } from "@washingtonpost/wpds-ui-kit";
import ChevronLeft from "@washingtonpost/wpds-assets/asset/chevron-left";
import { VideoEvents } from "../../globals";
import PropTypes from "prop-types";
import { useVideoElementRefContext } from "../../orca/contexts/VideoElementRefContext";

const GoBackButtonWrapper = styled("div", {
  width: "100%",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "flex-start"
});

const StyledGoBackButton = styled("span", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  zIndex: 999,
  color: "#FFF",
  cursor: "pointer",
  top: "$150",
  left: "15px",
  fontWeight: "$regular"
});

export const GoBackButton = ({ onClick }) => {
  const videoElementRef = useVideoElementRefContext();

  return (
    <GoBackButtonWrapper
      css={{
        display: "none",
        "@sm": {
          display: "flex"
        }
      }}
    >
      <StyledGoBackButton
        onClick={e => {
          e.stopPropagation();
          const fullscreenEvent = new Event(VideoEvents.UNFULLSCREEN);
          videoElementRef.current.dispatchEvent(fullscreenEvent);
          onClick();
        }}
      >
        <Icon size="100">
          <ChevronLeft />
        </Icon>
        &nbsp;
        <small style={{ fontWeight: "$regular" }}>
          <b>Go back</b>
        </small>
      </StyledGoBackButton>
    </GoBackButtonWrapper>
  );
};

GoBackButton.propTypes = {
  /** function that either closes full screen or closes the carousel active state */
  onClick: PropTypes.func.isRequired
};

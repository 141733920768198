import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { styled } from "@washingtonpost/wpds-ui-kit";

import { useVideoElementRefContext } from "../orca/contexts/VideoElementRefContext";
import { miniDuration } from "../../common/utils";

export const CountdownText = styled("div", {
  color: "$secondary",
  fontSize: "$087",
  userSelect: "none"
});

export function Countdown({ initDuration }) {
  const videoElementRef = useVideoElementRefContext();
  const [currentTime, setCurrentTime] = useState(
    videoElementRef.current?.currentTime || 0
  );

  useEffect(() => {
    const videoElement = videoElementRef.current;
    if (!videoElement) return;

    const handleTimeUpdate = e => {
      setCurrentTime(e.target?.currentTime);
    };

    videoElement?.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      videoElement?.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, [videoElementRef]);

  return (
    <CountdownText>{miniDuration(initDuration - currentTime)}</CountdownText>
  );
}

Countdown.propTypes = {
  initDuration: PropTypes.number.isRequired
};

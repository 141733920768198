import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer
} from "react";
import PropTypes from "prop-types";

import { useVideoDataContext } from "../../contexts/VideoDataContext";
import { VideoEvent } from "../../../lib/analytics/utils";
import { useVideoAnalyticsContext } from "../../contexts/VideoAnalyticsContext";
import { useStickyVideoContext } from "../../contexts/StickyVideoContext";
import { getAdData, getAvAdMeta } from "../utils/helpers";
import { AdState } from "../../../globals";
import { useAdsElementsRefContext } from "../../contexts/AdsElementsRefContext";

export const AdActionType = {
  RESET: "reset",
  MUTE: "mute",
  UNMUTE: "unmute",
  PLAY: "play",
  PAUSE: "pause",
  HANDLE_TIME_CHANGE: "handleTimeChange",
  SKIP_READY: "skipReady",
  SET_SHOW_CONTROLS: "setShowControls",
  SET_SHOW_COUNTDOWN: "setShowCountdown",
  AFTER_SKIP: "afterSkip"
};

/**
 * Returns a new state object with remainingTime, skipCountdown, and skipReady.
 *
 * @param {Object} state - The current state object.
 * @param {Object} action - The action object.
 * @returns {Object} A new state object with remainingTime, skipCountdown,
 * and skipReady updated.
 */
const handleTimeChange = (state, action) => {
  const { skipData } = action;
  return {
    ...state,
    remainingTime: Math.floor(skipData.remainingTime),
    skipCountdown: Math.floor(skipData.skipCountDown) + 1,
    skipReady: skipData.skipReady
  };
};

/**
 * Returns a new state object with showControls updated.
 *
 * @param {Object} state - The current state object.
 * @param {Object} action - The action object.
 * @returns {Object} A new state object with showControls updated.
 */
const setShowControls = (state, action) => {
  const { showControls } = action;
  return { ...state, showControls };
};

const setShowCountdown = (state, action) => {
  const { showCountdown } = action;
  return { ...state, showCountdown };
};

/**
 * Returns a new state object after updating the skipReady and
 * showCountdown properties.
 *
 * @param {Object} state - The current state object.
 * @returns {Object} A new state object with skipReady and
 * showCountdown properties updated.
 */
const afterSkip = state => {
  return { ...state, skipReady: false, showCountdown: null };
};

/**
 * The reducer for the ad container.
 * @param {object} state - The state of the ad container.
 * @param {string} action - The action to take.
 * @returns - The new state of the ad container.
 */
export const adControlsReducer = (state, action) => {
  const actions = {
    [AdActionType.RESET]: state => ({
      ...state,
      playing: false,
      showControls: false
    }),
    [AdActionType.MUTE]: state => ({ ...state, adMuted: true }),
    [AdActionType.UNMUTE]: state => ({ ...state, adMuted: false }),
    [AdActionType.PLAY]: state => ({ ...state, playing: true }),
    [AdActionType.PAUSE]: state => ({ ...state, playing: false }),
    [AdActionType.HANDLE_TIME_CHANGE]: handleTimeChange,
    [AdActionType.SKIP_READY]: state => ({ ...state, skipReady: true }),
    [AdActionType.SET_SHOW_CONTROLS]: setShowControls,
    [AdActionType.SET_SHOW_COUNTDOWN]: setShowCountdown,
    [AdActionType.AFTER_SKIP]: afterSkip
  };

  if (actions[action.type]) {
    return actions[action.type](state, action);
  } else {
    throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const initialState = isPreroll => {
  return {
    adMuted: !!isPreroll,
    playing: false,
    remainingTime: 0,
    showControls: false,
    showCountdown: false,
    skipCountdown: null,
    skipReady: false
  };
};

const AdControlsContext = createContext({
  adMuted: false,
  playing: false,
  remainingTime: 0,
  showControls: false,
  showCountdown: false,
  skipCountdown: null,
  skipReady: false,
  dispatchAdAction: () => {},
  mute: () => {},
  pause: () => {},
  play: () => {},
  unmute: () => {},
  visitAdvertiser: () => {}
});

export const useAdControlsContext = () => {
  return useContext(AdControlsContext);
};

export const AdControlsProvider = ({
  isPreroll,
  toggleMute = () => {},
  setAdState = () => {},
  transitionToVideo = () => {},
  adState,
  children
}) => {
  const [adLocalState, dispatchAdAction] = useReducer(
    adControlsReducer,
    initialState(isPreroll)
  );

  const { uniqueId } = useVideoDataContext();
  const { sendAnalyticsEvent } = useVideoAnalyticsContext();
  const { updateStickyContext, playingId, isStickyEnabled, isOpenSticky } =
    useStickyVideoContext();
  const { adWrapperRef } = useAdsElementsRefContext();

  const play = useCallback(() => {
    adWrapperRef.current?.irisPlayer?.play();
    sendAnalyticsEvent(VideoEvent.AD_UNPAUSE);

    if (isStickyEnabled) updateStickyContext({ playingId: uniqueId });

    dispatchAdAction({ type: AdActionType.PLAY });
  }, [
    adWrapperRef,
    isStickyEnabled,
    sendAnalyticsEvent,
    updateStickyContext,
    uniqueId
  ]);

  const pause = useCallback(() => {
    adWrapperRef.current?.irisPlayer?.pause();
    sendAnalyticsEvent(VideoEvent.AD_PAUSE);

    if (isStickyEnabled && !isOpenSticky && playingId === uniqueId) {
      updateStickyContext({ playingId: null });
    }

    dispatchAdAction({ type: AdActionType.PAUSE });
  }, [
    adWrapperRef,
    isOpenSticky,
    isStickyEnabled,
    playingId,
    sendAnalyticsEvent,
    updateStickyContext,
    uniqueId
  ]);

  const mute = useCallback(() => {
    adWrapperRef.current?.irisPlayer?.mute();
    dispatchAdAction({ type: AdActionType.MUTE });
  }, [adWrapperRef]);

  const unmute = useCallback(() => {
    adWrapperRef.current?.irisPlayer?.unmute();
    dispatchAdAction({ type: AdActionType.UNMUTE });
  }, [adWrapperRef]);

  const visitAdvertiser = useCallback(() => {
    adWrapperRef.current?.irisPlayer?.openClickThroughUrl();
  }, [adWrapperRef]);

  const togglePlay = useCallback(() => {
    if (adLocalState.playing) {
      pause();
    } else {
      play();
    }
  }, [adLocalState.playing, pause, play]);

  const toggleAdMute = useCallback(() => {
    if (adLocalState.adMuted) {
      unmute();
    } else {
      mute();
    }

    // Update video element mute state
    toggleMute();
  }, [adLocalState.adMuted, unmute, mute, toggleMute]);

  const getIrisPlayerData = useCallback(() => {
    const { current: { irisPlayer } = {} } = adWrapperRef;
    const adData = getAdData(irisPlayer);
    return { irisPlayer, adData };
  }, [adWrapperRef]);

  const reset = useCallback(() => {
    dispatchAdAction({ type: AdActionType.RESET });
  }, [dispatchAdAction]);

  const handleEarlyExit = useCallback(() => {
    setAdState(AdState.COMPLETE);
    reset();
    transitionToVideo();
  }, [reset, setAdState, transitionToVideo]);

  const skip = useCallback(() => {
    const { irisPlayer, adData } = getIrisPlayerData();

    irisPlayer.stop();

    sendAnalyticsEvent(VideoEvent.AD_SKIP, {
      avAdMeta: getAvAdMeta(adData)
    });
    handleEarlyExit();
  }, [getIrisPlayerData, sendAnalyticsEvent, handleEarlyExit]);

  // If adState is reset while ad is playing (for example, new video selected
  // in playlist), we need to close ad
  useEffect(() => {
    const { current: { irisPlayer } = {} } = adWrapperRef;

    if (adState === AdState.INIT && irisPlayer?.getCurrentAd()) {
      irisPlayer.stop();
      setAdState(AdState.COMPLETE);
      reset();
    }
  }, [adState, adWrapperRef, reset, setAdState]);

  // When another sticky media element is played, pause the ad
  useEffect(() => {
    if (playingId && playingId !== uniqueId && adLocalState.playing) {
      pause();
    }
  }, [pause, adLocalState.playing, playingId, uniqueId]);

  const memoValue = useMemo(() => {
    return {
      ...adLocalState,
      dispatchAdAction,
      mute,
      pause,
      play,
      unmute,
      visitAdvertiser,
      togglePlay,
      toggleAdMute,
      skip,
      getIrisPlayerData,
      reset
    };
  }, [
    adLocalState,
    mute,
    pause,
    play,
    unmute,
    visitAdvertiser,
    togglePlay,
    toggleAdMute,
    skip,
    getIrisPlayerData,
    reset
  ]);

  return (
    <AdControlsContext.Provider value={memoValue}>
      {children}
    </AdControlsContext.Provider>
  );
};

AdControlsProvider.propTypes = {
  adWrapperRef: PropTypes.object,
  isPreroll: PropTypes.bool,
  children: PropTypes.node,
  toggleMute: PropTypes.func,
  setAdState: PropTypes.func,
  transitionToVideo: PropTypes.func,
  adState: PropTypes.string
};

import React from "react";
import PropTypes from "prop-types";

import { styled } from "@washingtonpost/wpds-ui-kit";

const StyledButtonWrapper = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
});

export const ButtonWrapper = ({ children }) => {
  return <StyledButtonWrapper>{children}</StyledButtonWrapper>;
};

ButtonWrapper.propTypes = {
  children: PropTypes.node.isRequired
};

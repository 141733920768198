import { Box, styled } from "@washingtonpost/wpds-ui-kit";

export const Flex = styled(Box, {
  display: "flex",
  variants: {
    direction: {
      row: {
        flexDirection: "row"
      },
      column: {
        flexDirection: "column"
      }
    },
    align: {
      center: {
        alignItems: "center"
      },
      start: {
        alignItems: "flex-start"
      },
      end: {
        alignItems: "flex-end"
      }
    },
    width: {
      full: {
        width: "100%"
      }
    },
    justify: {
      center: {
        justifyContent: "center"
      },
      start: {
        justifyContent: "flex-start"
      },
      end: {
        justifyContent: "flex-end"
      }
    }
  }
});

export const TextBox = styled(Box, {
  variants: {
    size: {
      md: {
        fontSize: "$087"
      }
    },
    align: {
      right: {
        textAlign: "right"
      }
    },
    color: {
      secondary: {
        color: "$secondary"
      }
    }
  }
});

import PropTypes from "prop-types";
import React, { useState, useRef } from "react";

import { useVideoElementRefContext } from "../orca/contexts/VideoElementRefContext";
import "./audio-scrubber.css";

/**
 * Audio Scrubber component to be used in the Video Player.
 * @param {object} playerState - Player state object.
 * @param {function} handleVolumeChange - Function to handle volume change.
 * @param {function} toggleMute - Function to toggle mute.
 * @returns The Audio Scrubber component.
 */

const AudioScrubber = ({ playerState, handleVolumeChange, toggleMute }) => {
  const videoElementRef = useVideoElementRefContext();

  const { volume, isMuted } = playerState;

  const [sliderPosition, setSliderPosition] = useState(
    isNaN(videoElementRef?.current?.volume) ? 1 : videoElementRef.current.volume
  );
  const finalVolume = isMuted ? 0 : sliderPosition;

  const inputRef = useRef();

  // This function is called whenever the value of the slider is changed.
  // It updates the background of the slider to show the current value.
  const handleScrubberUpdate = e => {
    const { min, max, value } = e.target;
    const valueMin = value - min;
    const maxMin = max - min;
    e.target.style.background = `linear-gradient(to right, #fff 0%, #fff ${
      (valueMin / maxMin) * 100
    }%, #595959 ${(valueMin / maxMin) * 100}%, #595959 100%)`;
  };

  /**
   * Handles changing the volume of the video.
   * @param e - The event object.
   */
  const handleChange = e => {
    if (e.target.valueAsNumber !== volume && isMuted) {
      toggleMute();
    }
    setSliderPosition(e.target.valueAsNumber);
    handleVolumeChange(e.target.valueAsNumber);
    handleScrubberUpdate(e);
  };

  return (
    <input
      tabIndex={-1} // TODO: Figure out a way for scrubber to be used with keyboard
      aria-hidden="true"
      type="range"
      min={0}
      max={1}
      step={0.02}
      value={finalVolume}
      data-testid="volume-slider"
      ref={inputRef}
      className={"audio-scrubber"}
      onChange={e => {
        handleChange(e);
      }}
      style={{
        background: `linear-gradient(to right, #fff 0%, #fff ${
          finalVolume * 100
        }%, #595959 ${finalVolume * 100}%, #595959 100%)`
      }}
    />
  );
};

AudioScrubber.propTypes = {
  /** The player state object. */
  playerState: PropTypes.object.isRequired,
  /** The function to handle volume change. */
  handleVolumeChange: PropTypes.func.isRequired,
  /** The function to toggle mute. */
  toggleMute: PropTypes.func.isRequired
};

export default AudioScrubber;

import { styled } from "@washingtonpost/wpds-ui-kit";
import React from "react";
import PropTypes from "prop-types";
import { useStickyVideoContext } from "../contexts/StickyVideoContext";

const StyledFullScreenWrapper = styled("div", {});

export const FullScreenWrapper = ({
  fullScreenWrapper,
  controlWrapperClassName,
  isFullscreen,
  liveEventEnded,
  aspectRatio,
  children
}) => {
  const { isOpenSticky } = useStickyVideoContext();

  const isVertical = aspectRatio > 1;

  return (
    <StyledFullScreenWrapper
      data-testid="full-screen-wrapper"
      ref={fullScreenWrapper}
      className={controlWrapperClassName}
      css={{
        ...(isFullscreen && {
          display: liveEventEnded ? "none" : "flex",
          alignItems: "center"
        }),
        ...(liveEventEnded && { display: "none" }),
        ...(isOpenSticky && {
          width: "450px",
          position: "fixed",
          inset: "auto 20px 20px auto",
          top: "auto",
          right: 20,
          bottom: 20,
          left: "auto",
          zIndex: "100",
          aspectRatio: aspectRatio,
          transition: "width 0.5s ease, height 0.5s ease",
          height: isVertical
            ? `${450 * (1 / aspectRatio)}px`
            : `${450 * aspectRatio}px`,
          backgroundColor: "$primary",
          "@md": {
            width: "300px",
            height: isVertical
              ? `${300 * (1 / aspectRatio)}px`
              : `${300 * aspectRatio}px`
          },
          "@sm": {
            width: "250px",
            bottom: 150,
            height: isVertical
              ? `${250 * (1 / aspectRatio)}px`
              : `${250 * aspectRatio}px`
          }
        })
      }}
    >
      {children}
    </StyledFullScreenWrapper>
  );
};

FullScreenWrapper.propTypes = {
  fullScreenWrapper: PropTypes.object,
  controlWrapperClassName: PropTypes.string,
  isFullscreen: PropTypes.bool,
  liveEventEnded: PropTypes.bool,
  isOpenSticky: PropTypes.bool,
  aspectRatio: PropTypes.number,
  children: PropTypes.node.isRequired
};

import React, { createContext, useContext, useRef } from "react";
import PropTypes from "prop-types";

const VideoElementRefContext = createContext({
  current: null
});

export const useVideoElementRefContext = () =>
  useContext(VideoElementRefContext);

export const VideoElementRefProvider = ({ children, initialValue = null }) => {
  const videoElementRef = useRef(initialValue);

  return (
    <VideoElementRefContext.Provider value={videoElementRef}>
      {children}
    </VideoElementRefContext.Provider>
  );
};

VideoElementRefProvider.propTypes = {
  children: PropTypes.node,
  initialValue: PropTypes.any
};

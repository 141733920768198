export const IRIS_TIMEOUT = 3;

export const BLANK_CREATIVE_IDS = [
  "715998959",
  "138210079292",
  "716945960",
  "138228291976"
];

export const GAM_BASE_URL = "https://pubads.g.doubleclick.net/gampad";

import { AutoPlayType } from "../../../globals";

export const isStickyVideoOpen = (openId, playingId, uniqueId) => {
  return openId === uniqueId && playingId === uniqueId;
};

export const isOtherMediaSticky = (playingId, uniqueId, openId) => {
  return (
    // to detect if other media starting is closing the sticky player:
    (playingId !== uniqueId && playingId !== null) || // another video starts
    (playingId !== uniqueId && openId !== null) // sticky audio starts
  );
};

export const getStickyEnabled = (config, autoplayState) => {
  const { isSticky = true, isLead = false } = config;

  // Looping video should never go sticky
  return autoplayState.isLooping ||
    // Autoplaying video should not go sticky UNLESS in lead spot
    (autoplayState.type === AutoPlayType.AUTO && !isLead)
    ? false
    : isSticky;
};

export const getStickyPlayerContext = (
  stickyPlayerPropContext,
  stickyPlayerHookContext
) => {
  // sticky player context hook shared between video and audio
  const {
    playingId: hookPlayingId,
    openId: hookOpenId,
    updateContext: hookStickyContext
  } = stickyPlayerHookContext;

  // If sticky player povided as prop, override context hook
  const {
    playingId: propPlayingId,
    openId: propOpenId,
    updateContext: propUpdateContext
  } = stickyPlayerPropContext || {};

  const playingId = propPlayingId || hookPlayingId;
  const openId = propOpenId || hookOpenId;
  const updateStickyContext = propUpdateContext || hookStickyContext;

  return {
    playingId,
    openId,
    updateStickyContext
  };
};

export const shouldOpenSticky = (
  overrideStickyContext,
  isStickyEnabled,
  openId,
  playingId,
  uniqueId
) => {
  return (
    overrideStickyContext ||
    (isStickyEnabled && isStickyVideoOpen(openId, playingId, uniqueId))
  );
};

export const shouldSendStickyAnalytics = (
  isOpenSticky,
  overrideStickyContext,
  playingId,
  uniqueId,
  openId
) => {
  return (
    isOpenSticky &&
    !overrideStickyContext &&
    isOtherMediaSticky(playingId, uniqueId, openId)
  );
};

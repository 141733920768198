// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../orca-ui/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../orca-ui/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.audio-scrubber {
  -webkit-appearance: none;
  width: 100px;
  height: 4px;
  border-radius: 5px;
  cursor: pointer;
}

.audio-scrubber::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  box-shadow: 0 0 2px 0 #555;
  transition: background 0.3s ease-in-out;
}

.audio-scrubber::-moz-range-thumb {
  -webkit-appearance: none;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  box-shadow: 0 0 2px 0 #555;
  transition: background 0.3s ease-in-out;
}

input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

input[type="range"]::-moz-range-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}
`, "",{"version":3,"sources":["webpack://./../components/src/video/controls/audio-scrubber.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,wBAAwB;EACxB,WAAW;EACX,UAAU;EACV,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,0BAA0B;EAC1B,uCAAuC;AACzC;;AAEA;EACE,wBAAwB;EACxB,WAAW;EACX,UAAU;EACV,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,0BAA0B;EAC1B,uCAAuC;AACzC;;AAEA;EACE,wBAAwB;EACxB,gBAAgB;EAChB,YAAY;EACZ,uBAAuB;AACzB;;AAEA;EACE,wBAAwB;EACxB,gBAAgB;EAChB,YAAY;EACZ,uBAAuB;AACzB","sourcesContent":[".audio-scrubber {\n  -webkit-appearance: none;\n  width: 100px;\n  height: 4px;\n  border-radius: 5px;\n  cursor: pointer;\n}\n\n.audio-scrubber::-webkit-slider-thumb {\n  -webkit-appearance: none;\n  height: 8px;\n  width: 8px;\n  border-radius: 50%;\n  background: #fff;\n  cursor: pointer;\n  box-shadow: 0 0 2px 0 #555;\n  transition: background 0.3s ease-in-out;\n}\n\n.audio-scrubber::-moz-range-thumb {\n  -webkit-appearance: none;\n  height: 8px;\n  width: 8px;\n  border-radius: 50%;\n  background: #fff;\n  cursor: pointer;\n  box-shadow: 0 0 2px 0 #555;\n  transition: background 0.3s ease-in-out;\n}\n\ninput[type=\"range\"]::-webkit-slider-runnable-track {\n  -webkit-appearance: none;\n  box-shadow: none;\n  border: none;\n  background: transparent;\n}\n\ninput[type=\"range\"]::-moz-range-track {\n  -webkit-appearance: none;\n  box-shadow: none;\n  border: none;\n  background: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import { useCallback, useState } from "react";
import { IrisEvents, PerformanceEvents, PromoState } from "../../../globals";
import { usePerformanceContext } from "../../contexts/PerformanceContext";
import {
  getScript,
  listenScriptOnce,
  loadScript
} from "../../../../common/utils";
import { useAdsElementsRefContext } from "../../contexts/AdsElementsRefContext";
import { useVideoElementRefContext } from "../../contexts/VideoElementRefContext";

export const ZEUS_SCRIPT_URL = "https://www.washingtonpost.com/zeus/main.js";

export const useAdsScripts = (
  adSettings,
  setShouldShowAds,
  setAdsInitialized,
  setPromoState,
  debug,
  errorCallback
) => {
  const { debugZeus, preloadAd, isPreroll, adTagOverride, adTimeout } =
    adSettings.current;
  const { sendPerformanceEvent, sendPerformanceMeasure } =
    usePerformanceContext();
  const { adWrapperRef, adContainerRef } = useAdsElementsRefContext();
  const videoElementRef = useVideoElementRefContext();

  const getPlayerSettings = useCallback(() => {
    return {
      videoContainer: adWrapperRef.current,
      videoElement: videoElementRef.current,
      adContainer: adContainerRef.current,
      getAdTagUrl: async function () {
        if (adTagOverride) return adTagOverride;
        const url = await window.iris.buildUrl(adWrapperRef.current, {
          adSettings: adSettings.current,
          videoElement: videoElementRef.current
        });
        return url;
      },
      adSettings: {
        disableCustomPlaybackForIOS10Plus: true,
        // These settings override the default ima player UI
        adsRenderingSettings: {
          disableUi: true,
          useVideoAdUi: false,
          uiElements: []
        },
        vastLoadTimeout: adTimeout * 1000
      }
    };
  }, [
    adContainerRef,
    adWrapperRef,
    adSettings,
    adTagOverride,
    adTimeout,
    videoElementRef
  ]);

  const [isIrisReady, setIrisReady] = useState(false);

  const initializeAds = useCallback(async () => {
    try {
      const playerSettings = getPlayerSettings();
      await loadIris(playerSettings, debugZeus);
      sendPerformanceEvent(PerformanceEvents.IRIS_READY);

      sendPerformanceMeasure(
        PerformanceEvents.IRIS_READY,
        PerformanceEvents.ORCA_RENDER
      );

      if (isPreroll) {
        sendPerformanceEvent(PerformanceEvents.ORCA_READY);

        sendPerformanceMeasure(
          PerformanceEvents.ORCA_READY,
          PerformanceEvents.ORCA_RENDER,
          { irisLoaded: true }
        );
      }

      // Set up Iris player request listener
      const irisPlayerRequestPromise = new Promise(resolve => {
        adWrapperRef.current?.irisPlayer.on(IrisEvents.REQUEST, resolve);
      });

      if (preloadAd) {
        await irisPlayerRequestPromise;
      }

      setIrisReady(true);
      setAdsInitialized(true);
    } catch (e) {
      setPromoState(PromoState.READY);
      setShouldShowAds(false);
      setAdsInitialized(true);
      errorCallback?.(e);
      if (debug) console.error(e);
    }
  }, [
    adWrapperRef,
    setShouldShowAds,
    setAdsInitialized,
    debug,
    errorCallback,
    getPlayerSettings,
    isPreroll,
    preloadAd,
    sendPerformanceEvent,
    sendPerformanceMeasure,
    setPromoState,
    debugZeus
  ]);

  return { isIrisReady, initializeAds };
};

const loadIris = async (playerSettings, debugZeus) => {
  // Sync load Zeus script
  await new Promise((resolve, reject) => {
    if (window.zeus?.version) {
      resolve();
    } else {
      const zeusScript = getScript(ZEUS_SCRIPT_URL);
      if (zeusScript) {
        listenScriptOnce(zeusScript, resolve, reject);
      } else {
        loadScript(ZEUS_SCRIPT_URL, resolve, reject);
      }
    }
  });

  window.iris = window.iris || {};
  window.iris.cmd = window.iris.cmd || [];
  if (debugZeus) window.iris.imaDebugMode = true;

  const irisReadyPromise = new Promise(resolve => {
    window.iris.cmd.push(async iris => {
      await iris.setupPlayer(playerSettings);
      resolve();
    });
  });

  await Promise.race([
    // Wait for ready event to fire
    irisReadyPromise,
    // Timeout after 6 seconds
    new Promise((_, reject) => {
      setTimeout(() => {
        const error = new Error("Iris setup timed out");
        reject(error);
      }, 6000);
    })
  ]);
};

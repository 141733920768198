import React from "react";
import PropTypes from "prop-types";
import Fullscreen from "@washingtonpost/wpds-assets/asset/fullscreen";
import FullscreenExit from "@washingtonpost/wpds-assets/asset/fullscreen-exit";
import { VideoEvents } from "../../globals";
import { Icon, Button } from "@washingtonpost/wpds-ui-kit";
import { useVideoElementRefContext } from "../../orca/contexts/VideoElementRefContext";
import "./index.css";

const FullScreenButton = ({ isFullscreen, setIsFullscreen, isMiniPlayer }) => {
  const videoElementRef = useVideoElementRefContext();

  return (
    <Button
      aria-label="Expand video to full screen"
      data-testid="fullscreen-button"
      icon="center"
      className={"focus-visible-highlight"}
      variant="primary"
      css={{
        border: "none",
        background: "none",
        padding: "unset",
        "&:hover": {
          background: "none"
        }
      }}
      onClick={e => {
        e.stopPropagation();
        const fullscreenEvent = new Event(
          isFullscreen ? VideoEvents.UNFULLSCREEN : VideoEvents.FULLSCREEN
        );
        videoElementRef.current.dispatchEvent(fullscreenEvent);
        setIsFullscreen(!isFullscreen);
      }}
    >
      <Icon
        fill="white"
        size={isMiniPlayer ? "100" : "150"}
        label="full-screen"
      >
        {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
      </Icon>
    </Button>
  );
};

FullScreenButton.displayName = "FullScreenButton";

export default FullScreenButton;

FullScreenButton.propTypes = {
  isFullscreen: PropTypes.bool.isRequired,
  setIsFullscreen: PropTypes.func.isRequired,
  isMiniPlayer: PropTypes.bool.isRequired
};

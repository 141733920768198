import React from "react";
import PropTypes from "prop-types";
import { styled } from "@washingtonpost/wpds-ui-kit";
import { PlayerSize } from "../hooks/usePlayerSize";

const Curtain = styled("div", {
  backgroundColor: "rgba(0,0,0,0.7)",
  display: "flex",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "10%",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "$meta",
  color: "$secondary",
  fontSize: "$100",
  fontWeight: "$light",
  textAlign: "center",
  lineHeight: "$125"
});

const EVENT_ENDED_MSG = "This live event has ended.";
// TO DO: once Orca can playthrough to the recording, add WAITING_FOR_RECORDING_MSG

export const EventEndedCurtain = ({ playerSize }) => {
  const isSmall =
    playerSize === PlayerSize.SMALL || playerSize === PlayerSize.MINI;
  return (
    <Curtain
      css={{
        ...(isSmall && {
          height: "unset"
        })
      }}
    >
      {EVENT_ENDED_MSG}
    </Curtain>
  );
};

EventEndedCurtain.propTypes = {
  playerSize: PropTypes.string
};

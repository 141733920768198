import { useState, useEffect } from "react";

// adapted from https://github.com/WashPost/wpds-site-furniture/blob/d6038d8868c064251aa7c3e6c971a59b23e01676/packages/react-hooks/src/useReducedMotion.js
export function useReducedMotion() {
  // Use default initial value of `undefined` which indicates this value is not yet ready to be used!
  const [reducedMotion, setReducedMotion] = useState();

  useEffect(() => {
    const mediaQueryList = window.matchMedia(
      "(prefers-reduced-motion: no-preference)"
    );

    // Set the true initial value, now that we're on the client:
    setReducedMotion(
      !window.matchMedia("(prefers-reduced-motion: no-preference)").matches
    );

    // Register our event listener
    const listener = event => {
      setReducedMotion(!event.matches);
    };

    mediaQueryList.addEventListener("change", listener);

    return () => {
      mediaQueryList.removeEventListener("change", listener);
    };
  }, []);

  return reducedMotion;
}

import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";
import { isDesktop, isLargeDesktop } from "../utils/device-helper";
import { useCallback } from "react";
import isEqual from "lodash/isEqual";

const StickyPlayerContext = createContext({
  init: false,
  openId: null,
  isLoaded: false,
  playingId: null,
  isExpandable: true,
  isExpanded: false,
  handleClick: () => {},
  updateContext: () => {}
});

export const useStickyPlayerContext = () => useContext(StickyPlayerContext);

export const StickyPlayerProvider = ({ children }) => {
  let isExpanded = false;

  if ((typeof window !== "undefined" && isDesktop()) || isLargeDesktop()) {
    isExpanded = true;
    localStorage.getItem("userExpandPreference", isExpanded);
  }

  const [value, setValue] = useState({
    init: false,
    openId: null,
    isLoaded: false,
    playingId: null,
    isExpandable: true,
    isExpanded: isExpanded,
    handleClick: () => {}
  });
  const updateContext = useCallback(update => {
    setValue(current => {
      const newState = { ...current, ...update };
      return isEqual(newState, current) ? current : newState;
    });
  }, []);

  return (
    <StickyPlayerContext.Provider value={{ ...value, updateContext }}>
      {children}
    </StickyPlayerContext.Provider>
  );
};

StickyPlayerProvider.propTypes = {
  children: PropTypes.node
};

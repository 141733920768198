import React, { createContext, useCallback, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import {
  sendPerformanceEvent as sendPerfEvent,
  sendPerformanceMeasure as sendPerfMeasure
} from "../../orca/utils";
import { useAdsElementsRefContext } from "./AdsElementsRefContext";

const VideoPerformanceContext = createContext({
  sendAnalyticsEvent: () => {},
  avAdMeta: null,
  setAvAdMeta: () => {},
  adAttribute: null,
  setAdAttribute: () => {},
  firstVideoLoaded: false
});

export const usePerformanceContext = () => useContext(VideoPerformanceContext);

export const VideoPerformanceProvider = ({ children, config = {} }) => {
  const { adWrapperRef } = useAdsElementsRefContext();
  const { debug } = config;

  const sendPerformanceEvent = useCallback(
    (performanceEvent, detail) => {
      sendPerfEvent(performanceEvent, debug, {
        ...detail,
        nodeId: adWrapperRef.current.id
      });
    },
    [debug, adWrapperRef]
  );

  const sendPerformanceMeasure = useCallback(
    (performanceEvent, prevEvent, detail) => {
      sendPerfMeasure(performanceEvent, prevEvent, debug, {
        ...detail,
        nodeId: adWrapperRef.current.id
      });
    },
    [debug, adWrapperRef]
  );

  const contextValue = useMemo(
    () => ({
      sendPerformanceEvent,
      sendPerformanceMeasure
    }),
    [sendPerformanceEvent, sendPerformanceMeasure]
  );

  return (
    <VideoPerformanceContext.Provider value={contextValue}>
      {children}
    </VideoPerformanceContext.Provider>
  );
};

VideoPerformanceProvider.propTypes = {
  config: PropTypes.object,
  children: PropTypes.node
};

import React from "react";
import { Icon, styled } from "@washingtonpost/wpds-ui-kit";
import { ChevronUp } from "@washingtonpost/wpds-assets";

const SwipeUpButtonWrapper = styled("div", {
  position: "absolute",
  bottom: 0,
  fontSize: "$075",
  fontWeight: "$bold",
  width: "100%",
  marginBottom: "$050",
  "@media (min-width: 480px)": {
    display: "none"
  }
});

export const SwipeUp = () => {
  return (
    <SwipeUpButtonWrapper>
      <Icon size="150">
        <ChevronUp />
      </Icon>
      <p>Swipe up for next video</p>
    </SwipeUpButtonWrapper>
  );
};

import React from "react";
import PropTypes from "prop-types";

import { InnerWrapper, LeftControls, Text } from "./index.stitches";
import { CloseStickyPlayerButton } from "../../../controls/buttons/CloseStickyPlayerButton";
import { useStickyVideoContext } from "../../contexts/StickyVideoContext";
import { useAdControlsContext } from "../contexts/AdControlsContext";
import { complexDurationNoFraction } from "../../../../common/utils";

export const TopControls = ({
  advertiserUrl,
  closeStickyPlayer,
  isFullscreen
}) => {
  const { remainingTime } = useAdControlsContext();
  const { isOpenSticky } = useStickyVideoContext();
  return (
    <InnerWrapper position="topControls">
      <LeftControls>
        {isOpenSticky && !isFullscreen && (
          <CloseStickyPlayerButton
            buttonStyles={{ padding: "12px" }}
            closeStickyPlayer={closeStickyPlayer}
          />
        )}
        <Text
          text="duration"
          css={{
            whiteSpace: "nowrap",
            padding: "12px"
          }}
        >
          Ad ({complexDurationNoFraction(remainingTime)})
        </Text>
      </LeftControls>
      {advertiserUrl && <Text text="visitAdvertiser">Visit advertiser</Text>}
    </InnerWrapper>
  );
};

export const topControlsProps = {
  closeStickyPlayer: PropTypes.func,
  isFullscreen: PropTypes.bool
};

TopControls.propTypes = {
  advertiserUrl: PropTypes.string,
  ...topControlsProps
};

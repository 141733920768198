import { useEffect } from "react";

import { VideoEvents } from "../../globals";
import { subscribeEvent, unsubscribeEvent } from "../utils/elementEvents";
import { SPLUNK_EVENT_NAMES, SPLUNK_EVENT_TYPES } from "./globals";
import { sendVideoSplunkEvent } from "../utils/sendVideoSplunkEvent";
import { useVideoElementRefContext } from "../contexts/VideoElementRefContext";

// Sends splunk events when the video is fullscreened.
export const useSendVideoFullscreenEvents = (splunkParams, config) => {
  const videoElementRef = useVideoElementRefContext();

  const handleOnFullscreen = () => {
    sendVideoSplunkEvent(
      videoElementRef,
      SPLUNK_EVENT_TYPES.FULLSCREEN,
      SPLUNK_EVENT_NAMES.ON_FULLSCREEN,
      splunkParams,
      config
    );
  };

  const handleOnUnfullscreen = () => {
    sendVideoSplunkEvent(
      videoElementRef,
      SPLUNK_EVENT_TYPES.FULLSCREEN,
      SPLUNK_EVENT_NAMES.ON_UNFULLSCREEN,
      splunkParams,
      config
    );
  };

  useEffect(() => {
    const videoElement = videoElementRef?.current;
    if (!videoElement) return;

    subscribeEvent(videoElement, VideoEvents.FULLSCREEN, handleOnFullscreen);
    subscribeEvent(
      videoElement,
      VideoEvents.UNFULLSCREEN,
      handleOnUnfullscreen
    );

    return () => {
      unsubscribeEvent(
        videoElement,
        VideoEvents.FULLSCREEN,
        handleOnFullscreen
      );
      unsubscribeEvent(
        videoElement,
        VideoEvents.UNFULLSCREEN,
        handleOnUnfullscreen
      );
    };
  }, [videoElementRef]);
};

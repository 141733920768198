import { imageResizer } from "../../../lib/utils";
import { DEFAULT_IMAGE } from "../../../globals";

// Check whether animated promo is enabled and permissable
const getAnimatedPromo = video => {
  const readerWantsReducedMotion =
    typeof window !== "undefined"
      ? window.matchMedia(`(prefers-reduced-motion: reduce)`) === true ||
        window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true
      : false;
  return (
    !readerWantsReducedMotion && video?.additional_properties?.gifAsThumbnail
  );
};

export const getBackgroundVideo = video => {
  const animatedPromo = getAnimatedPromo(video);
  const mp4Promo = animatedPromo
    ? (video?.streams || []).filter(
        stream => stream?.stream_type === "gif-mp4"
      )[0]
    : null;

  return video?.bandito?.video || mp4Promo?.url;
};

export const getBackgroundImage = (video, placeholder) => {
  const animatedPromo = getAnimatedPromo(video);
  const gifPromo = animatedPromo
    ? (video?.streams || []).filter(stream => stream?.stream_type === "gif")[0]
    : null;
  // Set background image.
  const videoImage =
    video?.bandito?.image ||
    gifPromo?.url ||
    video?.promo_image?.url ||
    placeholder?.image ||
    DEFAULT_IMAGE;
  const isVertical = video?.additional_properties?.vertical;
  return videoImage ? imageResizer(videoImage, isVertical ? 480 : 960) : null;
};

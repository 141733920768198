import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@washingtonpost/wpds-ui-kit";

import { PlayPauseButton } from "./buttons/PlayPauseButton";
import { SubtitlesButton } from "./buttons/SubtitlesButton";
import FullScreenButton from "./buttons/FullScreenButton";
import { ShareButton } from "./buttons/ShareButton";
import { GoBackButton } from "./buttons/GoBackButton";
import { SwipeUp } from "./SwipeUp";
import { VideoScrubber } from "./VideoScrubber";
import Volume from "./Volume";
import Headline from "./Headline";
import LoadingState from "./LoadingState";
import {
  addOrcaEvent,
  hasBurnedHeadline,
  isExcludeVideoElement
} from "../orca/utils";
import { OrcaWindowEvents } from "../globals.js";
import { CloseStickyPlayerButton } from "./buttons/CloseStickyPlayerButton";
import {
  BottomControls,
  BottomControlsWrapper,
  ControlsContainer,
  ControlsWrapper,
  LeftButtonsWrapper,
  LiveText,
  LiveWrapper,
  RedDot,
  TopControls,
  TopControlsWrapper
} from "./index.stitches";
import { ButtonWrapper } from "./buttons/ButtonWrapper";
import { useVideoDataContext } from "../orca/contexts/VideoDataContext";
import { generateGradient } from "../../common/utils.js";
import { useAutoplayContext } from "../orca/contexts/AutoplayContext";
import { Countdown } from "./Countdown";
import { useStickyVideoContext } from "../orca/contexts/StickyVideoContext";

const Controls = ({
  videoID,
  isVertical,
  playerState,
  initDuration,
  toggleMute,
  togglePlay,
  canonicalUrl,
  handleVolumeChange,
  setShowSubtitles,
  isFullscreen,
  setIsFullscreen,
  hasSubtitles,
  isCarousel,
  carouselEnd,
  closeCarousel,
  isLive,
  showFullscreenBtn,
  debug,
  closeStickyPlayer,
  allowShare,
  isMiniPlayer
}) => {
  const { videoData } = useVideoDataContext();
  const { autoplayState } = useAutoplayContext();
  const { isOpenSticky } = useStickyVideoContext();

  const metaHeadline = videoData?.headlines.meta_title || "";
  const basicHeadline = videoData?.headlines.basic || "";

  const [isVisible, setIsVisible] = useState(true);
  const [showVolumeSlider, setShowVolumeSlider] = useState(false);
  const [selected, setSelected] = useState(false);
  const [persistentProgress, setPersistentProgress] = useState(0);

  const timeoutRef = useRef(null);
  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      setIsVisible(false);
    }, 3000);

    // Clear the timeout.
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const controlsContainerHeight = () => {
    return window.innerHeight ? `${window.innerHeight}px` : "100%";
  };

  const burnedHeadline = hasBurnedHeadline(videoData);
  const isExcludeVideo = isExcludeVideoElement(videoData);

  return (
    <ControlsContainer
      data-testid="orca-controls"
      tabIndex={0}
      aria-label="Video controls"
      onClick={togglePlay}
      onMouseOver={() => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        setIsVisible(true);
      }}
      onMouseLeave={() => {
        if (!selected) {
          setIsVisible(false);
        }
      }}
      onFocus={() => {
        setIsVisible(true);
      }}
      onTouchStart={() => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        setIsVisible(true);
        addOrcaEvent(OrcaWindowEvents.CONTROLS_TOUCH, true, videoID, debug);
      }}
      onTouchEnd={() => {
        timeoutRef.current = setTimeout(() => {
          setIsVisible(false);
          addOrcaEvent(OrcaWindowEvents.CONTROLS_TOUCH, false, videoID, debug);
        }, 3000);
      }}
      // Only on mobile update controls max-height
      style={{
        "@sm": {
          maxHeight: controlsContainerHeight()
        }
      }}
    >
      <LoadingState />

      {isVisible && (
        <ControlsWrapper
          css={getGradient(autoplayState.isLooping, isOpenSticky, isMiniPlayer)}
        >
          {!autoplayState.isLooping && (
            <TopControlsWrapper
              css={{
                ...(isMiniPlayer && {
                  padding: "$050"
                })
              }}
            >
              <TopControls>
                {((isFullscreen && isVertical) || isCarousel) && (
                  <GoBackButton
                    onClick={
                      isCarousel ? closeCarousel : () => setIsFullscreen(false)
                    }
                  />
                )}

                {/* Top-left button */}
                <ButtonWrapper>
                  {isOpenSticky && !isFullscreen ? (
                    <CloseStickyPlayerButton
                      closeStickyPlayer={closeStickyPlayer}
                    />
                  ) : (
                    !isMiniPlayer &&
                    allowShare &&
                    canonicalUrl &&
                    !isExcludeVideo && (
                      <ShareButton
                        headline={metaHeadline || basicHeadline}
                        canonicalUrl={canonicalUrl}
                        isVertical={isVertical}
                        setSelected={setSelected}
                      />
                    )
                  )}
                </ButtonWrapper>

                {/* Top-right button */}
                <ButtonWrapper>
                  {showFullscreenBtn && (
                    <FullScreenButton
                      isFullscreen={isFullscreen}
                      setIsFullscreen={setIsFullscreen}
                      isMiniPlayer={isMiniPlayer}
                    />
                  )}
                </ButtonWrapper>
              </TopControls>
            </TopControlsWrapper>
          )}

          <BottomControlsWrapper
            css={{
              ...(isMiniPlayer && {
                padding: "$050"
              }),
              "@sm": {
                marginBottom: isCarousel ? "$150" : "0"
              }
            }}
          >
            <BottomControls
              css={{
                gridTemplateColumns: autoplayState.isLooping
                  ? "repeat(2, 1fr)"
                  : "repeat(3, 1fr)"
              }}
            >
              {isCarousel && !burnedHeadline && (
                <Headline headline={basicHeadline} />
              )}
              {!autoplayState.isLooping && (
                <LeftButtonsWrapper>
                  {isLive ? (
                    <LiveWrapper>
                      <RedDot />
                      <LiveText
                        css={{
                          fontSize: isMiniPlayer ? "$087" : "$100"
                        }}
                      >
                        LIVE
                      </LiveText>
                    </LiveWrapper>
                  ) : (
                    isMiniPlayer && <Countdown initDuration={initDuration} />
                  )}
                  {!isMiniPlayer && (
                    <SubtitlesButton
                      {...{
                        setShowSubtitles,
                        playerState,
                        hasSubtitles
                      }}
                    />
                  )}
                </LeftButtonsWrapper>
              )}
              <Box
                css={{
                  justifySelf: !autoplayState.isLooping ? "center" : "left"
                }}
              >
                <PlayPauseButton
                  isPlaying={playerState.isPlaying}
                  isMiniPlayer={isMiniPlayer}
                />
              </Box>
              <Volume
                {...{
                  setShowVolumeSlider,
                  isVertical,
                  isMiniPlayer,
                  showVolumeSlider,
                  playerState,
                  handleVolumeChange,
                  toggleMute
                }}
              />
            </BottomControls>
            {!isMiniPlayer && !isLive && !autoplayState.isLooping && (
              <VideoScrubber
                setSelected={setSelected}
                initDuration={initDuration}
                persistentProgress={persistentProgress}
                setPersistentProgress={setPersistentProgress}
              />
            )}
          </BottomControlsWrapper>

          {isCarousel && !carouselEnd && <SwipeUp />}
        </ControlsWrapper>
      )}
    </ControlsContainer>
  );
};

Controls.propTypes = {
  /** boolean flag to denote if the video is vertical */
  isVertical: PropTypes.bool,
  /** state object generated by useVideoControls hook */
  playerState: PropTypes.object.isRequired,
  /** statically defined duration of video used to render time before playback starts */
  initDuration: PropTypes.number,
  /** a function to toggle mute */
  toggleMute: PropTypes.func.isRequired,
  /** a function to toggle play */
  togglePlay: PropTypes.func,
  /** a function to handle volume change */
  handleVolumeChange: PropTypes.func.isRequired,
  /** a function to toggle if subtitles are showing */
  setShowSubtitles: PropTypes.func.isRequired,
  /** boolean flag to denote if the video is fullscren */
  isFullscreen: PropTypes.bool.isRequired,
  /** function that sets the video's fullscreen state */
  setIsFullscreen: PropTypes.func.isRequired,
  /** The headline to pass to social media links */
  metaHeadline: PropTypes.string,
  /** The headline to pass to social media links */
  basicHeadline: PropTypes.string,
  /** The canonical url to pass to social media links */
  canonicalUrl: PropTypes.string.isRequired,
  /** Does the video have subtitles. */
  hasSubtitles: PropTypes.bool,
  /** Boolean if video is in carousel. */
  isCarousel: PropTypes.bool,
  /** If in carousel, boolean tracks if at carousel end */
  carouselEnd: PropTypes.bool,
  /** If in carousel, this callback closes carousel active state */
  closeCarousel: PropTypes.func,
  /** boolean flag to denote if the video is live */
  isLive: PropTypes.bool,
  /** should render full screen button */
  showFullscreenBtn: PropTypes.bool,
  /** The id of the video */
  videoID: PropTypes.string,
  /** boolean flag to log orca events */
  debug: PropTypes.bool,
  /** boolean flag to denote if video is currently displaying in the sticky player */
  isOpenSticky: PropTypes.bool,
  /** If video is currently open in the sticky player, this function returns it to the normal player */
  closeStickyPlayer: PropTypes.func,
  autoplayState: PropTypes.object,
  /** Whether share button is displayed */
  allowShare: PropTypes.bool,
  /** Is mini player? */
  isMiniPlayer: PropTypes.bool
};

export default Controls;

const getGradient = (isLooping, isOpenSticky, isMiniPlayer) => {
  if (isLooping) {
    return {
      background: generateGradient({
        topPercent: 0,
        bottomPercent: 25
      })
    };
  }

  if (isOpenSticky || isMiniPlayer) {
    return {
      background: generateGradient({
        topPercent: 35,
        bottomPercent: 35
      })
    };
  }

  return {
    background: generateGradient({ topPercent: 15, bottomPercent: 25 })
  };
};

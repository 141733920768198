import { getEventLabel, getSplunkStartReason, getStartReason } from "./utils";

import { joinArray } from "../../../common/utils";
import { DEFAULT_VALUE_ANALYTICS, MediaType } from "../../../common/globals";
import sendAnalytics from "../../../common/sendAnalytics";
import { AdAttribute, VideoEvents } from "../../globals";
import { dispatchNewEvent } from "../../orca/utils/elementEvents";

/**
 * sendAnalytics function is used to push to the dataLayer for video analytics events.
 * @typedef {import('./utils').VideoEvent} VideoEvent
 * @param {Object} videoData - Video data object.
 * @param {VideoEvent} eventAction - The event name that triggered the action.
 * @param {String} label - The 'label' is the player location appended to the start reason.
 * @param {Object} config - orca config information including debug and a way to mock page type for testing
 * @param {Boolean} soundMuted - Whether or not the video is muted
 * @param {Object} videoElementRef - Video element ref.
 * @param {Object} avAdMeta - Ad metadata, including length, skipAllow, and skipDelay
 * @param {String} adAttribute - Ad status (adDisabled, adStarted, adCompleted, etc.)
 * @param {string} autoplayType - autoplay type for autoplay state object
 */
const sendVideoAnalytics = ({
  videoData,
  eventAction,
  label,
  config = {},
  soundMuted,
  videoElementRef,
  avAdMeta,
  adAttribute,
  autoplayState = {},
  playerType,
  avLiveProgress
}) => {
  if (typeof window === "undefined" || !window.dataLayer || !videoData) return;
  const hasAnimatedPromo = videoData.additional_properties?.gifAsThumbnail;
  const animatedPromoPromoType = videoData.streams?.reduce(
    (acc, stream) => {
      if (stream.stream_type === "gif") {
        return { ...acc, gifPromo: true };
      }
      if (stream.stream_type === "gif-mp4") {
        return { ...acc, mp4Promo: true };
      }
      return acc;
    },
    {
      gifPromo: false,
      mp4Promo: false
    }
  );

  const getPromoType = () => {
    if (hasAnimatedPromo) {
      if (animatedPromoPromoType.mp4Promo) {
        return "mp4";
      } else if (animatedPromoPromoType.gifPromo) {
        return "gif";
      }
    }
    return "image";
  };

  const startReason = getStartReason(autoplayState, label);

  const videoDurationSeconds = videoData?.duration / 1000;

  // Create custom event for when video starts
  if (eventAction === "video-start" && videoElementRef?.current) {
    dispatchNewEvent(videoElementRef.current, VideoEvents.START, {
      startReason: getSplunkStartReason(startReason, autoplayState)
    });
  }
  sendAnalytics(
    {
      label: getEventLabel(startReason),
      event: eventAction,
      action: eventAction,
      category: "video",
      avCMS: videoData.source?.system,
      avName: videoData.headlines?.basic,
      avLength: videoDurationSeconds,
      avArcID: videoData._id,
      avSection: videoData.taxonomy?.primary_site?.name,
      avSource: videoData.source?.name,
      avType: videoData.additional_properties?.videoCategory,
      avTags: joinArray(
        videoData.taxonomy?.tags,
        ";",
        DEFAULT_VALUE_ANALYTICS,
        "text"
      ),
      keywords: videoData.taxonomy?.seo_keywords,
      avPromoType: getPromoType(),
      publishDate: videoData.display_date,
      avTestGroup: config?.testGroup,
      avPlayerType: playerType,
      hosts: videoData.credits?.host_talent,
      contributors: videoData.credits?.contributors,
      ...(config.isCarousel && {
        progressThreshold: config.carouselIndex + 1
      }),
      ...(videoElementRef?.current && {
        soundStatus: videoElementRef.current.muted ? "sound-off" : "sound-on"
      }),
      ...(soundMuted !== undefined && {
        soundStatus: soundMuted ? "sound-off" : "sound-on"
      }),
      adAttribute: config.isCarousel ? AdAttribute.DISABLED : adAttribute,
      avAdMeta: joinArray(avAdMeta, ";", DEFAULT_VALUE_ANALYTICS),
      avLiveProgress: avLiveProgress || DEFAULT_VALUE_ANALYTICS,
      ...(videoData.credits?.by?.[0]?.name
        ? {
            producers: videoData.credits?.by
          }
        : {
            avProducer:
              videoData.additional_properties?.firstPublishedBy?.name ||
              DEFAULT_VALUE_ANALYTICS
          })
    },
    config.debug,
    MediaType.VIDEO
  );
};

export default sendVideoAnalytics;

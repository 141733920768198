import React, { createContext, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import useAnalytics from "../../lib/analytics/useAnalytics";

const VideoAnalyticsContext = createContext({
  sendAnalyticsEvent: () => {},
  avAdMeta: null,
  setAvAdMeta: () => {},
  adAttribute: null,
  setAdAttribute: () => {},
  firstVideoLoaded: false
});

export const useVideoAnalyticsContext = () => useContext(VideoAnalyticsContext);

export const VideoAnalyticsProvider = ({ children, config = {} }) => {
  const {
    sendAnalyticsEvent,
    avAdMeta,
    setAvAdMeta,
    adAttribute,
    setAdAttribute,
    isAnalyticsInitialized,
    setPlayerType,
    sendVideoStartEvent
  } = useAnalytics(config);

  const contextValue = useMemo(
    () => ({
      sendAnalyticsEvent,
      avAdMeta,
      setAvAdMeta,
      adAttribute,
      setAdAttribute,
      isAnalyticsInitialized,
      setPlayerType,
      sendVideoStartEvent
    }),
    [
      sendAnalyticsEvent,
      avAdMeta,
      setAvAdMeta,
      adAttribute,
      setAdAttribute,
      isAnalyticsInitialized,
      setPlayerType,
      sendVideoStartEvent
    ]
  );

  return (
    <VideoAnalyticsContext.Provider value={contextValue}>
      {children}
    </VideoAnalyticsContext.Provider>
  );
};

VideoAnalyticsProvider.propTypes = {
  config: PropTypes.object,
  children: PropTypes.node
};

import { createRef, useCallback, useEffect, useState } from "react";
import {
  addOrcaEvent,
  closeFullscreen,
  displayIOSCaptions,
  elementCanFullScreen,
  getFullScreen,
  getSubtitlesDisplayFromIOSCaptions,
  goFullscreen,
  showFullscreenButton
} from "../utils";
import { OrcaWindowEvents } from "../../globals";
import { BreakPoints } from "../../../utils/device-helper";
import useDevice from "../../../common/hooks/useDevice";
import { useVideoDataContext } from "../contexts/VideoDataContext";
import { useVideoElementRefContext } from "../contexts/VideoElementRefContext";
import { useStickyVideoContext } from "../contexts/StickyVideoContext";

const PIP = "picture-in-picture";

/**
 * Hook that handles fullscreen functionality for a video player.
 *
 * @param {string} videoId - The ID of the video player.
 * @param {boolean} isCarousel - Whether the video player is in a carousel.
 * @param {boolean} isVertical - Whether the video is in a vertical orientation.
 * @param {function} setIsNativeIOSPlayer - Function to set whether the video
 * is using the native iOS player.
 * @param {object} videoElementRef - The reference to the video element.
 * @param {object} fullScreenWrapper - The reference to the fullscreen element.
 * @param {boolean} showSubtitles - Whether subtitles should be shown.
 * @param {function} setShowSubtitles - Function to set whether subtitles
 * @param {boolean} debug - Whether to enable debug mode.
 * @param {function} setRenderControls - Function to set whether controls render
 *
 * @returns {object} - Object containing state and functions for fullscreen
 *  functionality.
 */
const useFullscreen = (
  debug,
  fullScreenWrapper,
  isCarousel,
  isVertical,
  setIsNativeIOSPlayer,
  showSubtitles,
  setShowSubtitles,
  setRenderControls
) => {
  const videoElementRef = useVideoElementRefContext();
  const { videoId } = useVideoDataContext();
  const { isOpenSticky } = useStickyVideoContext();

  const [isFullscreen, setIsFullscreen] = useState(false);
  // Use Ref instead of state because ref updates instantly.
  const subsShowingFullscreen = createRef(true);

  /**
   * If we're in an environment where the fullscreen ref can't be opened using
   * the fullscreen api (ios safari), we need to use the native player.
   *
   * @param {object} fullscreenRef  - The reference to the fullscreen element.
   * @returns  {boolean} - Whether the video is using the native iOS player.
   */
  const shouldNativeIOSPlayer = fullscreenRef => {
    return !elementCanFullScreen(fullscreenRef) && !isCarousel;
  };

  const isMobileSize = useDevice() === BreakPoints.MOBILE;

  const showFullscreenBtn = showFullscreenButton({
    isCarousel,
    isMobileSize,
    isVertical,
    isOpenSticky
  });

  const setFullscreen = useCallback(
    fullScreen => {
      const fullscreenRef = fullScreenWrapper.current;
      if (!fullscreenRef) return;

      try {
        if (fullScreen) {
          /** Since iOS Safari wants to be unique, we have to account for
           * fullscreen and native player separately.
           */
          if (shouldNativeIOSPlayer(fullscreenRef)) {
            goFullscreen(videoElementRef.current);
            displayIOSCaptions(showSubtitles, videoElementRef.current);
            setIsFullscreen(true);
            setIsNativeIOSPlayer(true);
          } else {
            goFullscreen(fullscreenRef).then(() => {
              setIsFullscreen(true);
            });
          }
          addOrcaEvent(
            OrcaWindowEvents.FULLSCREEN_CHANGE,
            true,
            videoId,
            debug
          );
        } else {
          closeFullscreen(videoElementRef);
          setIsFullscreen(false);
          addOrcaEvent(
            OrcaWindowEvents.FULLSCREEN_CHANGE,
            false,
            videoId,
            debug
          );
        }
      } catch {
        setIsFullscreen(getFullScreen());
      }
    },
    [videoElementRef.current, fullScreenWrapper.current, showSubtitles]
  );

  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullscreen(getFullScreen());
    };

    const fullscreenRef = fullScreenWrapper.current;
    if (fullScreenWrapper && elementCanFullScreen(fullscreenRef)) {
      fullscreenRef.addEventListener(
        "fullscreenchange",
        handleFullScreenChange
      );
    }

    return () => {
      fullscreenRef.removeEventListener(
        "fullscreenchange",
        handleFullScreenChange
      );
    };
  }, [fullScreenWrapper.current]);

  useEffect(() => {
    const videoElement = videoElementRef.current;
    if (!videoElement) return;

    const handleCloseFullScreen = e => {
      /** iOS Safari treats entering the PiP mode as exiting fullscreen,
       * but Orca doesn't recognize that, so we have to handle it here.
       */
      if (e.target["webkitPresentationMode"] === PIP) {
        setIsFullscreen(false);
      } else {
        /**
         * When iOS Safari exits fullscreen without going to PiP, this code
         * handles the logic for showing the controls and subtitles.
         */
        const isFullscreen = getFullScreen(videoElement);
        setIsFullscreen(isFullscreen);
        setIsNativeIOSPlayer(isFullscreen);
        const subsAreEnabled = getSubtitlesDisplayFromIOSCaptions(videoElement);
        setShowSubtitles(subsAreEnabled);
      }
    };

    /**
     * These functions handles the hiding and showing of controls and subtitles
     * when the video enters or exits Picture-in-Picture mode.
     * If the video enters Picture-in-Picture mode,
     * the controls are hidden. Otherwise, the controls are shown.
     * https://developer.apple.com/documentation/webkitjs/htmlvideoelement
     *
     */
    const handleEnterPIP = () => {
      /**
       * If the video enters Picture-in-Picture mode, iOS treats it as leaving
       * fullscreen, so we have to hide the controls.
       */
      setRenderControls(false);
      /**
       * turn off subs when we go into pip, and track whether subs were
       * showing in the ios player so we can set the state when we return
       * inline.
       */
      const subsShowing = getSubtitlesDisplayFromIOSCaptions(videoElement);
      subsShowingFullscreen.current = subsShowing;
    };
    const handleExitPIP = e => {
      /**
       * When we return to inline player, set the subs to the state they were
       * in the ios fullscreen player.
       */
      setRenderControls(true);
      if (e.target["webkitPresentationMode"] === "inline") {
        setShowSubtitles(subsShowingFullscreen.current);
      }
    };

    videoElement.addEventListener("webkitendfullscreen", handleCloseFullScreen);
    videoElement.addEventListener("enterpictureinpicture", handleEnterPIP);
    videoElement.addEventListener("leavepictureinpicture", handleExitPIP);

    return () => {
      videoElement.removeEventListener(
        "webkitendfullscreen",
        handleCloseFullScreen
      );
      videoElement.removeEventListener("enterpictureinpicture", handleEnterPIP);
      videoElement.removeEventListener("leavepictureinpicture", handleExitPIP);
    };
  }, [videoElementRef.current]);

  return {
    isFullscreen,
    setFullscreen,
    showFullscreenBtn
  };
};

export default useFullscreen;

import { useCallback, useEffect, useMemo } from "react";
import { AdState, OrcaWindowEvents, VideoEvents } from "../../globals";
import { VideoEvent } from "../../lib/analytics/utils";
import { addOrcaEvent } from "../utils";
import { useVideoDataContext } from "../contexts/VideoDataContext";
import { useVideoElementRefContext } from "../contexts/VideoElementRefContext";
import { useVideoAnalyticsContext } from "../contexts/VideoAnalyticsContext";
import { useAutoplayContext } from "../contexts/AutoplayContext";
import { useStickyVideoContext } from "../contexts/StickyVideoContext";

import { usePlayerState } from "./useVideoControls/usePlayerState";

const useVideoControls = ({
  config,
  isCarousel,
  isNativeIOSPlayer,
  adState,
  updateAutoplayState
}) => {
  const videoElementRef = useVideoElementRefContext();
  const { videoId, videoData, uniqueId } = useVideoDataContext();
  const { autoplayState } = useAutoplayContext();
  const { sendAnalyticsEvent } = useVideoAnalyticsContext();
  const { isStickyEnabled, isOpenSticky, playingId, updateStickyContext } =
    useStickyVideoContext();

  const { forceClosedCaptionsOn = false } =
    videoData.additional_properties || {};

  // If the user has muted the video, we want to keep it muted.
  let userMutePreference = false;
  let userSubtitlesPreference = false;

  const { debug = false } = config;

  /**
   * If we're in a browser environment, we can use localStorage to persist.
   */
  if (typeof window !== "undefined") {
    userMutePreference =
      window.localStorage.getItem("userMutePreference") === "true";
    userSubtitlesPreference =
      window.localStorage.getItem("userSubtitlesPreference") === "true";
  }

  const { playerState, updatePlayerState } = usePlayerState({
    initState: {
      isStarted: false, // this indicates if the video has started playing successfully, and this remains true even if the video is paused
      isMuted: config.isMutedOverride ?? userMutePreference,
      isPlaying: false,
      volume: userMutePreference ? 0 : 1,
      showSubtitles:
        config.showSubtitlesOverride ?? forceClosedCaptionsOn
          ? true
          : userSubtitlesPreference
    },
    videoElementRef
  });

  const setVideoMuteValue = useCallback(
    value => {
      addOrcaEvent(OrcaWindowEvents.MUTE, value, videoId, debug);
      videoElementRef.current.muted = value;
    },
    [videoElementRef, debug, videoId]
  );

  const getVideoMuteValue = useCallback(
    () => videoElementRef?.current?.muted,
    [videoElementRef]
  );

  const carouselState = config?.carouselState ?? {};
  const toggleCarouselState = useMemo(
    () => config.toggleCarouselState ?? (() => {}),
    [config.toggleCarouselState]
  );

  useEffect(() => {
    const videoElement = videoElementRef.current;
    if (!videoElement) return;

    toggleCarouselState("isMuted", playerState.isMuted);
    toggleCarouselState("showSubtitles", playerState.showSubtitles);

    // Keep the video element in sync with the player state.
    if (playerState.isMuted) setVideoMuteValue(true);
  }, [videoElementRef.current]);

  // Called when user clicks custom play btns
  const onPlayButton = () => {
    playerState.isPlaying ? pause() : play();
    sendAnalyticsEvent(
      playerState.isPlaying ? VideoEvent.PAUSE : VideoEvent.RESUME
    );
    // Update sticky context to record user clicking pause
    if (
      playerState.isPlaying &&
      isStickyEnabled &&
      !isOpenSticky &&
      playingId === uniqueId
    )
      updateStickyContext({ playingId: null });
  };

  const play = useCallback(() => {
    videoElementRef.current.play();
  }, [videoElementRef]);

  const pause = useCallback(() => {
    videoElementRef.current.pause();
  }, [videoElementRef]);

  // video html element callback
  const handlePlayEvent = isPlaying => {
    // Hack to stop iris.stop() from autoplaying video when a user picks a new vid in a playlist
    if (adState === AdState.REQUESTED) {
      videoElementRef.current.pause();
    }

    updatePlayerState({ isPlaying });

    // catch play/pause event from native controls
    if (isNativeIOSPlayer && videoElementRef.current?.currentTime != 0) {
      sendAnalyticsEvent(isPlaying ? VideoEvent.RESUME : VideoEvent.PAUSE);
    }
  };

  const reset = () => {
    pause();
    videoElementRef.current.currentTime = 0;
    updatePlayerState({
      isStarted: false,
      isPlaying: false
    });
  };

  // Toggle subtitles on and off.
  const setShowSubtitles = useCallback(
    value => {
      if (isCarousel) {
        toggleCarouselState("showSubtitles", value);
      }

      updatePlayerState({
        showSubtitles: value
      });

      if (value) {
        addOrcaEvent(OrcaWindowEvents.SUBS_ENABLED, value, videoId, debug);
      } else {
        addOrcaEvent(OrcaWindowEvents.SUBS_DISABLED, value, videoId, debug);
      }

      // Update the localStorage with the user preference.
      if (typeof window !== "undefined") {
        localStorage.setItem("userSubtitlesPreference", value);
      }
    },
    [debug, isCarousel, toggleCarouselState, videoId]
  );

  const resetControlsState = useCallback(() => {
    if (!autoplayState.isLooping) {
      videoElementRef.current.currentTime = 0;
      updatePlayerState({
        isMuted: getVideoMuteValue(),
        isStarted: false,
        isPlaying: false
      });
    }
  }, [autoplayState.isLooping, videoElementRef, getVideoMuteValue]);

  /**
   * Cleanup logic for when the component using the useVideoControl hook unmounts.
   * Properly disposes of the video element for when the component is destroyed to prevent memory leaks.
   */
  useEffect(() => {
    const videoElement = videoElementRef.current;

    return () => {
      videoElement.pause();
      videoElement.src = "";
      videoElement.load();
    };
  }, []);

  const setMuteState = useCallback(
    mute => {
      setVideoMuteValue(mute);
      updatePlayerState({ isMuted: mute });

      const muteEvent = new Event(mute ? VideoEvents.MUTE : VideoEvents.UNMUTE);
      videoElementRef.current.dispatchEvent(muteEvent);

      if (isCarousel) {
        toggleCarouselState("isMuted", mute);
      }

      // Set the muted state in local storage.
      if (typeof window !== "undefined") {
        localStorage.setItem("userMutePreference", mute);
      }
      // Update the caorusel state for muted value
      toggleCarouselState("isMuted", mute);
    },
    [isCarousel, toggleCarouselState, setVideoMuteValue, videoElementRef]
  );

  /**
   * Toggle the mute state of the video.
   */
  const toggleMute = useCallback(() => {
    setMuteState(!playerState.isMuted);
  }, [playerState.isMuted, setMuteState]);

  /**
   * This function handles the volume change from the audio slider.
   * @param {volume} volume The volume to update to coming from the audio slider.
   */
  const handleVolumeChange = volume => {
    videoElementRef.current.volume = volume;
    updatePlayerState({
      volume
    });
  };

  // If html media muted changes, update playerState (ex picture in picture)
  useEffect(() => {
    updatePlayerState({
      isMuted: getVideoMuteValue()
    });
  }, [videoElementRef?.current?.muted]);

  useEffect(() => {
    if (autoplayState.isLooping) {
      videoElementRef.current?.setAttribute("loop", "");
    }
  }, [autoplayState.isLooping]);

  // If the player state is different from the carousel state,
  // update the player state to match the carousel state
  useEffect(() => {
    const { isMuted, showSubtitles } = playerState;
    if (isCarousel) {
      if (
        isMuted !== carouselState.isMuted &&
        carouselState.isMuted !== undefined
      ) {
        setVideoMuteValue(carouselState.isMuted);
        updatePlayerState({
          isMuted: carouselState.isMuted
        });
      }
      if (
        showSubtitles !== carouselState.showSubtitles &&
        carouselState.showSubtitles !== undefined
      ) {
        setShowSubtitles(carouselState.showSubtitles);
      }
    }
  }, [carouselState]);

  const handleLivePreview = useCallback(() => {
    pause();
    toggleMute();
    updateAutoplayState({ isLivePreview: false, isAutoplay: false });
    setShowSubtitles(true);
  }, [pause, setShowSubtitles, toggleMute, updateAutoplayState]);

  // when another sticky media element is played, pause the video
  useEffect(() => {
    if (
      playingId &&
      playingId !== uniqueId &&
      playerState.isPlaying &&
      isStickyEnabled
    ) {
      pause();
    }
  }, [playingId, uniqueId, playerState.isPlaying, pause, isStickyEnabled]);

  const setInitialMuteState = useCallback(
    forcePlayAd => {
      const initialMuteValue = getInitialMuteValue(
        config,
        autoplayState,
        forcePlayAd
      );
      setMuteState(initialMuteValue);
    },
    [autoplayState, config, setMuteState]
  );

  return {
    playerState,
    onPlayButton,
    play,
    pause,
    reset,
    toggleMute,
    handleVolumeChange,
    setShowSubtitles,
    handlePlayEvent,
    resetControlsState,
    handleLivePreview,
    setInitialMuteState
  };
};

export default useVideoControls;

const getInitialMuteValue = (config, autoplayState, forcePlayAd) => {
  if (typeof window === "undefined") return true;

  const isAutoplayMute =
    autoplayState.isAutoplay &&
    !forcePlayAd &&
    !autoplayState.overrideAutoplayMuteBehavior;
  if (isAutoplayMute) return true;

  const isMutedOverride = config.isMutedOverride;

  const userMutePreference =
    window.localStorage.getItem("userMutePreference") === "true";

  return isMutedOverride ?? userMutePreference;
};

/* eslint-disable react/prop-types */
import React, { createContext, useContext, useReducer, useMemo } from "react";
import PropTypes from "prop-types";
import {
  setInitialVideoState,
  useVideoApi,
  videoApiReducer
} from "../hooks/useVideoApi";
import { generateUniqueID, getVideoAPIAndDomain } from "../utils";

const VideoDataContext = createContext({
  videoId: null,
  videoData: null,
  originalVideo: null,
  videoApiDataFailed: false,
  videoApiUrl: null,
  domain: null,
  dispatchVideoApiEvent: () => {}
});

export const useVideoDataContext = () => useContext(VideoDataContext);

export const VideoDataProvider = ({
  uuid,
  video: initialVideoData,
  isSandbox,
  config = {},
  orcaRef,
  children
}) => {
  const { adOverrides: { domain: domainOverride } = {} } = config;

  const videoProp = useMemo(() => {
    return initialVideoData || {};
  }, [initialVideoData]);

  const { videoApiUrl, domain } = useMemo(() => {
    return getVideoAPIAndDomain(domainOverride, isSandbox);
  }, [domainOverride, isSandbox]);

  const [videoDataState, dispatchVideoApiEvent] = useReducer(
    videoApiReducer,
    setInitialVideoState(videoProp)
  );

  const {
    originalVideo,
    videoApiDataFailed,
    videoData,
    fetchedByUUID,
    isForceLoadVideo
  } = videoDataState;

  // Fetch video data
  useVideoApi(
    dispatchVideoApiEvent,
    domain,
    originalVideo,
    uuid,
    videoApiUrl,
    videoData,
    videoProp,
    config,
    orcaRef
  );

  const videoId = useMemo(() => {
    return videoData?._id ? videoData._id : uuid;
  }, [videoData?._id, uuid]);

  // Used in cases where we need to differentiate 2 videos w/ the same id on one page
  const uniqueId = useMemo(() => {
    return generateUniqueID();
  }, []);

  const contextValue = useMemo(
    () => ({
      originalVideo,
      videoApiDataFailed,
      videoData,
      videoId,
      dispatchVideoApiEvent,
      videoApiUrl,
      domain,
      fetchedByUUID,
      uniqueId,
      isForceLoadVideo
    }),
    [
      domain,
      originalVideo,
      videoApiDataFailed,
      videoApiUrl,
      videoData,
      videoId,
      fetchedByUUID,
      uniqueId,
      isForceLoadVideo
    ]
  );

  return (
    <VideoDataContext.Provider value={contextValue}>
      {children}
    </VideoDataContext.Provider>
  );
};

VideoDataProvider.propTypes = {
  video: PropTypes.object,
  domainOverride: PropTypes.string,
  isSandbox: PropTypes.bool,
  debug: PropTypes.bool,
  uuid: PropTypes.string,
  children: PropTypes.node
};

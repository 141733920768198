import { useCallback, useEffect, useRef, useState } from "react";
import {
  AdAttribute,
  AutoPlayType,
  PageTypes,
  VideoEvents
} from "../../globals";

import sendVideoAnalytics from "./sendVideoAnalytics";
import { getPageType, getPlayerType, VideoEvent } from "./utils";
import { useVideoDataContext } from "../../orca/contexts/VideoDataContext";
import { useVideoElementRefContext } from "../../orca/contexts/VideoElementRefContext";
import { useAutoplayContext } from "../../orca/contexts/AutoplayContext";
import {
  isAdAllowed,
  isAdDisabledOnPlayer
} from "../../orca/ads/utils/helpers";

export default function useAnalytics(config) {
  const videoElementRef = useVideoElementRefContext();
  const { videoData } = useVideoDataContext();
  const { autoplayState } = useAutoplayContext();

  const { isCarousel, carouselIndex, debug, testGroup } = config;

  const [avAdMeta, setAvAdMeta] = useState([]);
  const [adAttribute, setAdAttribute] = useState("undefined");
  const [isAnalyticsInitialized, setAnalyticsInitialized] = useState(false);
  const [playerType, setPlayerType] = useState(getPlayerType(config));
  const quartile = useRef(0);

  const videoElement = videoElementRef.current;
  const src = videoElement?.currentSrc;

  const sendAnalyticsEvent = useCallback(
    (eventAction, data = {}) => {
      sendVideoAnalytics({
        videoData,
        eventAction,
        config,
        videoElementRef,
        avAdMeta,
        adAttribute,
        autoplayState,
        playerType,
        ...data
      });
    },
    [
      videoData,
      config,
      videoElementRef,
      avAdMeta,
      adAttribute,
      autoplayState,
      playerType
    ]
  );

  useEffect(() => {
    if (
      isAnalyticsInitialized ||
      !videoData._id ||
      !autoplayState.isInitialized
    )
      return;

    window.dataLayer = window.dataLayer || [];

    // Send first impression, videos are lazy-loaded in Spectrum, so it should send once scrolled to.
    // impression event should fire once per video player (and not fire again for new videos on playthrough)
    if (!isCarousel) {
      sendAnalyticsEvent(VideoEvent.IMPRESSION, {
        // autoplaying videos will always  start muted
        soundMuted: autoplayState.isAutoplay ? true : undefined
      });
    }

    if (!isAdAllowed(videoData, config)) {
      setAdAttribute(AdAttribute.NOT_ALLOWED);
    } else if (isAdDisabledOnPlayer(config)) {
      setAdAttribute(AdAttribute.DISABLED);
    }

    setAnalyticsInitialized(true);
  }, [
    videoData?._id,
    autoplayState,
    isAnalyticsInitialized,
    isCarousel,
    sendAnalyticsEvent
  ]);

  useEffect(() => {
    if (!src || !videoElement || !autoplayState.isInitialized) return;

    window.dataLayer = window.dataLayer || [];

    const interval = 5;
    let progress = interval;

    const timeupdated = e => {
      if ((e.target?.currentTime / e.target.duration) * 100 >= progress) {
        if (progress >= 25 && quartile.current < 25) {
          quartile.current = 25;
          sendAnalyticsEvent(VideoEvent.QUARTILE_25);
        }
        if (progress >= 50 && quartile.current < 50) {
          quartile.current = 50;
          sendAnalyticsEvent(VideoEvent.QUARTILE_50);
        }
        if (progress >= 75 && quartile.current < 75) {
          quartile.current = 75;
          sendAnalyticsEvent(VideoEvent.QUARTILE_75);
        }
        progress += interval;
      }
    };
    videoElement.addEventListener(VideoEvents.TIME_UPDATE, timeupdated);

    return () => {
      videoElement.removeEventListener(VideoEvents.TIME_UPDATE, timeupdated);
    };
  }, [
    videoElement,
    src,
    isCarousel,
    carouselIndex,
    debug,
    playerType,
    testGroup,
    autoplayState,
    sendAnalyticsEvent
  ]);

  const sendVideoStartEvent = (autoplayState, analyticsData = {}) => {
    // for live preview videos, we send an autoplay event on impression
    if (autoplayState.isLivePreview) {
      sendAnalyticsEvent(VideoEvent.AUTOPLAY);
      return;
    }

    if (!autoplayState.isAutoplay) {
      sendAnalyticsEvent(VideoEvent.START, { ...analyticsData });
      return;
    }

    const AutoplayEventMap = {
      [PageTypes.ARTICLE]: VideoEvent.START,
      [PageTypes.HOME]: VideoEvent.AUTOPLAY
    };

    // We set a different value for adAttribute depending on autoplay type
    const AdAttributeMap = {
      [AutoPlayType.AUTO]: AdAttribute.DISABLED,
      [AutoPlayType.LOOP]: AdAttribute.NOT_SUPPORTED,
      [AutoPlayType.LOOP_NO_CONTROLS]: AdAttribute.NOT_SUPPORTED
    };

    const pageType = getPageType();
    const event = AutoplayEventMap[pageType] || VideoEvent.START;
    const adAttribute =
      analyticsData.adAttribute || AdAttributeMap[autoplayState.type];
    setAdAttribute(adAttribute);

    sendAnalyticsEvent(event, { adAttribute });
  };

  return {
    sendAnalyticsEvent,
    avAdMeta,
    setAvAdMeta,
    adAttribute,
    setAdAttribute,
    isAnalyticsInitialized,
    setPlayerType,
    sendVideoStartEvent
  };
}

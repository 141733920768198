import debounce from "lodash/debounce";

// This is the URL from video-analytics, but it should be safe to reuse it.
// https://docs.splunk.com/Documentation/Splunk/9.1.0/Data/UsetheHTTPEventCollector
const HEC_URL = "https://d1get58iwmjrxx.cloudfront.net/collector";

// Wait 10 seconds before sending events to Splunk. Don't want to overload it.
const DEBOUNCE_INTERVAL = 10000;

// Alternatively, if there are 10 events queued, go ahead and send.
const FLUSH_ITEMS = 10;

// Post to our Http Event Collector
function postToHEC({ postBody }) {
  fetch(HEC_URL, {
    method: "POST",
    body: postBody
  })
    // eslint-disable-next-line no-unused-vars
    .then(response => {
      //console.debug(response);
    })
    .catch(err => {
      console.error(err);
    });
}

const queue = [];
function addToQueue(obj) {
  queue.push(obj);
}

function flushQueue() {
  // grab up to FLUSH_ITEMS events
  const events = [];
  for (let i = 0; queue.length > 0 && i < FLUSH_ITEMS; i++) {
    let { name, data } = queue.pop();
    events.push({ name, data });
  }

  const postBody = getPostBody(events);
  postToHEC({ postBody });

  // in case there are more events left, schedule another debounced call
  if (queue.length) flushQueueDebounced();
}

const flushQueueDebounced = debounce(flushQueue, DEBOUNCE_INTERVAL, {
  maxWait: DEBOUNCE_INTERVAL
});

export default class splunk {
  /**
   * Send an event to Splunk.
   * @param name event name
   * @param params other information to send along with the event
   */
  static send(name, params) {
    // extend params with event name and event time
    const data = Object.assign(
      {
        evtName: name,
        evtTime: Date.now()
      },
      params
    );

    addToQueue({ name, data });

    flushQueueDebounced();
  }
}

export function getPostBody(events = []) {
  let postBody = "";
  events.forEach(({ name, data }) => {
    // We need to send in this specific format for the http collector
    // See https://docs.splunk.com/Documentation/Splunk/8.0.1/Data/FormateventsforHTTPEventCollector -- look for batched data examples
    // I know it looks weird, but it's done intentionally -- it's just a series of objects, no comma or array

    const json = {
      sourcetype: "httpevent", // This value is required or the request we will recieve a 400
      event: {
        name,
        data
      }
    };
    if (data.evtTime) {
      json.time = Math.floor(data.evtTime / 1000);
    }

    postBody += JSON.stringify(json);
  });
  return postBody;
}

import React from "react";
import PropTypes from "prop-types";
import { Close } from "@washingtonpost/wpds-assets";
import { Icon, Button } from "@washingtonpost/wpds-ui-kit";

/**
 * Component which closes the sticky video player.
 * @returns A button containing the close player icon.
 */
export const CloseStickyPlayerButton = ({
  closeStickyPlayer,
  buttonStyles
}) => {
  return (
    <Button
      data-testid="close-sticky-button"
      icon="center"
      css={{
        border: "none",
        background: "none",
        padding: "0px",
        "&:hover": {
          background: "none"
        },
        ...(buttonStyles && { ...buttonStyles })
      }}
      onClick={e => {
        e.stopPropagation();
        closeStickyPlayer();
      }}
    >
      <Icon fill="white" size="150" label="close-player">
        <Close />
      </Icon>
    </Button>
  );
};

CloseStickyPlayerButton.displayName = "CloseStickyPlayerButton";

CloseStickyPlayerButton.propTypes = {
  /* callback function to close sticky player */
  closeStickyPlayer: PropTypes.func,
  buttonStyles: PropTypes.object
};

export const SIZES = {
  small: 400,
  medium: 700
};

export const DEFAULT_VIDEO_THUMBNAIL =
  "https://www.washingtonpost.com/wp-apps/imrs.php?src=https%3A%2F%2Fwww.washingtonpost.com%2Fplayer%2Fprod%2Fimg%2Fwp_grey.jpg&w=960";

export const DEFAULT_IMAGE =
  "https://www.washingtonpost.com/player/prod/img/video-thumbnail-bg.png";

export const DEFAULT_VERTICAL_IMAGE =
  "https://www.washingtonpost.com/player/prod/img/vertical-video-thumbnail.png";

export const RESIZER_PROXY = "https://www.washingtonpost.com/wp-apps/imrs.php";

export const BASE_URL = "https://www.washingtonpost.com";

export const BASE_VIDEO_API_URL = {
  // Video API requested through CloudFront CDN (alternate domain name): https://us-east-1.console.aws.amazon.com/cloudfront/v3/home?region=us-east-1#/distributions/E3HUW6ZK43LBOV
  PROD: "https://video-api.washingtonpost.com/api/v1/ansvideos/findByUuid",
  // CloudFront CDN for Sandbox video api: https://us-east-1.console.aws.amazon.com/cloudfront/v3/home?region=us-east-1#/distributions/E1N4TB49Y3RMRZ
  SANDBOX: "https://d2r02me222rgby.cloudfront.net/api/v1/ansvideos/findByUuid"
};

export const AutoPlayStatus = {
  INIT: "INIT",
  READY: "READY",
  COMPLETE: "COMPLETE"
};

export const OrcaWindowEvents = {
  CONTROLS_TOUCH: "controlsTouch",
  CONTROLS_VISIBLE: "controlsVisible",
  RESET_CAROUSEL_VIDEO: "resetCarouselVideo",
  FIRST_CAROUSEL_VIDEO: "firstCarouselVideo",
  PLAY: "play",
  PAUSE: "pause",
  VOLUME_CHANGE: "volumeChange",
  VIDEO_API_DATA: "videoApiData",
  VIDEO_API_ERROR: "videoApiError",
  FULLSCREEN_CHANGE: "fullscreenChange",
  ALLOW_PLAYTHROUGH: "allowPlaythrough",
  SUBS_AVAILABLE: "subsAvailable",
  SUBS_UNAVAILABLE: "subsUnavailable",
  HLS_ATTACHED: "hlsAttached",
  HLS_MANIFEST_PARSED: "hlsManifestParsed",
  MUTE: "mute",
  SEEK: "seek",
  SUBS_ENABLED: "subsEnabled",
  SUBS_DISABLED: "subsDisabled",
  LOAD_VERTICAL_VIDEO_PREVIEW: "loadVerticalVideoPreview",
  LOADED_DATA: "loadedData",
  SHOW_CONTROLS: "showControls"
};

export const VideoEvents = {
  // built in video element events
  END: "ended",
  CANPLAY: "canplay",
  MUTE: "mute",
  UNMUTE: "unmute",
  PLAY: "play",
  PAUSE: "pause",
  SEEKED: "seeked",
  LOADED_DATA: "loadeddata",
  ERROR: "error",
  //custom events
  CAPTIONS: "captions",
  FULLSCREEN: "fullscreen",
  UNFULLSCREEN: "unfullscreen",
  START: "start",
  NEW_VIDEO_DATA: "NEW_VIDEO_DATA",
  AD_COMPLETE: "AD_COMPLETE",
  AD_START: "AD_START",
  AD_ERROR: "AD_ERROR",
  AD_LOADED: "AD_LOADED",
  DESTROYED: "destroyed",
  ORCA_RENDER: "orcaRender",
  TIME_UPDATE: "timeupdate"
};

export const PerformanceEvents = {
  ORCA_RENDER: "orca-render",
  ORCA_READY: "orca-ready",
  IRIS_READY: "orca-iris-ready",
  AD_START: "orca-ad-start",
  PROMO_CLICK: "orca-promo-click",
  FIRST_LINE_EXECUTION: "orca-first-line-execution"
};

export const StartReason = {
  AUTO: "autoplay",
  LOOP: "looping",
  MANUAL: "manual",
  LOOP_NO_CONTROLS: "looping-no-controls"
};

export const PageTypes = {
  HOME: "homepage",
  LEAF: "leaf",
  ARTICLE: "article page",
  INTERACTIVE: "interactive graphics",
  IFRAME: "iframe"
};

export const AutoplayTags = {
  AUTOPLAY: "autoplay",
  LOOPING_PROMO: "looping-promo",
  LOOPING_VIDEO: "looping-video",
  LOOPING_NO_CONTROLS: "looping-no-controls" // this tag is only used internally and has the same effect as the looping-promo tag
};

export const ForceTags = {
  HD: "force-hd",
  YOUTUBE: "force-youtube"
};

export const AutoplayData = {
  AUTOPLAY: "data-autoplay",
  LOOPING_PROMO: "data-looping-promo",
  LOOPING_VIDEO: "data-looping-video",
  LOOP: "data-loop"
};

export const AutoPlayType = {
  AUTO: "autoplay",
  LOOP: "loop",
  LOOP_NO_CONTROLS: "loop-no-controls"
};

export const AdState = {
  INIT: "INIT",
  REQUESTED: "REQUESTED",
  LOADING: "LOADING",
  LOADED: "LOADED",
  PLAYING: "PLAYING",
  COMPLETE: "COMPLETE",
  ERROR: "ERROR"
};

export const AdAttribute = {
  DISABLED: "adDisabled",
  ERROR: "adError",
  COMPLETE: "adCompleted",
  BLANK: "adBlank",
  STARTED: "adStarted",
  NOT_ALLOWED: "adNotAllowed",
  NOT_SUPPORTED: "adNotSupported",
  TIME_OUT: "adTimeout"
};

export const IrisEvents = {
  REQUEST: "adRequest",
  LOAD: "adLoad",
  LOADED: "adLoaded",
  START: "adStart",
  COMPLETE: "complete",
  READY: "PLAYER_READY",
  PLAY: "adPlay",
  PAUSE: "adPause",
  SKIP_READY: "skipReady",
  TIME_CHANGE: "adTimeChange",
  ERROR: "adError"
};

export const PromoState = {
  LOADING: "LOADING",
  READY: "READY",
  BUFFER: "BUFFER",
  HIDDEN: "HIDDEN"
};

export const getLiveConfigUrl = liveConfigEnv => {
  liveConfigEnv = liveConfigEnv.replace(/[^a-z0-9\s-]/gi, "");
  return `https://d2p9l91d5g68ru.cloudfront.net/LiveConfig/${liveConfigEnv}.json`;
};

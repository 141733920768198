import { closeFullscreenPromise, getFullScreen } from "../../orca/utils";

const FACEBOOK_SHARE_URL = "https://www.facebook.com/sharer/sharer.php";
const LINKEDIN_SHARE_URL = "https://www.linkedin.com/sharing/share-offsite/";
const REDDIT_SHARE_URL = "https://www.reddit.com/submit";
const TWITTER_SHARE_URL = "https://twitter.com/intent/tweet";

const FACEBOOK_TARGET = "share_facebook";
const LINKEDIN_TARGET = "share_linkedin";
const REDDIT_TARGET = "share_reddit";
const TWITTER_TARGET = "share_twitter";

const VIDEOS_NEXTJS_URL = `https://washingtonpost.com/video`;

// Opens shareUrl in a popup window
const openShareWindow = (shareUrl, target) =>
  window.open(shareUrl, target, "width=658, height=354, scrollbars=no");

// Opens shareUrl in a new tab
const openShareTab = (shareUrl, target) => window.open(shareUrl, target);

/**
 * Constructs a URL for sharing the given `url` to Facebook
 *
 * @param {{ url: string }} params
 */
export const buildFacebookShareUrl = ({ url }) => {
  const shareUrl = new URL(FACEBOOK_SHARE_URL);
  shareUrl.searchParams.append("u", url);

  return shareUrl;
};

/**
 * Opens the Facebook share dialog in a new window
 *
 * @param {URL} shareUrl
 */
export const openFacebookShareWindow = shareUrl => {
  // Check if player is fullscreen
  return getFullScreen()
    ? // If so close the full screen and open link in new tab
      closeFullscreenPromise()
        .then(() => {
          openShareTab(shareUrl, FACEBOOK_TARGET);
        })
        .catch(console.error(`Cannot open link ${FACEBOOK_SHARE_URL}`))
    : // if not full screen we can open link in a new window
      openShareWindow(shareUrl, FACEBOOK_TARGET);
};

/**
 * Constructs a URL for sharing the given `url` to Facebook and then opens it in a new window
 *
 * @param {{ url: string }} params
 */
export const shareViaFacebook = ({ url }) =>
  openFacebookShareWindow(buildFacebookShareUrl({ url }));

/**
 * Constructs a `mailto:` string for the given params
 *
 * @param {{ body?: string; subject?: string; to?: string; }} params
 */
export const buildEmailShareUrl = ({
  body = undefined,
  subject = undefined,
  to = undefined
}) => {
  let shareUrl = "mailto:";
  if (typeof to === "string") {
    shareUrl = `${shareUrl}${to}`;
  }

  const params = [
    typeof body === "string" ? `body=${encodeURIComponent(body)}` : null,
    typeof subject === "string"
      ? `subject=${encodeURIComponent(subject)}`
      : null
  ].filter(Boolean);

  if (params.length > 0) {
    shareUrl = `${shareUrl}?${params.join("&")}`;
  }

  return shareUrl;
};

/**
 * Follows the given `mailto:` url string
 *
 * @param {string} shareUrl
 */
export const openEmailShareUrl = shareUrl => {
  window.location = shareUrl;
};

/**
 * Constructs a `mailto:` string for the given params and then follows it
 *
 * @param {{ body?: string; subject?: string; to?: string; }} params
 */
export const shareViaEmail = ({
  body = undefined,
  subject = undefined,
  to = undefined
}) => openEmailShareUrl(buildEmailShareUrl({ body, subject, to }));

/**
 * Constructs a URL for sharing the given `url` to LinkedIn
 *
 * @param {{ url: string }} params
 */
export const buildLinkedInShareUrl = ({ url }) => {
  const shareUrl = new URL(LINKEDIN_SHARE_URL);
  shareUrl.searchParams.append("url", url);

  return shareUrl;
};

/**
 * Opens the LinkedIn share dialog in a new window
 *
 * @param {URL} shareUrl
 */
export const openLinkedInShareWindow = shareUrl => {
  // Check if player is fullscreen
  return getFullScreen()
    ? // If so close the full screen and open link in new tab
      closeFullscreenPromise()
        .then(() => {
          openShareTab(shareUrl, LINKEDIN_TARGET);
        })
        .catch(console.error(`Cannot open link ${LINKEDIN_SHARE_URL}`))
    : // if not full screen we can open link in a new window
      openShareWindow(shareUrl, LINKEDIN_TARGET);
};

/**
 * Constructs a URL for sharing the given `url` to LinkedIn and then opens it in a new window
 *
 * @param {{ url: string }} params
 */
export const shareViaLinkedIn = ({ url }) =>
  openLinkedInShareWindow(buildLinkedInShareUrl({ url }));

/**
 * Checks if the browser supports the Web Share API and whether or not the given
 * shareData is shareable with it
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Web_Share_API
 *
 * @param {ShareData} shareData
 */
export const canShareNatively = shareData =>
  typeof navigator === "object" &&
  typeof navigator.canShare === "function" &&
  typeof navigator.share === "function" &&
  navigator.canShare(shareData);

/**
 * Opens the browser's built-in share tool with the given shareData
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Web_Share_API
 *
 * @param {ShareData} shareData
 */
export const openNativeShareTool = shareData => navigator.share(shareData);

/**
 * Constructs a URL for sharing the given `url` to Reddit
 *
 *
 * @param {{ title?: string; url: string }} params
 */
export const buildRedditShareUrl = ({ title = undefined, url }) => {
  const shareUrl = new URL(REDDIT_SHARE_URL);
  if (typeof title === "string") {
    shareUrl.searchParams.append("title", title);
  }
  shareUrl.searchParams.append("url", url);

  return shareUrl;
};

/**
 * Opens the Reddit share dialog in a new window
 *
 * @param {URL} shareUrl
 */
export const openRedditShareWindow = shareUrl => {
  // Check if player is fullscreen
  return getFullScreen()
    ? // If so close the full screen and open link in new tab
      closeFullscreenPromise()
        .then(() => {
          openShareTab(shareUrl, REDDIT_TARGET);
        })
        .catch(console.error(`Cannot open link ${REDDIT_SHARE_URL}`))
    : // if not full screen we can open link in a new window
      openShareWindow(shareUrl, REDDIT_TARGET);
};

/**
 * Constructs a URL for sharing the given `url` to Reddit and then opens it in a new window
 *
 * @param {{ title?: string; url: string }} params
 */
export const shareViaReddit = ({ title = undefined, url }) =>
  openRedditShareWindow(buildRedditShareUrl({ title, url }));

/**
 * Constructs a URL for sharing the given `url` to Twitter
 *
 * @param {{ text?: string; url: string }} params
 */
export const buildTwitterShareUrl = ({ text = undefined, url }) => {
  const shareUrl = new URL(TWITTER_SHARE_URL);
  if (typeof text === "string") {
    shareUrl.searchParams.append("text", text);
  }
  shareUrl.searchParams.append("url", url);

  return shareUrl;
};

/**
 * Opens the Twitter share dialog in a new window
 *
 * @param {URL} shareUrl
 */
export const openTwitterShareWindow = shareUrl => {
  // Check if player is fullscreen
  return getFullScreen()
    ? // If so close the full screen and open link in new tab
      closeFullscreenPromise()
        .then(() => {
          openShareTab(shareUrl, TWITTER_TARGET);
        })
        .catch(console.error(`Cannot open link ${TWITTER_SHARE_URL}`))
    : // if not full screen we can open link in a new window
      openShareWindow(shareUrl, TWITTER_TARGET);
};

/**
 * Constructs a URL for sharing the given `url` to Twitter and then opens it in a new window
 *
 * @param {{ text?: string; url: string }} params
 */
export const shareViaTwitter = ({ text = undefined, url }) =>
  openTwitterShareWindow(buildTwitterShareUrl({ text, url }));

export const generateEmbedCode = (url, isVertical) => {
  const getUUID = url => {
    const chunks = url.split("/");
    const uuid = chunks[chunks.length - 1].split("_")[0];
    return uuid;
  };
  return `<iframe width='${isVertical ? 290 : 480}' height='${
    isVertical ? 480 : 290
  }' src='${VIDEOS_NEXTJS_URL}/c/embed/${getUUID(
    url
  )}' style='border: none' webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`;
};

import { useEffect } from "react";

export const useUpdateDuration = (duration, initDuration, setDuration) => {
  useEffect(() => {
    if (duration !== initDuration) {
      setDuration(initDuration);
    }
  }, [duration, initDuration, setDuration]);
};

export const useUpdateProgressRef = (progress, progressRef) => {
  useEffect(() => {
    progressRef.current = progress;
  }, [progress, progressRef]);
};

export const useUpdatePersistentProgress = (
  progressRef,
  setPersistentProgress
) => {
  useEffect(() => {
    setPersistentProgress(progressRef.current);
  }, [progressRef, setPersistentProgress]);
};

import React, { useEffect } from "react";

import { YoutubePlayerWrapper } from "./index.stitches";
import { useVideoDataContext } from "../../contexts/VideoDataContext";
import sendVideoAnalytics from "../../../lib/analytics/sendVideoAnalytics";
import { PlayerType } from "../../../lib/analytics/utils";
import { loadScript } from "../../../../common/utils";

const YOUTUBE_API = "https://www.youtube.com/iframe_api";

const promiseYoutubeAPI = () => {
  return new Promise(resolve => {
    if (window.YT) {
      window.YT.ready(resolve);
    } else {
      new Promise(resolve => {
        loadScript(YOUTUBE_API, resolve);
      }).then(() => {
        window.YT.ready(resolve);
      });
    }
  });
};

const youtubeAutoplayTagInVideoData = videoData => {
  return videoData.taxonomy?.tags
    .map(tag => tag.text)
    .includes("youtube-allow-autoplay");
};

export const YoutubePlayer = () => {
  const { videoData, uniqueId } = useVideoDataContext();
  const elementId = `${uniqueId}-yt`;
  const youtubeVideoId = videoData.additional_properties?.youtubeVideoId;
  const autoplay = youtubeAutoplayTagInVideoData(videoData);

  useEffect(() => {
    promiseYoutubeAPI().then(() => {
      new window.YT.Player(elementId, {
        videoId: youtubeVideoId,
        // Settings taken directly from Powa
        // See docs: https://developers.google.com/youtube/player_parameters
        playerVars: {
          autoplay: autoplay ? 1 : 0,
          iv_load_policy: 3,
          mute: autoplay ? 1 : 0,
          rel: 0,
          playsinline: 1
        },
        events: {
          onReady: () => {
            sendVideoAnalytics({
              videoData,
              eventAction: "force-youtube-render",
              action: "force-youtube-render",
              label: PlayerType.YOUTUBE,
              config: { playerType: PlayerType.YOUTUBE }
            });
          }
          // TODO: Confirm if we also want to track analytics for forced live Youtube embeds every 60 seconds on the homepage.
          // https://github.com/WPMedia/video-analytics/blob/f03a3cf705925ba9322436ec88c58345f0ae2d95/src/events/event400.js
          // https://github.com/WPMedia/wp-powa-player/blob/3131436a388457d59f2b84ce349a2b4d44ab2c41/src/js/plugins/Wapo/WapoUpYouTube.js#L134-L142
          // onStateChange: () => {}
        }
      });
    });
  }, []);

  return (
    <YoutubePlayerWrapper>
      <div id={elementId} />
    </YoutubePlayerWrapper>
  );
};

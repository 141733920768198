// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../orca-ui/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../orca-ui/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.focus-visible-highlight:focus-visible {
  outline: 1px auto Highlight;
  outline: 1px auto -webkit-focus-ring-color;
}
`, "",{"version":3,"sources":["webpack://./../components/src/video/controls/buttons/index.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,0CAA0C;AAC5C","sourcesContent":[".focus-visible-highlight:focus-visible {\n  outline: 1px auto Highlight;\n  outline: 1px auto -webkit-focus-ring-color;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

const { useEffect, useCallback } = require("react");

const WALL_NAMES = ["paywall", "regwall", "iab_banner"];
const MEDIA_NAMES = ["audio", "video"];

export function useDisplayFramework({ ref, type, pauseSelf, handleWalls }) {
  useEffect(() => {
    // Wait until user ready to pause self
    if (!pauseSelf) return;

    window.registerDisplay?.({
      participant: MEDIA_NAMES,
      callback: participant => {
        const { state, ref: _ref } = participant?.info || {};
        const isMediaStart = state === "media-start";
        const isOtherMedia = _ref !== ref;

        if (isMediaStart && isOtherMedia) {
          pauseSelf();
        }
      }
    });
  }, [pauseSelf]);

  useEffect(() => {
    // Wait until user ready to handle walls
    if (!handleWalls) return;

    let walls = {};

    window.registerDisplay?.({
      participant: WALL_NAMES,
      callback: (participant = {}) => {
        const { name, readyDisplay } = participant;
        walls[name] = readyDisplay;
        // Wait for all walls to respond before handling
        if (Object.keys(walls).length >= WALL_NAMES.length) {
          handleWalls(walls);
        }
      }
    });
  }, [handleWalls]);

  const updateDisplay = useCallback(info => {
    // Register updated display state
    window.registerDisplay?.({
      participant: type,
      info: { ...info, ref }
    });
  }, []);

  return updateDisplay;
}

import get from "lodash/get";
import { AutoPlayType, PageTypes, StartReason } from "../../globals";

const GA_PAGE_MAPPING = {
  homepage: "homepage",
  leaf: "leaf",
  "article page": "embed",
  "interactive graphics": "graphics",
  iframe: "iframe",
  other: "embed"
};

const GA_START_MAPPING = {
  // mapping to the google analytics name for the player startReason
  continuous: "rollthrough",
  "continuous-carousel": "rollthrough-carousel",
  manual: "click",
  autoplay: "autoplay",
  looping: "looping",
  hover: "hover-carousel",
  "click-carousel": "click-carousel",
  "click-carousel-all": "click-carousel-all",
  "click-carousel-forward": "click-carousel-forward",
  "click-carousel-back": "click-carousel-back",
  "swipe-carousel": "swipe-carousel",
  "youtube-embed": "youtube"
};

const isHomePage = () => {
  return get(window, "Fusion.metas.contentType.value", "") === "homepage";
};

const isLeafPage = () => {
  // Next.js video page
  if (get(window, "pageName", "").startsWith("video-leaf-page")) {
    return true;
  }
};

const getTemplateName = () => {
  return get(window, "Fusion.template", "");
};

const isLUFPage = () => {
  if (window.pageType === "LUF") {
    return true;
  }
  const subtype = get(
    window,
    "__NEXT_DATA__.props.pageProps.globalContent.subtype"
  );
  return subtype === "highlights" || subtype === "live-all";
};

export const isArticlePage = () => {
  const templateName = getTemplateName();
  return (
    templateName.startsWith("article") ||
    templateName.startsWith("template/article") ||
    isLUFPage() // MED-3257: Treat LUF like article
  );
};

const isInteractivesPage = () => {
  return get(window, "wp_content_type", "") === "interactive graphics";
};

const isVideoIframe = () => {
  if (window.pageType === "wpvIframe") return true;

  if (
    /washingtonpost\.com\/(video|posttv)\/c\/embed/.test(window.location.href)
  ) {
    return true;
  }
};

export const getPageType = () => {
  if (typeof window !== "undefined") {
    if (isHomePage()) return PageTypes.HOME;
    if (isLeafPage()) return PageTypes.LEAF;
    if (isArticlePage()) return PageTypes.ARTICLE;
    if (isInteractivesPage()) return PageTypes.INTERACTIVE;
    if (isVideoIframe()) return PageTypes.IFRAME;
  }
  return "other";
};

export const setPageType = pageType => {
  window.Fusion = {};
  window.Fusion.metas = {};
  window.Fusion.metas.contentType = {};
  switch (pageType) {
    case PageTypes.HOME:
      window.Fusion.metas.contentType.value = "homepage";
      break;
    case PageTypes.LEAF:
      window.pageName = "video-leaf-page";
      break;
    case PageTypes.ARTICLE:
      window.Fusion.template = "article";
      break;
    default:
      break;
  }
};

/**
 * Video Event type definition
 * @typedef {Object} VideoEvent
 * @property {string} START
 * @property {string} PAUSE
 * @property {string} RESUME
 * @property {string} QUARTILE_25
 * @property {string} QUARTILE_50
 * @property {string} QUARTILE_75
 * @property {string} COMPLETE
 * @property {string} PROMO_CLICK
 * @property {string} IMPRESSION
 * @property {string} LOOPING_PROMO
 * @property {string} AUTOPLAY
 * @property {string} CAROUSEL_INTERACTION
 * @property {string} AD_PAUSE
 * @property {string} AD_UNPAUSE
 * @property {string} AD_SKIP
 * @property {string} AD_START
 * @property {string} AD_COMPLETE
 * @property {string} STICKY_PLAYER_OPEN
 * @property {string} STICKY_PLAYER_CLOSE
 * @property {string} SKIP
 * @property {string} LIVE_VIDEO_THRESHOLD
 */
export const VideoEvent = {
  START: "video-start",
  PAUSE: "video-pause",
  RESUME: "video-unpause",
  QUARTILE_25: "video-25-complete",
  QUARTILE_50: "video-50-complete",
  QUARTILE_75: "video-75-complete",
  COMPLETE: "video-complete",
  PROMO_CLICK: "video-promo-click",
  IMPRESSION: "media-video-player-impression",
  CAROUSEL_IMPRESSION: "media-carousel-impression",
  LOOPING_PROMO: "video-looping-promo",
  AUTOPLAY: "video-autoplay",
  CAROUSEL_INTERACTION: "video-carousel-interaction",
  AD_PAUSE: "video-ad-pause",
  AD_UNPAUSE: "video-ad-unpause",
  AD_SKIP: "video-ad-skip",
  AD_START: "video-ad-start",
  AD_COMPLETE: "video-ad-complete",
  STICKY_PLAYER_OPEN: "sticky-player-open",
  STICKY_PLAYER_CLOSE: "sticky-player-close",
  SKIP: "video-skip",
  LIVE_VIDEO_THRESHOLD: "live-video-threshold"
};

export const PlayerType = {
  STICKY: "orca-embed-sticky",
  EMBED_HP: "orca-embed-hp",
  EMBED_IFRAME: "orca-embed-iframe",
  LEAF: "orca-leaf",
  EMBED: "orca-embed",
  YOUTUBE: "youtube-embed",
  LIVE_BAR: "live-bar"
};

export const EventLabel = {
  MANUAL: "manual",
  HOVER: "hover",
  CLICK_CAROUSEL: "click-carousel",
  SWIPE_CAROUSEL: "swipe-carousel",
  CONTINUOUS_CAROUSEL: "continuous-carousel",
  CONTINUOUS: "continuous"
};

export const StickyPlayerEventLabel = {
  CLICK_CLOSE: "close-click",
  SCROLL_CLOSE: "close-scroll",
  OTHER_MEDIA_CLOSE: "close-other-media",
  OPEN_SCROLL: "open-scroll"
};

export const getPlayerType = config => {
  if (config?.playerTypeOverride) return config.playerTypeOverride;

  if (typeof window === "undefined") return;

  if (window.wp_meta_data?.isHomepage === true) {
    return PlayerType.EMBED_HP;
  }
  if (window.self !== window.top) {
    return PlayerType.EMBED_IFRAME;
  }
  if (window.wp_pb && /video-leaf-page/.test(window.wp_pb.pageName)) {
    return PlayerType.LEAF;
  }

  return PlayerType.EMBED;
};

export const getEventLabel = startReason => {
  // GA 'label' is player location appended to the start reason
  // e.g. click-embed, upnext-leaf, autoplay-hp
  const pageType = getPageType();

  if (pageType && startReason) {
    return `${startReason}-${get(GA_PAGE_MAPPING, pageType)}`;
  }

  return get(GA_PAGE_MAPPING, pageType);
};

function getStartReasonFromAutoplay(autoplayState) {
  if (autoplayState.isPlaythrough) return EventLabel.CONTINUOUS;
  if (autoplayState.isLooping) return StartReason.LOOP;
  if (autoplayState.isAutoplay) return StartReason.AUTO;
  return null;
}

export const getStartReason = (autoplayState, label) => {
  const playerStartReason =
    getStartReasonFromAutoplay(autoplayState) || label || StartReason.MANUAL;
  const gaStartReason = get(GA_START_MAPPING, playerStartReason);
  return gaStartReason;
};

export const getSplunkStartReason = (startReason, autoplayState) => {
  if (autoplayState.type === AutoPlayType.LOOP_NO_CONTROLS)
    return StartReason.LOOP_NO_CONTROLS;
  return startReason;
};

/**
 * Sending a click event to dataLayer object for GTM
 * @param {String} action The click event action
 * @param {String} label The click event label
 */
export const sendGAEvent = (action, label) => {
  if (typeof window === "undefined") return;
  window.dataLayer = window.dataLayer || [];
  const e = {
    event: action,
    action,
    category: "video",
    label
  };
  window.dataLayer.push(e);
};

import { useCallback, useEffect } from "react";
import { useVideoDataContext } from "../../contexts/VideoDataContext";
import {
  AdAttribute,
  AdState,
  IrisEvents,
  PerformanceEvents,
  PromoState,
  VideoEvents
} from "../../../globals";
import { addOrcaEvent } from "../../utils";
import { getAdAttribute, getAvAdMeta, getSkipData } from "../utils/helpers";
import { dispatchNewEvent } from "../../utils/elementEvents";
import { VideoEvent } from "../../../lib/analytics/utils";
import { useVideoElementRefContext } from "../../contexts/VideoElementRefContext";
import { useVideoAnalyticsContext } from "../../contexts/VideoAnalyticsContext";
import { useStickyVideoContext } from "../../contexts/StickyVideoContext";
import { usePerformanceContext } from "../../contexts/PerformanceContext";
import {
  AdActionType,
  useAdControlsContext
} from "../contexts/AdControlsContext";
import { useAdsElementsRefContext } from "../../contexts/AdsElementsRefContext";

const useIrisEventListeners = ({
  adSettings,
  debug,
  transitionToVideo,
  setAdState,
  setPromoState,
  setRenderControls,
  playerState,
  updateDisplay,
  isAdsInitialized,
  pauseVideo = () => {}
}) => {
  const videoElementRef = useVideoElementRefContext();
  const { videoData, videoId, uniqueId } = useVideoDataContext();
  const { dispatchAdAction, mute, skip, getIrisPlayerData, reset } =
    useAdControlsContext();
  const { sendAnalyticsEvent, setAvAdMeta, setAdAttribute } =
    useVideoAnalyticsContext();
  const { updateStickyContext, isStickyEnabled } = useStickyVideoContext();
  const { sendPerformanceEvent, sendPerformanceMeasure } =
    usePerformanceContext();
  const { adWrapperRef } = useAdsElementsRefContext();

  const { current: { debugZeus, isPreroll } = {} } = adSettings;
  const { isMuted } = playerState;

  const handleRequestAd = useCallback(() => {
    addOrcaEvent(IrisEvents.REQUEST, true, videoId, debug);

    const { current: { preloadAd } = {} } = adWrapperRef;

    // If the ad has already been preloaded, don't do anything.
    if (preloadAd) {
      return;
    }
  }, [videoId, debug, adWrapperRef]);

  const handleLoad = useCallback(() => {
    setAdState(AdState.LOADED);
    addOrcaEvent(IrisEvents.LOADED, true, videoId, debug);
  }, [debug, setAdState, videoId]);

  const handleStart = useCallback(() => {
    const { adData } = getIrisPlayerData();
    const { duration } = adData;

    if (debugZeus) {
      console.log("Iris Event", IrisEvents.START);
    }
    // When the ad starts, hide the promo.
    setPromoState(PromoState.HIDDEN);

    // If the video is in sticky mode, update the videoId.
    if (isStickyEnabled) {
      updateStickyContext({ playingId: uniqueId });
    }

    // Set the playing state, don't render controls, set ad state, if its muted
    // update the mute to match player.
    dispatchAdAction({ type: AdActionType.PLAY });
    setRenderControls(false);
    setAdState(AdState.PLAYING);
    if (isMuted || isPreroll) {
      mute();
    }

    // If the video tries to play at the same time as the ad, we pause so audio doesn't overlap
    pauseVideo();

    // Set countdown for skip, and send splunk event for ad start.
    dispatchAdAction({
      type: AdActionType.SET_SHOW_COUNTDOWN,
      showCountdown: duration >= 30
    });
    dispatchNewEvent(videoElementRef.current, VideoEvents.AD_START, adData);

    // Update display for walls interaction.
    updateDisplay({ state: "media-start" });

    const skipTime = adWrapperRef.current.irisPlayer?.getSkipTime();
    const adAttribute = getAdAttribute(adData);
    const avAdMeta = getAvAdMeta(adData, skipTime);
    setAvAdMeta(avAdMeta);
    setAdAttribute(adAttribute);

    if (duration > 1) {
      sendAnalyticsEvent(VideoEvent.AD_START, {
        avAdMeta,
        adAttribute
      });
      sendPerformanceEvent(PerformanceEvents.AD_START);
      sendPerformanceMeasure(
        PerformanceEvents.AD_START,
        PerformanceEvents.PROMO_CLICK
      );
      addOrcaEvent(IrisEvents.START, true, videoId, debug);
      dispatchAdAction({
        type: AdActionType.SET_SHOW_CONTROLS,
        showControls: true
      });
    }
  }, [
    getIrisPlayerData,
    debugZeus,
    setPromoState,
    isStickyEnabled,
    dispatchAdAction,
    setRenderControls,
    setAdState,
    isMuted,
    videoElementRef,
    updateDisplay,
    updateStickyContext,
    videoId,
    mute,
    debug,
    adWrapperRef,
    setAvAdMeta,
    sendAnalyticsEvent,
    pauseVideo,
    uniqueId,
    sendPerformanceEvent,
    sendPerformanceMeasure,
    setAdAttribute
  ]);

  const handlePlay = useCallback(() => {
    addOrcaEvent(IrisEvents.PLAY, true, videoId, debug);
    dispatchAdAction({ type: AdActionType.PLAY });
  }, [debug, dispatchAdAction, videoId]);

  const handlePause = useCallback(() => {
    addOrcaEvent(IrisEvents.PAUSE, true, videoId, debug);
    dispatchAdAction({ type: AdActionType.PAUSE });
  }, [debug, dispatchAdAction, videoId]);

  const handleTimeChange = useCallback(
    e => {
      const skipTimeOffset = adWrapperRef.current.irisPlayer.getSkipTime();
      const { remainingTime, currentTime } = e;
      const skipData = getSkipData(remainingTime, currentTime, skipTimeOffset); // Set the remaining time, countdown for skipping ad.
      dispatchAdAction({
        type: AdActionType.HANDLE_TIME_CHANGE,
        skipData
      });
    },
    [dispatchAdAction, adWrapperRef]
  );

  const handleSkipReady = useCallback(() => {
    dispatchAdAction({ type: AdActionType.SKIP_READY });
    addOrcaEvent(IrisEvents.SKIP_READY, true, videoId, debug);
  }, [dispatchAdAction, debug, videoId]);

  const handleError = useCallback(
    e => {
      const { adData } = getIrisPlayerData();
      addOrcaEvent(IrisEvents.ERROR, true, videoId, debug);
      setAdState(AdState.ERROR);
      reset();

      setAdAttribute(AdAttribute.ERROR);
      transitionToVideo({ adAttribute: AdAttribute.ERROR });
      dispatchNewEvent(videoElementRef.current, VideoEvents.AD_ERROR, adData);

      if (debug) {
        console.error("Failed to load ad.", e);
      }
    },
    [
      getIrisPlayerData,
      videoId,
      debug,
      setAdState,
      reset,
      setAdAttribute,
      transitionToVideo,
      videoElementRef
    ]
  );

  const handleComplete = useCallback(() => {
    const { adData } = getIrisPlayerData();
    const { duration } = adData;
    addOrcaEvent(AdAttribute.COMPLETE, true, videoId, debug);
    setAdState(AdState.COMPLETE);
    reset();

    const adAttribute = getAdAttribute(adData);

    setAdAttribute(adAttribute);
    dispatchNewEvent(videoElementRef.current, VideoEvents.AD_COMPLETE, adData);

    if (duration > 1) {
      sendAnalyticsEvent(VideoEvent.AD_COMPLETE, {
        avAdMeta: getAvAdMeta(adData),
        adAttribute
      });
    }
    transitionToVideo({ adAttribute });
  }, [
    debug,
    getIrisPlayerData,
    reset,
    sendAnalyticsEvent,
    setAdAttribute,
    setAdState,
    transitionToVideo,
    videoElementRef,
    videoId
  ]);

  useEffect(() => {
    if (!isAdsInitialized) return;

    const { irisPlayer } = adWrapperRef.current || {};

    if (!irisPlayer) return;

    irisPlayer.on(IrisEvents.REQUEST, handleRequestAd);
    irisPlayer.on(IrisEvents.LOAD, handleLoad);
    irisPlayer.on(IrisEvents.START, handleStart);
    irisPlayer.on(IrisEvents.PLAY, handlePlay);
    irisPlayer.on(IrisEvents.PAUSE, handlePause);
    irisPlayer.on(IrisEvents.TIME_CHANGE, handleTimeChange);
    irisPlayer.on(IrisEvents.SKIP_READY, handleSkipReady);
    irisPlayer.on(IrisEvents.ERROR, handleError);
    irisPlayer.on(IrisEvents.COMPLETE, handleComplete);
    return () => {
      irisPlayer.off(IrisEvents.REQUEST, handleRequestAd);
      irisPlayer.off(IrisEvents.LOAD, handleLoad);
      irisPlayer.off(IrisEvents.START, handleStart);
      irisPlayer.off(IrisEvents.PLAY, handlePlay);
      irisPlayer.off(IrisEvents.PAUSE, handlePause);
      irisPlayer.off(IrisEvents.TIME_CHANGE, handleTimeChange);
      irisPlayer.off(IrisEvents.SKIP_READY, handleSkipReady);
      irisPlayer.off(IrisEvents.ERROR, handleError);
      irisPlayer.off(IrisEvents.COMPLETE, handleComplete);
    };
  }, [
    adWrapperRef,
    handleLoad,
    handleRequestAd,
    handleStart,
    videoData,
    handlePlay,
    handleTimeChange,
    handleSkipReady,
    handleError,
    handleComplete,
    isAdsInitialized,
    handlePause
  ]);

  return skip;
};

export default useIrisEventListeners;

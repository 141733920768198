import { useEffect, useRef, useState } from "react";
import get from "lodash/get";

import { VideoEvents } from "../../globals";
import { subscribeEvent, unsubscribeEvent } from "../utils/elementEvents";
import { sendVideoSplunkEvent } from "../utils/sendVideoSplunkEvent";
import { SPLUNK_EVENT_NAMES, SPLUNK_EVENT_TYPES } from "./globals";
import { useVideoElementRefContext } from "../contexts/VideoElementRefContext";

// Sends Splunk event every 5 seconds, until 20 seconds, until player starts playing or times out.
const DEFAULT_SETTINGS = {
  timeoutInterval: 5000,
  videoTimeInterval: 1000,
  maxRuns: 4
};

export const useSendVideoTimeouts = (splunkParams, config) => {
  const videoElementRef = useVideoElementRefContext();

  const [sentOnTimeout, setSentOnTimeout] = useState(false);
  const [sentOnPlayback, setSentOnPlayback] = useState(false);
  const intervalTimeoutRef = useRef();
  const intervalTimeRef = useRef();
  let pingCount = 0;

  const checkFirstFrame = event => {
    clearInterval(intervalTimeRef.current);

    intervalTimeRef.current = setInterval(() => {
      if (videoElementRef.current?.currentTime > 0) {
        onPlayback(event);
      }
    }, DEFAULT_SETTINGS.videoTimeInterval);
  };

  const handleAdStart = event => {
    // On playthrough, the event `video-start` is triggered before the `ad-start` event is ran,
    // this makes sure we send an `eventOK` after the `video-start` event to restart the check
    // in order to avoid sending false timeout events since we dont want the `handleOnStart`
    // event to run since an ad is playing.
    onPlayback(event);
  };

  const handleOnStart = event => {
    // When triggered, this runs two intervals that checks:
    // 1. If a video is playing with `checkFirstFrame`.
    // 2. If a video is not playing, sends an `eventWaiting` event every 2 seconds for at least 16 seconds.
    // Either `checkFirstFrame` ends the interval and sends the event `eventOK` to show that a video is playing or
    // sends an `eventTimedOut` event that shows that a video is not playing and timed out.
    if (sentOnPlayback) return;

    clearInterval(intervalTimeoutRef.current);
    checkFirstFrame(event);

    intervalTimeoutRef.current = setInterval(() => {
      pingCount += 1;
      sendVideoSplunkEvent(
        videoElementRef,
        SPLUNK_EVENT_TYPES.TIMEOUT,
        SPLUNK_EVENT_NAMES.WAITING,
        {
          startReason: get(event.detail, "startReason", ""),
          startEvent: get(event, "type", ""),
          ...splunkParams
        },
        config
      );

      if (pingCount >= DEFAULT_SETTINGS.maxRuns) {
        onTimeout(event);
      }
    }, DEFAULT_SETTINGS.timeoutInterval);
  };

  const reset = () => {
    clearInterval(intervalTimeoutRef.current);
    clearInterval(intervalTimeRef.current);
    pingCount = 0;
  };

  const handleOnEnd = () => {
    reset();
  };
  const onPlayback = event => {
    if (sentOnPlayback) return;
    sendVideoSplunkEvent(
      videoElementRef,
      SPLUNK_EVENT_TYPES.TIMEOUT,
      SPLUNK_EVENT_NAMES.OK,
      {
        startReason: get(event.detail, "startReason", ""),
        startEvent: get(event, "type", ""),
        ...splunkParams
      },
      config
    );

    setSentOnPlayback(true);
    reset();
  };

  const onTimeout = event => {
    if (sentOnTimeout) return;

    sendVideoSplunkEvent(
      videoElementRef,
      SPLUNK_EVENT_TYPES.TIMEOUT,
      SPLUNK_EVENT_NAMES.TIMED_OUT,
      {
        startReason: get(event.detail, "startReason", ""),
        startEvent: get(event, "type", ""),
        ...splunkParams
      },
      config
    );

    setSentOnTimeout(true);
    reset();
  };

  useEffect(() => {
    const videoElement = videoElementRef?.current;
    if (!videoElement) return;

    subscribeEvent(videoElement, VideoEvents.START, handleOnStart);
    subscribeEvent(videoElement, VideoEvents.DESTROYED, handleOnEnd);
    subscribeEvent(videoElement, VideoEvents.AD_START, handleAdStart);
    subscribeEvent(videoElement, VideoEvents.END, handleOnEnd);

    return () => {
      unsubscribeEvent(videoElement, VideoEvents.START, handleOnStart);
      unsubscribeEvent(videoElement, VideoEvents.DESTROYED, handleOnEnd);
      unsubscribeEvent(videoElement, VideoEvents.AD_START, handleAdStart);
      unsubscribeEvent(videoElement, VideoEvents.END, handleOnEnd);
    };
  }, [videoElementRef]);
};

import { useCallback, useEffect, useState } from "react";

// TODO: move the related logic from useVideoControls to this hook
export function usePlayerState({ initState, videoElementRef }) {
  const [playerState, setPlayerState] = useState({
    isStarted: false, // this indicates if the video has started playing successfully, and this remains true even if the video is paused
    isMuted: false,
    isPlaying: false,
    volume: 1,
    showSubtitles: false,
    ...initState
  });

  const updatePlayerState = useCallback(
    newState => {
      setPlayerState(prevState => ({
        ...prevState,
        ...newState
      }));
    },
    [setPlayerState]
  );

  const playingHandler = useCallback(() => {
    if (!playerState.isStarted) {
      updatePlayerState({ isStarted: true });
    }
  }, [playerState, updatePlayerState]);

  useEffect(() => {
    const videoElement = videoElementRef?.current;
    if (!videoElement) return;

    videoElement.addEventListener("playing", playingHandler);

    return () => {
      videoElement.removeEventListener("playing", playingHandler);
    };
  }, [videoElementRef, playingHandler]);

  return {
    playerState,
    updatePlayerState
  };
}

import React from "react";
import PropTypes from "prop-types";
import { useLiveCounter } from "../hooks/useLiveCounter";

export const DataLayer = ({ isLive, playerState }) => {
  useLiveCounter(isLive, playerState);
  return <></>;
};

DataLayer.propTypes = {
  isLive: PropTypes.bool,
  playerState: PropTypes.object
};
